import { Box, CircularProgress, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiFund, ApiRegistry, LimitedPartnerType, UpdateFundRequest } from '../../api';
import { BreadcrumbLink } from '../../common/Breadcrumbs';
import { Button } from '../../common/Button';
import { OneColumnRow } from '../../common/FormElements';
import { Autocomplete } from '../../common/inputs/Autocomplete';
import { MainContent } from '../../common/MainContent';
import { PersonRegistryEntryForm, schema as registrySchema } from './PersonRegistryEntryForm';
import { useGeneralPartner } from '../../hooks/useGeneralPartner';
import {
  buildLimitedPartnerOptions,
  LimitedPartnerOption,
  useFund,
  useUpdateFund,
} from '../../hooks/useFund';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object({
  partner: yup.object().required('Please choose a Partner').typeError('Please choose a Partner'),
  registry: registrySchema,
});

export type FundDashboardRegistryParams = {
  generalPartnerId: string;
  fundId: string;
  registryId?: string;
};

export type RegistryEntry = {
  partner: LimitedPartnerOption;
  registry: ApiRegistry;
};

const computeUpdatePayload = (
  fund: ApiFund,
  formParams: RegistryEntry
): UpdateFundRequest | undefined => {
  if (fund.id && fund.registry && fund.investments) {
    const payload: UpdateFundRequest = {
      fundId: fund.id,
      apiUpsertFundRequest: {
        registry: fund.registry,
        investments: fund.investments,
        fundSize: fund.fundSize,
        name: fund.name,
        serviceType: fund.serviceType,
        symbol: fund.symbol,
      },
    };
    const personIdx = fund.registry.findIndex(
      (registryEntry) => registryEntry.entityId == formParams.partner.value
    );
    // Update
    if (personIdx >= 0 && payload.apiUpsertFundRequest.registry != undefined) {
      payload.apiUpsertFundRequest.registry[personIdx] = formParams.registry;
    } else {
      //Insert
      payload.apiUpsertFundRequest.registry = fund.registry.concat(formParams.registry);
    }

    return payload;
  }
  return undefined;
};

export const RegistryEntryForm = () => {
  const { generalPartnerId, fundId, registryId } = useParams<FundDashboardRegistryParams>();
  if (fundId === undefined || generalPartnerId === undefined) {
    return <CircularProgress />;
  }
  const navigate = useNavigate();

  const { generalPartner, isGeneralPartnerLoading } = useGeneralPartner(generalPartnerId);
  const { fund, isFundLoading } = useFund(generalPartnerId, fundId);
  const formHook: UseFormReturn<RegistryEntry, object> = useForm<RegistryEntry>({
    mode: 'onBlur',
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const useUpdateFundMutation = useUpdateFund();
  const { trigger, control, watch, getValues, reset } = formHook;

  const { partner } = watch();
  const lps: Array<LimitedPartnerOption> = buildLimitedPartnerOptions(
    generalPartner,
    fund,
    registryId == undefined
  );

  const selectedPartner = registryId ? lps.find((lp) => lp.value == registryId) : partner;

  useEffect(() => {
    if (selectedPartner) {
      const registryEntity = fund?.registry?.find(
        (registryEntry) => registryEntry.entityId == registryId
      );
      reset({
        partner: selectedPartner,
        registry: registryEntity
          ? registryEntity
          : {
              entityId: selectedPartner.value,
            },
      });
    }
  }, [partner?.value]);

  if (
    fund === undefined ||
    generalPartner === undefined ||
    isGeneralPartnerLoading ||
    isFundLoading
  ) {
    return <CircularProgress />;
  }

  if (useUpdateFundMutation.isSuccess) {
    navigate(`/general-partners/${generalPartnerId}/funds/${fundId}/registry`);
  }

  let additionalForm = <></>;
  if (partner != undefined) {
    additionalForm = <PersonRegistryEntryForm />;
  }

  return (
    <MainContent
      links={[
        <BreadcrumbLink key={1} to='..'>
          All companies
        </BreadcrumbLink>,
        <BreadcrumbLink key={2} to={`../${generalPartnerId}/funds`}>
          {generalPartner.name}
        </BreadcrumbLink>,
        <BreadcrumbLink key={3} to={`../${generalPartnerId}/funds/${fundId}/info`}>
          {`${fund.name} dashboard`}
        </BreadcrumbLink>,
        <BreadcrumbLink key={4} to={`../${generalPartnerId}/funds/${fundId}/registry`}>
          Registry
        </BreadcrumbLink>,
        <BreadcrumbLink key={5} to='#'>
          {registryId ? 'Edit' : 'Add'}
        </BreadcrumbLink>,
      ]}
      headingText='Add Registry Entry'
    >
      <FormProvider {...formHook}>
        <Box
          display='flex'
          flexDirection='column'
          width='780px'
          marginTop='20px'
          rowGap='20px'
          marginLeft='40px'
        >
          <OneColumnRow>
            <Controller
              control={control}
              name='partner'
              render={({ field, fieldState }) => {
                const { ref, onChange, value, ...rest } = field;
                return (
                  <Autocomplete
                    options={lps}
                    inputRef={ref}
                    disabled={registryId != undefined}
                    onChange={(_, value) => {
                      console.log(getValues());
                      onChange(value);
                    }}
                    isOptionEqualToValue={(option, value) => {
                      if (value == undefined) return false;
                      return option.value == value.value;
                    }}
                    value={value || null}
                    {...rest}
                    label='Partner'
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    sx={{ flex: '1 1 0px' }}
                  />
                );
              }}
            />
          </OneColumnRow>
          {additionalForm}
          <Toolbar
            sx={{
              paddingLeft: '0px',
              paddingRight: '0px',
              flexGrow: 1,
              columnGap: '5px',
            }}
          >
            <Box flexGrow={1}></Box>
            <Button href={`/general-partners/${generalPartnerId}/funds/${fundId}/registry`}>
              Cancel
            </Button>
            <Button
              variant='text'
              onClick={async () => {
                const result = await trigger();
                if (result) {
                  const formParams: RegistryEntry = getValues();
                  const payload = computeUpdatePayload(fund, formParams);
                  if (payload) {
                    useUpdateFundMutation.mutate(payload);
                  }
                }
              }}
            >
              Save
            </Button>
          </Toolbar>
        </Box>
      </FormProvider>
    </MainContent>
  );
};
