import {
  DataGridPro as MuiDataGrid,
  DataGridProProps,
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import React from 'react';
import { Button } from './Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/material';

interface Props {
  noRowsLabel?: string;
}

export const column = ({
  field,
  headerName,
  width,
  valueFormatter,
  flex,
}: Pick<GridColDef, 'field' | 'headerName' | 'width' | 'valueFormatter' | 'flex'>): GridColDef => {
  return {
    field,
    headerName,
    width,
    valueFormatter,
    flex,
    headerAlign: 'left',
  };
};

export const customColumn = (params: {
  renderCell: (params: GridRenderCellParams) => React.ReactNode;
  minWidth: any;
  width?: any;
  maxWidth?: any;
  field?: string;
  headerName?: string;
  align?: GridAlignment;
}): GridColDef => {
  return {
    field: params.field != undefined ? params.field : 'customColumn',
    headerName: params.headerName != undefined ? params.headerName : '',
    sortable: false,
    align: params.align != undefined ? params.align : 'right',
    flex: 1,
    minWidth: params.minWidth,
    width: params.width,
    maxWidth: params.maxWidth,
    renderCell: params.renderCell,
  };
};

interface ActionColumnParams {
  disabled?: boolean;
  label: string;
  onAction: (params: GridRenderCellParams) => void;
}

export const actionColumn = (
  fun: (params: GridRenderCellParams) => ActionColumnParams
): GridColDef => {
  return {
    field: 'action',
    headerName: '',
    sortable: false,
    width: 110,
    align: 'right',
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      const { label, disabled, onAction } = fun(params);
      const onClick = () => {
        onAction(params);
      };
      return (
        <Button variant='TertiaryAction' disabled={disabled} onClick={onClick}>
          {label}
        </Button>
      );
    },
  };
};

export const DataGrid: React.FC<
  Props &
    Pick<
      DataGridProProps,
      | 'rows'
      | 'columns'
      | 'initialState'
      | 'apiRef'
      | 'checkboxSelection'
      | 'onSelectionModelChange'
      | 'selectionModel'
    >
> = ({
  rows,
  columns,
  noRowsLabel,
  initialState,
  apiRef,
  checkboxSelection,
  onSelectionModelChange,
  selectionModel,
}) => {
  const initalPageSize = initialState?.pagination?.pageSize ?? 10;
  const [pageSize, setPageSize] = React.useState<number>(initalPageSize);

  return (
    <MuiDataGrid
      initialState={initialState}
      apiRef={apiRef}
      components={{
        ColumnSortedDescendingIcon: ArrowDropUpIcon,
        ColumnSortedAscendingIcon: ArrowDropDownIcon,
        ColumnUnsortedIcon: () => (
          <Box display='flex' flexDirection='column'>
            <ArrowDropUpIcon viewBox='0 -7 24 24' fontSize='small' />
            <ArrowDropDownIcon viewBox='0 7 24 24' fontSize='small' />
          </Box>
        ),
      }}
      sx={{
        '&': {
          border: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'normal',
          letterSpacing: 'normal',
          lineHeight: 'normal',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          paddingLeft: '0',
        },
        '& .MuiDataGrid-columnHeaders': {
          borderBottom: 'solid 1px black',
        },
        '& .MuiTablePagination-displayedRows': {
          fontWeight: '500',
        },
        '& .MuiButton-root': {
          height: '36px',
          minWidth: '91px',
        },
      }}
      sortingMode='client'
      rows={rows}
      columns={columns}
      rowHeight={61}
      autoHeight
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 25, 100]}
      pagination
      localeText={{
        noRowsLabel: noRowsLabel || 'No rows',
      }}
      checkboxSelection={checkboxSelection}
      selectionModel={selectionModel}
      onSelectionModelChange={onSelectionModelChange}
    />
  );
};
