import React from 'react';
import { ExampleDocumentsPagePlaceholder } from './ExampleDocumentsPagePlaceholder';

export const InvestmentFormsPage: React.FC = () => {
  function createData(srNo: number, documentName: string, uploadedAt: string) {
    return { srNo, documentName, uploadedAt };
  }

  const rows = [
    createData(1, 'Investment form one', '01-10-2021'),
    createData(2, 'Investment form two', '29-12-2021'),
    createData(3, 'Investment form three', '10-01-2022'),
  ];

  return (
    <ExampleDocumentsPagePlaceholder
      rows={rows}
      fileName='example_form.pdf'
      title='My investment forms'
    />
  );
};
