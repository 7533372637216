import React from 'react';
import { Link } from '@mui/material';

export const SignUpLegalText: React.FC = () => {
  return (
    <div>
      I agree to the{' '}
      <Link
        target='_blank'
        underline='always'
        href='https://gopoolside.com/privacy-policy/'
      >
        Privacy Policy
      </Link>{' '}
      and{' '}
      <Link
        target='_blank'
        underline='always'
        href='https://gopoolside.com/terms-of-use/'
      >
        Terms of Use
      </Link>
      .
    </div>
  );
};
