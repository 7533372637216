import { CircularProgress } from '@mui/material';
import React from 'react';
import { useQueryClient } from 'react-query';
import { ApiFund, ApiInvestment, UpdateFundRequest } from '../../api';
import { Button } from '../../common/Button';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import { useFund, useUpdateFund } from '../../hooks/useFund';
import { useGeneralPartner } from '../../hooks/useGeneralPartner';
import { InvestmentFormParams } from './InvestmentForm';

const computeUpdatePayload = (
  fund: ApiFund,
  investment: ApiInvestment
): UpdateFundRequest | undefined => {
  if (fund.id && fund.registry && fund.investments) {
    const payload: UpdateFundRequest = {
      fundId: fund.id,
      apiUpsertFundRequest: {
        registry: fund.registry,
        investments: [...fund.investments],
        fundSize: fund.fundSize,
        name: fund.name,
        serviceType: fund.serviceType,
        symbol: fund.symbol,
      },
    };
    const invesmentIdx = payload.apiUpsertFundRequest.investments.findIndex(
      (searchInvestment) => investment.id == searchInvestment.id
    );
    if (invesmentIdx >= 0) {
      payload.apiUpsertFundRequest.investments.splice(invesmentIdx, 1);
      return payload;
    }
  }
  return undefined;
};

export const InvestmentDelete: React.FC<InvestmentFormParams> = (params: InvestmentFormParams) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { generalPartner, isGeneralPartnerLoading } = useGeneralPartner(params.generalPartnerId);
  const { fund, isFundLoading } = useFund(params.generalPartnerId, params.fundId);
  const queryClient = useQueryClient();
  const useUpdateFundMutation = useUpdateFund();
  if (
    isGeneralPartnerLoading ||
    isFundLoading ||
    !generalPartner ||
    !fund ||
    !params.investmentId
  ) {
    return <CircularProgress />;
  }
  const investment = fund.investments?.find((invesment) => invesment.id == params.investmentId);
  if (!investment) {
    return <CircularProgress />;
  }
  if (open && useUpdateFundMutation.isSuccess) {
    queryClient.invalidateQueries('fund');
    setOpen(false);
  }
  return (
    <>
      <Button variant='TertiaryAction' onClick={handleClickOpen}>
        Delete
      </Button>
      <ConfirmationDialog
        title='Delete Investment!'
        message={`Are you sure you want to delete ${investment.name} ${investment.instrument}?`}
        confirmButtonLabel='Delete'
        confirmButtonVariant='Disabling'
        open={open}
        onCancel={handleClose}
        onConfirm={() => {
          const payload = computeUpdatePayload(fund, investment);
          if (payload) {
            useUpdateFundMutation.mutate(payload);
          }
        }}
      />
    </>
  );
};
