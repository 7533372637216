/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiDocumentLink
 */
export interface ApiDocumentLink {
    /**
     * 
     * @type {string}
     * @memberof ApiDocumentLink
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDocumentLink
     */
    link: string;
}

export function ApiDocumentLinkFromJSON(json: any): ApiDocumentLink {
    return ApiDocumentLinkFromJSONTyped(json, false);
}

export function ApiDocumentLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDocumentLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
        'link': json['link'],
    };
}

export function ApiDocumentLinkToJSON(value?: ApiDocumentLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
        'link': value.link,
    };
}

