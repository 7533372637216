/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCreateAdminRequest,
    ApiCreateAdminRequestFromJSON,
    ApiCreateAdminRequestFromJSONTyped,
    ApiCreateAdminRequestToJSON,
    CompanyData,
    CompanyDataFromJSON,
    CompanyDataFromJSONTyped,
    CompanyDataToJSON,
    RiskStatus,
    RiskStatusFromJSON,
    RiskStatusFromJSONTyped,
    RiskStatusToJSON,
} from './';

/**
 * update company request
 * @export
 * @interface ApiUpdateCompanyRequest
 */
export interface ApiUpdateCompanyRequest {
    /**
     * 
     * @type {RiskStatus}
     * @memberof ApiUpdateCompanyRequest
     */
    risk?: RiskStatus;
    /**
     * 
     * @type {CompanyData}
     * @memberof ApiUpdateCompanyRequest
     */
    companyData: CompanyData;
    /**
     * 
     * @type {Array<ApiCreateAdminRequest>}
     * @memberof ApiUpdateCompanyRequest
     */
    representatives: Array<ApiCreateAdminRequest>;
}

export function ApiUpdateCompanyRequestFromJSON(json: any): ApiUpdateCompanyRequest {
    return ApiUpdateCompanyRequestFromJSONTyped(json, false);
}

export function ApiUpdateCompanyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiUpdateCompanyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'risk': !exists(json, 'risk') ? undefined : RiskStatusFromJSON(json['risk']),
        'companyData': CompanyDataFromJSON(json['companyData']),
        'representatives': ((json['representatives'] as Array<any>).map(ApiCreateAdminRequestFromJSON)),
    };
}

export function ApiUpdateCompanyRequestToJSON(value?: ApiUpdateCompanyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'risk': RiskStatusToJSON(value.risk),
        'companyData': CompanyDataToJSON(value.companyData),
        'representatives': ((value.representatives as Array<any>).map(ApiCreateAdminRequestToJSON)),
    };
}

