/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAmlForm,
    ApiAmlFormFromJSON,
    ApiAmlFormFromJSONTyped,
    ApiAmlFormToJSON,
    ApiOnboardCompanyAml,
    ApiOnboardCompanyAmlFromJSON,
    ApiOnboardCompanyAmlFromJSONTyped,
    ApiOnboardCompanyAmlToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiOnboardAmlInfo
 */
export interface ApiOnboardAmlInfo {
    /**
     * 
     * @type {ApiAmlForm}
     * @memberof ApiOnboardAmlInfo
     */
    personalAmlForm?: ApiAmlForm;
    /**
     * 
     * @type {Array<ApiOnboardCompanyAml>}
     * @memberof ApiOnboardAmlInfo
     */
    companyAmlForms?: Array<ApiOnboardCompanyAml>;
}

export function ApiOnboardAmlInfoFromJSON(json: any): ApiOnboardAmlInfo {
    return ApiOnboardAmlInfoFromJSONTyped(json, false);
}

export function ApiOnboardAmlInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiOnboardAmlInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalAmlForm': !exists(json, 'personalAmlForm') ? undefined : ApiAmlFormFromJSON(json['personalAmlForm']),
        'companyAmlForms': !exists(json, 'companyAmlForms') ? undefined : ((json['companyAmlForms'] as Array<any>).map(ApiOnboardCompanyAmlFromJSON)),
    };
}

export function ApiOnboardAmlInfoToJSON(value?: ApiOnboardAmlInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personalAmlForm': ApiAmlFormToJSON(value.personalAmlForm),
        'companyAmlForms': value.companyAmlForms === undefined ? undefined : ((value.companyAmlForms as Array<any>).map(ApiOnboardCompanyAmlToJSON)),
    };
}

