/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiAmlForm,
    ApiAmlFormFromJSON,
    ApiAmlFormToJSON,
    ApiOnboardConfirmPersonalDetails,
    ApiOnboardConfirmPersonalDetailsFromJSON,
    ApiOnboardConfirmPersonalDetailsToJSON,
    ApiOnboardProfile,
    ApiOnboardProfileFromJSON,
    ApiOnboardProfileToJSON,
    ApiShuftiVerification,
    ApiShuftiVerificationFromJSON,
    ApiShuftiVerificationToJSON,
} from '../models';

export interface ConfirmPersonalDetailsRequest {
    pin: string;
    apiOnboardConfirmPersonalDetails: ApiOnboardConfirmPersonalDetails;
    invitation?: string;
    invitationId?: string;
}

export interface EndOnboardSessionRequest {
    pin: string;
    invitation?: string;
    invitationId?: string;
}

export interface InfoRequest {
    pin: string;
    invitation?: string;
    invitationId?: string;
}

export interface SubmitAmlFormRequest {
    pin: string;
    apiAmlForm: ApiAmlForm;
    invitation?: string;
    invitationId?: string;
}

/**
 * 
 */
export class OnboardApi extends runtime.BaseAPI {

    /**
     * confirms / changes person email address
     */
    async confirmPersonalDetailsRaw(requestParameters: ConfirmPersonalDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiShuftiVerification>> {
        if (requestParameters.pin === null || requestParameters.pin === undefined) {
            throw new runtime.RequiredError('pin','Required parameter requestParameters.pin was null or undefined when calling confirmPersonalDetails.');
        }

        if (requestParameters.apiOnboardConfirmPersonalDetails === null || requestParameters.apiOnboardConfirmPersonalDetails === undefined) {
            throw new runtime.RequiredError('apiOnboardConfirmPersonalDetails','Required parameter requestParameters.apiOnboardConfirmPersonalDetails was null or undefined when calling confirmPersonalDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.invitation !== undefined) {
            queryParameters['invitation'] = requestParameters.invitation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.invitationId !== undefined && requestParameters.invitationId !== null) {
            headerParameters['invitationId'] = String(requestParameters.invitationId);
        }

        if (requestParameters.pin !== undefined && requestParameters.pin !== null) {
            headerParameters['pin'] = String(requestParameters.pin);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboard/confirmPersonalDetails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiOnboardConfirmPersonalDetailsToJSON(requestParameters.apiOnboardConfirmPersonalDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiShuftiVerificationFromJSON(jsonValue));
    }

    /**
     * confirms / changes person email address
     */
    async confirmPersonalDetails(requestParameters: ConfirmPersonalDetailsRequest, initOverrides?: RequestInit): Promise<ApiShuftiVerification> {
        const response = await this.confirmPersonalDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ends onboarding session
     */
    async endOnboardSessionRaw(requestParameters: EndOnboardSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.pin === null || requestParameters.pin === undefined) {
            throw new runtime.RequiredError('pin','Required parameter requestParameters.pin was null or undefined when calling endOnboardSession.');
        }

        const queryParameters: any = {};

        if (requestParameters.invitation !== undefined) {
            queryParameters['invitation'] = requestParameters.invitation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.invitationId !== undefined && requestParameters.invitationId !== null) {
            headerParameters['invitationId'] = String(requestParameters.invitationId);
        }

        if (requestParameters.pin !== undefined && requestParameters.pin !== null) {
            headerParameters['pin'] = String(requestParameters.pin);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboard/end/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Ends onboarding session
     */
    async endOnboardSession(requestParameters: EndOnboardSessionRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.endOnboardSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Onboarding Info
     */
    async infoRaw(requestParameters: InfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiOnboardProfile>> {
        if (requestParameters.pin === null || requestParameters.pin === undefined) {
            throw new runtime.RequiredError('pin','Required parameter requestParameters.pin was null or undefined when calling info.');
        }

        const queryParameters: any = {};

        if (requestParameters.invitation !== undefined) {
            queryParameters['invitation'] = requestParameters.invitation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.invitationId !== undefined && requestParameters.invitationId !== null) {
            headerParameters['invitationId'] = String(requestParameters.invitationId);
        }

        if (requestParameters.pin !== undefined && requestParameters.pin !== null) {
            headerParameters['pin'] = String(requestParameters.pin);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboard/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiOnboardProfileFromJSON(jsonValue));
    }

    /**
     * Onboarding Info
     */
    async info(requestParameters: InfoRequest, initOverrides?: RequestInit): Promise<ApiOnboardProfile> {
        const response = await this.infoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saves AML form for a limited partner
     */
    async submitAmlFormRaw(requestParameters: SubmitAmlFormRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAmlForm>> {
        if (requestParameters.pin === null || requestParameters.pin === undefined) {
            throw new runtime.RequiredError('pin','Required parameter requestParameters.pin was null or undefined when calling submitAmlForm.');
        }

        if (requestParameters.apiAmlForm === null || requestParameters.apiAmlForm === undefined) {
            throw new runtime.RequiredError('apiAmlForm','Required parameter requestParameters.apiAmlForm was null or undefined when calling submitAmlForm.');
        }

        const queryParameters: any = {};

        if (requestParameters.invitation !== undefined) {
            queryParameters['invitation'] = requestParameters.invitation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.invitationId !== undefined && requestParameters.invitationId !== null) {
            headerParameters['invitationId'] = String(requestParameters.invitationId);
        }

        if (requestParameters.pin !== undefined && requestParameters.pin !== null) {
            headerParameters['pin'] = String(requestParameters.pin);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboard/aml/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAmlFormToJSON(requestParameters.apiAmlForm),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAmlFormFromJSON(jsonValue));
    }

    /**
     * saves AML form for a limited partner
     */
    async submitAmlForm(requestParameters: SubmitAmlFormRequest, initOverrides?: RequestInit): Promise<ApiAmlForm> {
        const response = await this.submitAmlFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
