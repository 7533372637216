import React from 'react';
import { AppRoutes } from './routing/routes';
import Amplify, { Hub } from 'aws-amplify';
import { listener } from './amplifyAuthEventListener';
import { Provider as AlertProvider } from 'react-alert';
import { alertOptions, AlertTemplate } from './common/AlertTemplate';
import { Authenticator } from './pages/auth/Authenticator';
import awsExports from './aws-exports';
import { QueryClient, QueryClientProvider } from 'react-query';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { queryClient } from './apis';

LicenseInfo.setLicenseKey(
  '65f71bb66864abaea736a7a3d7c73066T1JERVI6Mzk3MjcsRVhQSVJZPTE2Nzg5NjI2NTMwMDAsS0VZVkVSU0lPTj0x'
);

Amplify.configure(awsExports);

const App: React.FC = () => {
  Hub.listen('auth', (data) => {
    listener(data, queryClient);
  });

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </AlertProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
};

export default App;
