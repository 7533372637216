import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BreadcrumbLink } from '../../common/Breadcrumbs';
import { MainContent } from '../../common/MainContent';
import { useGeneralPartner } from '../../hooks/useGeneralPartner';
import { OwnerInvitationInfo } from '../onboard/OwnerInvitationInfo';
import { ShuftiStatus } from './ShuftiStatus';

export type PersonDetailsParams = {
  generalPartnerId: string;
  personId: string;
};

export const PersonDetails = () => {
  const params = useParams<PersonDetailsParams>();
  if (params.generalPartnerId == undefined || params.personId == undefined) {
    return <CircularProgress />;
  }
  const { generalPartner, isGeneralPartnerLoading } = useGeneralPartner(params.generalPartnerId);
  if (isGeneralPartnerLoading || generalPartner == undefined) {
    return <CircularProgress />;
  }

  return (
    <MainContent
      links={[
        <BreadcrumbLink key={1} to='/general-partners'>
          All companies
        </BreadcrumbLink>,
        <BreadcrumbLink key={2} to={`/general-partners/${params.generalPartnerId}`}>
          {generalPartner.name}
        </BreadcrumbLink>,
      ]}
      headingText='Person Details'
    >
      <Box flexDirection={'row'} display="flex">

        <OwnerInvitationInfo generalPartnerId={params.generalPartnerId} personId={params.personId} />
        <ShuftiStatus personId={params.personId} />
      </Box>

    </MainContent>

  );
}
