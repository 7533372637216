import { Box, Tab, Tabs as MuiTabs } from '@mui/material';
import React, { SyntheticEvent } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ padding: '40px 40px 40px 0' }}>{children}</Box>
      )}
    </div>
  );
};

interface TabsProps {
  tabs: string[];
  panels: (index: number) => React.ReactElement;
  value: number;
  onChange?: (_: SyntheticEvent, index: number) => void;
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  panels,
  value,
  onChange,
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: 'var(--peacock-blue)',
            },
            '& .MuiTab-textColorPrimary': {
              padding: '0 20px 0 20px',
            },
          }}
          value={value}
          onChange={onChange}
        >
          {tabs.map((label, index) => (
            <Tab
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'normal',
                color: '#afafaf',
                '&.Mui-selected': {
                  color: 'black',
                },
              }}
              disableRipple
              key={index}
              label={label}
            />
          ))}
        </MuiTabs>
      </Box>
      {panels(value)}
    </Box>
  );
};
