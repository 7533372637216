import React, { useCallback, useMemo } from 'react';
import {
  ArrayLayoutProps,
  isObjectArrayWithNesting,
  RankedTester,
  rankWith,
  createDefaultValue,
  findUISchema,
  and,
  UISchemaElement,
  composePaths,
} from '@jsonforms/core';
import { Hidden, Typography } from '@mui/material';
import { withJsonFormsArrayLayoutProps } from '@jsonforms/react';
import map from 'lodash/map';
import range from 'lodash/range';
import { Button } from '../Button';
import { JsonFormsDispatch } from '@jsonforms/react';
import { Box, Toolbar } from '@mui/material';

const ArrayLayoutRenderer = (props: ArrayLayoutProps) => {
  const {
    data,
    path,
    schema,
    uischema,
    addItem,
    removeItems,
    renderers,
    cells,
    rootSchema,
    uischemas,
    visible,
  } = props;
  const innerCreateDefaultValue = useCallback(
    () => createDefaultValue(props.schema),
    [props.schema]
  );
  const addItemCb = useCallback(
    (p: string, value: any) => addItem(p, value),
    [addItem]
  );
  const addBtnLabel = uischema.options?.addButtonLabel
    ? uischema.options?.addButtonLabel
    : 'Add Element';
  // @ts-ignore
  const foundUISchema = useMemo(
    () =>
      findUISchema(
        uischemas ? uischemas : [],
        schema,
        uischema.scope,
        path,
        undefined,
        uischema,
        rootSchema
      ),
    [uischemas, schema, uischema.scope, path, uischema, rootSchema]
  );
  return (
    <Hidden xsUp={!visible}>
      <div>
        {data > 0 ? (
          map(range(data), (index) => {
            const childPath = composePaths(path, `${index}`);
            // const childData = Resolve.data(ctx.core.data, childPath);
            return (
              <div key={index}>
                <Toolbar
                  disableGutters={true}
                  sx={{
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    flexGrow: 1,
                    columnGap: '5px',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontWeight: '500',
                    }}
                  >
                    {index + 1}.
                  </Typography>
                  <Box flexGrow={1}></Box>
                  <Button
                    variant='Disabling'
                    onClick={() => {
                      if (removeItems) {
                        removeItems(path, [index])();
                      }
                    }}
                  >
                    Delete
                  </Button>
                </Toolbar>
                <JsonFormsDispatch
                  schema={schema}
                  uischema={foundUISchema}
                  path={childPath}
                  key={childPath}
                  renderers={renderers}
                  cells={cells}
                />
              </div>
            );
          })
        ) : (
          <p>No data</p>
        )}
      </div>
      <Button
        sx={{
          marginTop: '10px',
        }}
        variant='AddInForm'
        onClick={addItemCb(path, innerCreateDefaultValue())}
      >
        {addBtnLabel}
      </Button>
    </Hidden>
  );
};

export const arrayLayoutTester: RankedTester = rankWith(
  5,
  and(isObjectArrayWithNesting, (uischema: UISchemaElement): boolean => {
    if (uischema.options == undefined) return false;
    return 'custom' in uischema.options;
  })
);
export default withJsonFormsArrayLayoutProps(ArrayLayoutRenderer);
