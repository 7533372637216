/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiOnboardAmlInfo,
    ApiOnboardAmlInfoFromJSON,
    ApiOnboardAmlInfoFromJSONTyped,
    ApiOnboardAmlInfoToJSON,
    ApiVeriffStatus,
    ApiVeriffStatusFromJSON,
    ApiVeriffStatusFromJSONTyped,
    ApiVeriffStatusToJSON,
    ShuftiStatus,
    ShuftiStatusFromJSON,
    ShuftiStatusFromJSONTyped,
    ShuftiStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiOnboardProfile
 */
export interface ApiOnboardProfile {
    /**
     * 
     * @type {string}
     * @memberof ApiOnboardProfile
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOnboardProfile
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOnboardProfile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOnboardProfile
     */
    lastName?: string;
    /**
     * 
     * @type {ApiVeriffStatus}
     * @memberof ApiOnboardProfile
     */
    veriffStatus?: ApiVeriffStatus;
    /**
     * 
     * @type {ShuftiStatus}
     * @memberof ApiOnboardProfile
     */
    shuftiVerificationStatus?: ShuftiStatus;
    /**
     * 
     * @type {ApiOnboardAmlInfo}
     * @memberof ApiOnboardProfile
     */
    amlInfo?: ApiOnboardAmlInfo;
}

export function ApiOnboardProfileFromJSON(json: any): ApiOnboardProfile {
    return ApiOnboardProfileFromJSONTyped(json, false);
}

export function ApiOnboardProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiOnboardProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'veriffStatus': !exists(json, 'veriffStatus') ? undefined : ApiVeriffStatusFromJSON(json['veriffStatus']),
        'shuftiVerificationStatus': !exists(json, 'shuftiVerificationStatus') ? undefined : ShuftiStatusFromJSON(json['shuftiVerificationStatus']),
        'amlInfo': !exists(json, 'amlInfo') ? undefined : ApiOnboardAmlInfoFromJSON(json['amlInfo']),
    };
}

export function ApiOnboardProfileToJSON(value?: ApiOnboardProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'veriffStatus': ApiVeriffStatusToJSON(value.veriffStatus),
        'shuftiVerificationStatus': ShuftiStatusToJSON(value.shuftiVerificationStatus),
        'amlInfo': ApiOnboardAmlInfoToJSON(value.amlInfo),
    };
}

