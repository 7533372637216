import { JsonForms } from '@jsonforms/react';
import { Box, CircularProgress, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AmlFormStatus, ApiAmlForm } from '../../api';
import { amlSchema } from './dataSchema';
import { poolSideJsonFormRenderers } from '../../common/renderers';
import { materialCells } from '@jsonforms/material-renderers';
import { Button } from '../../common/Button';
import { useMutation, useQueryClient } from 'react-query';
import { ownerAmlApi } from '../../apis';
import { UISchemaElement } from '@jsonforms/core';

export interface Props {
  amlForm: ApiAmlForm;
  backLink: string;
  uiSchema: UISchemaElement;
}

const useUpateAmlForm = (onSuccess?: () => void) => {
  return useMutation(
    'aml-form',
    async (params: ApiAmlForm) => {
      return ownerAmlApi.setOwnerAmlForm({
        apiAmlForm: params,
      });
    },
    {
      onSuccess: onSuccess,
    }
  );
};

export const AmlIndividualForm = ({ amlForm, backLink, uiSchema }: Props) => {
  const queryClient = useQueryClient();
  if (amlForm == undefined || amlForm.formData == undefined) {
    return <CircularProgress />;
  }

  const updateAmlFormMutation = useUpateAmlForm(() => {
    queryClient.invalidateQueries('person-aml');
    queryClient.invalidateQueries('company-aml');
    window.scrollTo(0, 0);
  });
  const defaultData = JSON.parse(amlForm.formData);
  const isReadOnly =
    amlForm.amlFormStatus != undefined && amlForm.amlFormStatus != AmlFormStatus.InProgress;

  const [data, setData] = useState(defaultData);
  const saveBtnLabel =
    amlForm?.amlFormStatus == AmlFormStatus.Submitted ||
    amlForm?.amlFormStatus == AmlFormStatus.Approved
      ? 'Reopen'
      : 'Save';
  if (updateAmlFormMutation.isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box display='flex' flexDirection='column' width='780px' rowGap='20px'>
      <Typography color='#ff0000'>Current status: {amlForm?.amlFormStatus}</Typography>
      <JsonForms
        schema={amlSchema}
        uischema={uiSchema}
        data={data}
        renderers={poolSideJsonFormRenderers}
        cells={materialCells}
        onChange={({ data }) => {
          setData(data);
        }}
        readonly={isReadOnly}
      />
      <Toolbar
        sx={{
          paddingLeft: '0px',
          paddingRight: '0px',
          flexGrow: 1,
          columnGap: '5px',
        }}
      >
        <Box flexGrow={1}></Box>
        <Button href={backLink}>Back</Button>
        <Button
          variant='PrimaryAction'
          onClick={() => {
            const payload = Object.assign({}, amlForm, {
              formData: JSON.stringify(data),
              amlFormStatus: AmlFormStatus.InProgress,
            });
            updateAmlFormMutation.mutate(payload);
          }}
        >
          {saveBtnLabel}
        </Button>
        {(amlForm?.amlFormStatus == AmlFormStatus.InProgress ||
          amlForm?.amlFormStatus == AmlFormStatus.Submitted) && (
          <Button
            variant='PrimaryAction'
            onClick={() => {
              const payload = Object.assign({}, amlForm, {
                formData: JSON.stringify(data),
                amlFormStatus: AmlFormStatus.Approved,
              });
              updateAmlFormMutation.mutate(payload);
            }}
          >
            Approve {amlForm?.amlFormStatus == AmlFormStatus.InProgress ? '(forced)' : ''}
          </Button>
        )}
      </Toolbar>
    </Box>
  );
};
