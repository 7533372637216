/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiShuftiResponseVerificationDataDocument,
    ApiShuftiResponseVerificationDataDocumentFromJSON,
    ApiShuftiResponseVerificationDataDocumentFromJSONTyped,
    ApiShuftiResponseVerificationDataDocumentToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiShuftiResponseVerificationData
 */
export interface ApiShuftiResponseVerificationData {
    /**
     * 
     * @type {ApiShuftiResponseVerificationDataDocument}
     * @memberof ApiShuftiResponseVerificationData
     */
    document?: ApiShuftiResponseVerificationDataDocument;
}

export function ApiShuftiResponseVerificationDataFromJSON(json: any): ApiShuftiResponseVerificationData {
    return ApiShuftiResponseVerificationDataFromJSONTyped(json, false);
}

export function ApiShuftiResponseVerificationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiShuftiResponseVerificationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document': !exists(json, 'document') ? undefined : ApiShuftiResponseVerificationDataDocumentFromJSON(json['document']),
    };
}

export function ApiShuftiResponseVerificationDataToJSON(value?: ApiShuftiResponseVerificationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document': ApiShuftiResponseVerificationDataDocumentToJSON(value.document),
    };
}

