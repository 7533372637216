import React from 'react';
import { MainContent } from '../../common/MainContent';
import { Button } from '../../common/Button';
import { actionColumn, column, DataGrid } from '../../common/DataGrid';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { ApiCompany, companiesApi } from '../../apis';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useSearchParams } from 'react-router-dom';
import { getInitialState, handleSortingAndPagingParams } from '../../util/gridSorting';

const useGeneralPartners = () => {
  return useQuery('general', async () => {
    return companiesApi.listCompanies();
  });
};

export const GeneralPartnersList = () => {
  const { data, isLoading } = useGeneralPartners();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const apiRef = useGridApiRef();
  React.useEffect(() => {
    if (!isLoading) {
      handleSortingAndPagingParams(apiRef, setSearchParams);
    }
  }, [apiRef, isLoading]);

  const initialState: GridInitialStatePro = getInitialState(searchParams);

  if (data === undefined || isLoading) {
    return <CircularProgress />;
  }

  const columns: GridColumns = [
    column({ headerName: 'Name of the company', field: 'name', width: 200 }),
    column({ headerName: 'Registry code', field: 'registryCode', width: 150 }),
    column({ headerName: 'Risk', field: 'risk', width: 150 }),
    actionColumn(() => {
      return {
        label: 'Dashboard',
        onAction: ({ row }: GridCellParams<any, ApiCompany>) => {
          navigate(`${row.id}/info`);
        },
      };
    }),
  ];
  return (
    <MainContent
      headingText='All companies'
      headerButtons={
        <Button variant='SecondaryAction' href='/general-partners/add'>
          Add company
        </Button>
      }
    >
      <DataGrid columns={columns} rows={data} initialState={initialState} apiRef={apiRef} />
    </MainContent>
  );
};
