import React from 'react';
import { Box, Typography } from '@mui/material';
import { VerificationForm } from './VerificationForm';
import BackgroundBlur from '../../assets/background-blur.png';
import './VerificationPage.css';
import '@fontsource/roboto/400.css';
import { SignOutButton } from '../auth/SignOutButton';
import { useInvestorProfile } from '../../hooks/useInvestorProfile';
import { ApiVeriffStatus, ShuftiStatus } from '../../api';
import { Navigate, useNavigate } from 'react-router-dom';
import { CircularProgressOverlay } from '../../util/CircularProgressOverlay';

export const VerificationPage: React.FC = () => {
  const { isProfileLoading, profile } = useInvestorProfile();
  const navigate = useNavigate();

  if (isProfileLoading) {
    return <CircularProgressOverlay />;
  } else if (profile?.veriffStatus === ShuftiStatus.Approved) {
    return <Navigate to='/limited-partners/dashboard' />;
  }

  return (
    <Box
      style={{
        backgroundImage: `url(${BackgroundBlur}`,
        height: '100%',
        width: '100%',
        backgroundRepeat: 'no-repeat',
      }}
      position='absolute'
      className='verification-page'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      {(profile?.veriffStatus === ShuftiStatus.InProgress && (
        <>
          <Typography style={{ fontFamily: 'Roboto' }} variant='h5'>
            Verification is already in progress, please wait for the results.
          </Typography>
        </>
      )) || (
        <>
          <Typography style={{ fontFamily: 'Roboto' }} variant='h5'>
            Please verify your identity
          </Typography>
          <VerificationForm
            onFinish={() => {
              navigate('/limited-partners/dashboard');
            }}
          />
        </>
      )}
      <SignOutButton color='black' />
    </Box>
  );
};
