/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * information required to add info to the registry of the fund (LP is a company)
 * @export
 * @interface ApiRegistry
 */
export interface ApiRegistry {
    /**
     * 
     * @type {string}
     * @memberof ApiRegistry
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiRegistry
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiRegistry
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiRegistry
     */
    entityId?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiRegistry
     */
    totalCommitment?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiRegistry
     */
    paidInCapital?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiRegistry
     */
    callableCapital?: number;
    /**
     * 
     * @type {Date}
     * @memberof ApiRegistry
     */
    agreementSigningDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApiRegistry
     */
    firstClosingDate?: Date;
}

export function ApiRegistryFromJSON(json: any): ApiRegistry {
    return ApiRegistryFromJSONTyped(json, false);
}

export function ApiRegistryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiRegistry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'totalCommitment': !exists(json, 'totalCommitment') ? undefined : json['totalCommitment'],
        'paidInCapital': !exists(json, 'paidInCapital') ? undefined : json['paidInCapital'],
        'callableCapital': !exists(json, 'callableCapital') ? undefined : json['callableCapital'],
        'agreementSigningDate': !exists(json, 'agreementSigningDate') ? undefined : (new Date(json['agreementSigningDate'])),
        'firstClosingDate': !exists(json, 'firstClosingDate') ? undefined : (new Date(json['firstClosingDate'])),
    };
}

export function ApiRegistryToJSON(value?: ApiRegistry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'entityId': value.entityId,
        'totalCommitment': value.totalCommitment,
        'paidInCapital': value.paidInCapital,
        'callableCapital': value.callableCapital,
        'agreementSigningDate': value.agreementSigningDate === undefined ? undefined : (value.agreementSigningDate.toISOString().substr(0,10)),
        'firstClosingDate': value.firstClosingDate === undefined ? undefined : (value.firstClosingDate.toISOString().substr(0,10)),
    };
}

