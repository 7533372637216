import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#003fae',
    },
    secondary: {
      main: '#3370db',
    },
    error: {
      main: '#ff0000',
    },
  },
  typography: {
    caption: {
      color: '#afafaf',
    },
  },

  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          boxShadow: 'none',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(206, 206, 212, 0.19)',
          padding: '8px 16px 8px 16px',
        },
        title: {
          fontWeight: 'bold',
          fontSize: '16px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeLarge: {
          fontSize: '18px',
        },
        sizeMedium: {
          fontSize: '16px',
        },
        sizeSmall: {
          fontSize: '14px',
        },
        textPrimary: {
          textTransform: 'uppercase',
          color: '#003fae',
        },
        textSecondary: {
          textTransform: 'uppercase',
          color: '#ff0000',
        },
        outlinedPrimary: {
          color: '#003fae',
        },
        containedPrimary: {
          backgroundColor: '#003fae',
        },
        containedSecondary: {
          backgroundColor: '#9bcccc',
          ':hover': {
            backgroundColor: '#61a5a5',
          },
        },
      },
    },
  },
});
