import React from 'react';
import { Input, Select as MaterialSelect, SelectProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { countryList } from '../renderers/countries';

interface Props extends SelectProps {
  options: Array<{ value: any; label: string }>;
  flavor?: 'menubar' | 'normal';
}

const StyledSelectMenuBar = styled(MaterialSelect)({
  '&.MuiInput-underline:hover:before': {
    borderBottom: '2px solid white',
  },
  '&.MuiInput-underline:after': {
    borderBottom: '2px solid white',
  },
  '& .MuiSelect-icon': {
    color: 'white',
  },
  '& .MuiSelect-select': {
    borderBottom: '2px solid white',
  },
});

const StyledSelectNormal = styled(MaterialSelect)({
  '&.MuiInput-underline:hover:before': {
    borderBottom: '2px solid var(--peacock-blue)',
  },
  '&.MuiInput-underline:after': {
    borderBottom: '2px solid var(--peacock-blue)',
  },
});

const SelectInput = styled(Input)({
  '& label.Mui-focused': {
    color: 'var(--peacock-blue)',
  },
  '& .MuiSelect-nativeInput': {
    color: 'var(--black)',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
});

const SelectInputMenuBar = styled(Input)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiSelect-nativeInput': {
    color: 'white',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
});

export const Select: React.FC<Props> = ({ options, flavor, ...rest }: Props) => {
  if (flavor == 'menubar') {
    return (
      <FormControl variant='standard' sx={{ flex: '1 1 0px' }}>
        <InputLabel id={rest.labelId}>{rest.label}</InputLabel>
        <StyledSelectMenuBar sx={{ color: 'white' }} {...rest} input={<SelectInputMenuBar />}>
          {options.map(({ value, label }, index) => (
            <MenuItem key={index} value={value}>
              {label}
            </MenuItem>
          ))}
        </StyledSelectMenuBar>
      </FormControl>
    );
  }
  return (
    <FormControl variant='standard' sx={{ flex: '1 1 0px' }}>
      <InputLabel id={rest.labelId}>{rest.label}</InputLabel>
      <StyledSelectNormal {...rest} input={<SelectInput />}>
        {options.map(({ value, label }, index) => (
          <MenuItem key={index} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelectNormal>
    </FormControl>
  );
};

export const CountrySelect: React.FC<Omit<Props, 'options'>> = (props: Omit<Props, 'options'>) => {
  const options = countryList.map((c) => {
    return {
      label: c.name,
      value: c.name,
    };
  });
  return <Select options={options} {...props} />;
};
