/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiAdmin,
    ApiAdminFromJSON,
    ApiAdminToJSON,
    ApiCreateAdminRequest,
    ApiCreateAdminRequestFromJSON,
    ApiCreateAdminRequestToJSON,
    ApiInvestorProfile,
    ApiInvestorProfileFromJSON,
    ApiInvestorProfileToJSON,
    ApiUpdateSelectedEntity,
    ApiUpdateSelectedEntityFromJSON,
    ApiUpdateSelectedEntityToJSON,
} from '../models';

export interface AddRepresentativeRequest {
    companyId: string;
    apiCreateAdminRequest: Array<ApiCreateAdminRequest>;
}

export interface GetRepresentativeRequest {
    personId: string;
}

export interface SelectLPRequest {
    apiUpdateSelectedEntity: ApiUpdateSelectedEntity;
}

/**
 * 
 */
export class RepresentativesApi extends runtime.BaseAPI {

    /**
     * adds new representative
     */
    async addRepresentativeRaw(requestParameters: AddRepresentativeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiAdmin>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling addRepresentative.');
        }

        if (requestParameters.apiCreateAdminRequest === null || requestParameters.apiCreateAdminRequest === undefined) {
            throw new runtime.RequiredError('apiCreateAdminRequest','Required parameter requestParameters.apiCreateAdminRequest was null or undefined when calling addRepresentative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/companies/{companyId}/representatives`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.apiCreateAdminRequest.map(ApiCreateAdminRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiAdminFromJSON));
    }

    /**
     * adds new representative
     */
    async addRepresentative(requestParameters: AddRepresentativeRequest, initOverrides?: RequestInit): Promise<Array<ApiAdmin>> {
        const response = await this.addRepresentativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns all the representatives
     */
    async getAllRepresentativesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiAdmin>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/representatives`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiAdminFromJSON));
    }

    /**
     * returns all the representatives
     */
    async getAllRepresentatives(initOverrides?: RequestInit): Promise<Array<ApiAdmin>> {
        const response = await this.getAllRepresentativesRaw(initOverrides);
        return await response.value();
    }

    /**
     * fetches one representative info
     */
    async getRepresentativeRaw(requestParameters: GetRepresentativeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiInvestorProfile>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getRepresentative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/representatives/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInvestorProfileFromJSON(jsonValue));
    }

    /**
     * fetches one representative info
     */
    async getRepresentative(requestParameters: GetRepresentativeRequest, initOverrides?: RequestInit): Promise<ApiInvestorProfile> {
        const response = await this.getRepresentativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * select the LP to represent
     */
    async selectLPRaw(requestParameters: SelectLPRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiInvestorProfile>> {
        if (requestParameters.apiUpdateSelectedEntity === null || requestParameters.apiUpdateSelectedEntity === undefined) {
            throw new runtime.RequiredError('apiUpdateSelectedEntity','Required parameter requestParameters.apiUpdateSelectedEntity was null or undefined when calling selectLP.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/limited-partner/select`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiUpdateSelectedEntityToJSON(requestParameters.apiUpdateSelectedEntity),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInvestorProfileFromJSON(jsonValue));
    }

    /**
     * select the LP to represent
     */
    async selectLP(requestParameters: SelectLPRequest, initOverrides?: RequestInit): Promise<ApiInvestorProfile> {
        const response = await this.selectLPRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
