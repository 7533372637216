import { useQuery } from 'react-query';
import {
  ApiDocumentLink,
  fundDocumentApi,
  investorDocumentApi,
  investorFundDocumentApi,
} from '../apis';

export const useFundDocuments = (fundId: string) => {
  const { data, isLoading } = useQuery(['fund-documents', fundId], async () => {
    return fundDocumentApi.getFundDocuments({ fundId: fundId });
  });

  return {
    fundDocuments: data,
    isLoading: isLoading,
  };
};

export const getDocumentDownloadLink = async (
  documentId: string
): Promise<ApiDocumentLink> => {
  return await investorDocumentApi.getDocumentDownloadLink({
    documentId: documentId,
  });
};

export const getFundDocumentDownloadLink = async (
  fundId: string,
  documentId: string
): Promise<ApiDocumentLink> => {
  return await fundDocumentApi.getDownloadLink({
    fundId: fundId,
    documentId: documentId,
  });
};

export const userInvestorFundDocuments = (fundId: string) => {
  const { data, isLoading } = useQuery(
    ['investor-fund-documents', fundId],
    async () => {
      return investorFundDocumentApi.getInvestorFundDocuments({
        fundId: fundId,
      });
    }
  );

  return {
    fundDocuments: data,
    isLoading: isLoading,
  };
};
