import React from 'react';
import { ExampleDocumentsPagePlaceholder } from './ExampleDocumentsPagePlaceholder';

export const InvestmentReportsPage: React.FC = () => {
  function createData(srNo: number, documentName: string, uploadedAt: string) {
    return { srNo, documentName, uploadedAt };
  }

  const rows = [
    createData(1, 'Q2 2021 Report', '15-06-2021'),
    createData(2, 'Q3 2021 Report', '14-09-2021'),
    createData(3, 'Q4 2021 Report', '16-12-2021'),
  ];

  return (
    <ExampleDocumentsPagePlaceholder
      rows={rows}
      fileName='example_report.pdf'
      title='My investment reports'
    />
  );
};
