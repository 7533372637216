import React from 'react';
import { MainContent } from '../../common/MainContent';
import { Box, CircularProgress, Toolbar, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { ApiInvestorProfile } from '../../api';
import { TabPanel, Tabs } from '../../common/Tabs';
import { amlCompanyUISchema, amlPersonUISchema } from './uiSchemas';
import { useMachine } from '@xstate/react';
import { AmlEventObject, amlStateMachine } from './amlStateMachine';
import { Button } from '../../common/Button';
import { JsonForms } from '@jsonforms/react';
import { amlSchema } from './dataSchema';
import { poolSideJsonFormRenderers } from '../../common/renderers';
import { materialCells } from '@jsonforms/material-renderers';

function mergeMeta(meta: any) {
  return Object.keys(meta).reduce((acc, key) => {
    const value = meta[key];

    // Assuming each meta value is an object
    Object.assign(acc, value);

    return acc;
  }, {});
}

export const AmlForm = () => {
  const [current, send] = useMachine(amlStateMachine);

  useQuery(['investorProfile'], {
    onSuccess: (data) => {
      const newProfile = data as ApiInvestorProfile;
      const shouldReset =
        current.context.targetProfile != undefined &&
        newProfile.selectedEntityId != undefined &&
        newProfile.selectedEntityId != current.context.targetProfile.selectedEntityId;
      if (shouldReset) {
        send({
          type: 'reset',
        });
      }
    },
  });
  if (current.context.isLoading) {
    return <CircularProgress />;
  }
  const meta: Record<string, string> = mergeMeta(current.meta);
  return (
    <MainContent headingText='Onboarding AML Form'>
      <Box display='flex' flexDirection='column' width='780px' marginTop='20px' rowGap='20px'>
        <Box marginBottom='16px'>
          <Typography>
            In accordance with the Estonian Money Laundering and Terrorist Financing Prevention Act
            in order to register as an investor of the limited partnership fund (‘usaldusfond’) it
            is necessary to provide the data requested below. The data will be processed on behalf
            of the fund in line with the fund rules and the Privacy Policy (attached hereto).
          </Typography>
        </Box>
        <Tabs
          value={current.context.tab}
          tabs={current.context.tabNames}
          panels={(value: number) => {
            let companyTab = <></>;
            if (current.context.companyAmlData != undefined) {
              companyTab = (
                <TabPanel value={value} index={1}>
                  {meta.message != undefined && (
                    <Typography marginBottom='16px' color='#ff0000'>
                      {meta.message}. Current status: {current.context.companyAml?.amlFormStatus}
                    </Typography>
                  )}
                  <JsonForms
                    schema={amlSchema}
                    uischema={amlCompanyUISchema}
                    data={current.context.companyAmlData}
                    renderers={poolSideJsonFormRenderers}
                    readonly={current.context.isReadonly}
                    cells={materialCells}
                    onChange={({ data }) => {
                      send({
                        type: 'amlFormUpdate',
                        companyAmlData: data,
                      } as AmlEventObject);
                    }}
                  />
                </TabPanel>
              );
            }
            return (
              <>
                <TabPanel value={value} index={0}>
                  {meta.message != undefined && (
                    <Typography marginBottom='16px' color='#ff0000'>
                      {meta.message}. Current status: {current.context.personAml?.amlFormStatus}
                    </Typography>
                  )}
                  <JsonForms
                    schema={amlSchema}
                    uischema={amlPersonUISchema}
                    data={current.context.personAmlData}
                    renderers={poolSideJsonFormRenderers}
                    cells={materialCells}
                    readonly={current.context.isReadonly}
                    onChange={({ data }) => {
                      send({
                        type: 'amlFormUpdate',
                        personAmlData: data,
                      } as AmlEventObject);
                    }}
                  />
                </TabPanel>
                {companyTab}
              </>
            );
          }}
        ></Tabs>
        <Toolbar
          sx={{
            paddingLeft: '0px',
            paddingRight: '0px',
            flexGrow: 1,
            columnGap: '5px',
          }}
        >
          <Box flexGrow={1}></Box>
          {current.context.saveLabel && (
            <Button
              variant='PrimaryAction'
              onClick={() => {
                send({
                  type: 'save',
                } as AmlEventObject);
              }}
            >
              {current.context.saveLabel}
            </Button>
          )}
          {current.context.backLabel && (
            <Button
              variant='PrimaryAction'
              onClick={() => {
                send({
                  type: 'prev',
                } as AmlEventObject);
              }}
            >
              {current.context.backLabel}
            </Button>
          )}
          {current.context.nextLabel && (
            <Button
              variant='PrimaryAction'
              onClick={() => {
                send({
                  type: 'next',
                } as AmlEventObject);
              }}
            >
              {current.context.nextLabel}
            </Button>
          )}
        </Toolbar>
      </Box>
    </MainContent>
  );
};
