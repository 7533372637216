import { TextField as MaterialTextField, TextFieldProps } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';

const phoneRE = /^[+]?[(]?[\d]{3}[)]?[-\s.]?[\d]{3}[-\s.]?[\d]{4,6}$/im;
export const yupPhone = yup.string().matches(phoneRE, {
  message: 'Phone number is incorrect!',
  excludeEmptyString: true,
});

export const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  return <MaterialTextField size='medium' variant='standard' {...props} />;
};

export const CurrencyTextField: React.FC<TextFieldProps> = (
  props: TextFieldProps
) => {
  return (
    <MaterialTextField
      size='medium'
      variant='standard'
      InputProps={{ inputComponent: NumberFormatCustom as any }}
      {...props}
    />
  );
};
const NumberFormatCustom: React.FC<any> = React.forwardRef(
  function NumberFormatCustom(props: any, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix='€'
      />
    );
  }
);
