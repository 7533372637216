import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MainContent } from '../../common/MainContent';
import React, { useState } from 'react';
import { BreadcrumbLink } from '../../common/Breadcrumbs';
import { ApiAdmin, ApiCompany, demoApi, EmailType, SendEmailRequest } from '../../apis';
import { Box, CircularProgress, Toolbar } from '@mui/material';
import { TabPanel, Tabs } from '../../common/Tabs';
import { DisplayField } from '../../common/DisplayField';
import { GridCellValue, GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import { actionColumn, column, customColumn, DataGrid } from '../../common/DataGrid';
import { Button } from '../../common/Button';
import { ApiAmlInfo, LimitedPartnerType } from '../../api';
import { useGeneralPartner } from '../../hooks/useGeneralPartner';
import { DateTime } from 'luxon';
import { GridSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { getInitialState, handleSortingAndPagingParams } from '../../util/gridSorting';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useSendEmails, useDisablePersons } from './generalPartnerActions';
import { useAlert } from 'react-alert';
import { GridRowId } from '@mui/x-data-grid/models/gridRows';
import { useQueryClient } from 'react-query';

type Params = {
  generalPartnerId: string;
  selectedTab: string;
};

type TabPanelContent = {
  company: ApiCompany;
};

const CompanyInformation: React.FC<TabPanelContent> = ({ company }) => {
  const clickHandler = async () => {
    await demoApi.refreshCompanyWithDemo({
      companyId: String(company.id),
      body: true,
    });
  };
  return (
    <Box>
      <DisplayField label='Name'>{company.name}</DisplayField>
      <DisplayField label='Address'>{company.address}</DisplayField>
      <DisplayField label='Registry code'>{company.registryCode}</DisplayField>
      <DisplayField label='Risk'>{company.risk}</DisplayField>
      <Toolbar
        disableGutters={true}
        sx={{
          paddingLeft: '0px',
          paddingRight: '0px',
          flexGrow: 1,
          columnGap: '5px',
        }}
      >
        <Button variant='TertiaryGhost' onClick={clickHandler}>
          Demo
        </Button>
        <Button variant='TertiaryAction' href={`/general-partners/${company.id}/edit`}>
          Edit
        </Button>
      </Toolbar>
    </Box>
  );
};

const Funds: React.FC<TabPanelContent> = ({ company }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const apiRef = useGridApiRef();
  React.useEffect(() => {
    handleSortingAndPagingParams(apiRef, setSearchParams);
  }, [apiRef, company.funds]);

  const initialState: GridInitialStatePro = getInitialState(searchParams);

  const columns: GridColumns = [
    column({ headerName: 'Name', field: 'name', width: 200 }),
    column({ headerName: 'Fund size', field: 'fundSize', width: 150 }),
    column({ headerName: 'Symbol', field: 'symbol', width: 150 }),
    column({ headerName: 'Service type', field: 'serviceType', width: 150 }),
    actionColumn(() => {
      return {
        label: 'View',
        onAction: (params) => {
          navigate(`${params.id}/info`);
        },
      };
    }),
  ];
  return (
    <DataGrid
      columns={columns}
      rows={company.funds || []}
      noRowsLabel='No funds'
      initialState={initialState}
      apiRef={apiRef}
    />
  );
};

const flattenObjct = (obj: Record<string, any>, prefix: string, acc: Record<string, any>) => {
  for (const key of Object.keys(obj)) {
    const objType = typeof obj[key];
    const objIsDate = obj[key] instanceof Date;
    if (objType === 'object' && !('lastAmlEvent' in obj[key]) && !objIsDate) {
      flattenObjct(obj[key], key + '_', acc);
    } else {
      acc[prefix + key] = obj[key];
    }
  }
};

const amlInfoValueFormatter = (v: GridValueFormatterParams) => {
  const amlInfo: ApiAmlInfo = v.value as ApiAmlInfo;
  if (amlInfo != undefined) {
    const amlEvent = DateTime.fromJSDate(amlInfo.lastAmlEvent!);
    const days = DateTime.now().diff(amlEvent, 'days').toObject();
    const daysNumber = Math.floor(days.days!);
    return `${daysNumber} days (${amlInfo.lastAmlUser})`;
  }
  return '';
};

const amlInfoSorter = (v1: GridCellValue, v2: GridCellValue) => {
  if (v1 == undefined) return -1;
  if (v2 == undefined) return 1;

  const diff =
    (v1 as ApiAmlInfo).lastAmlEvent!.getTime() - (v2 as ApiAmlInfo).lastAmlEvent!.getTime();
  if (diff < 0) return -1;
  if (diff > 0) return 1;
  return diff;
};

const LimitedPartners: React.FC<TabPanelContent> = ({ company }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const alert = useAlert();
  const sendEmailsMutation = useSendEmails(() => {
    alert.show('Emails sent successfully!', { type: 'success' });
  });

  const queryClient = useQueryClient();
  const disablePersonsMutation = useDisablePersons(() => {
    queryClient.invalidateQueries();
    alert.show('Persons disabled successfully!', { type: 'success' });
  });

  const apiRef = useGridApiRef();
  React.useEffect(() => {
    handleSortingAndPagingParams(apiRef, setSearchParams);
  }, [apiRef, company.limitedPartners]);

  const initialState: GridInitialStatePro = getInitialState(searchParams);

  const lps = company.limitedPartners?.map((cp) => {
    const acc: Record<string, any> = {};
    flattenObjct(cp, '', acc);
    acc['id'] = cp.company?.id + ':' + cp.person?.id;
    return acc;
  });

  const sendOnboardingReminderEmailClickHandler = async () => {
    const selectedPersonIds = [];
    for (let i = 0; i < selectionModel.length; i++) {
      const personUuid = selectionModel[i].toString().split(':')[1];
      selectedPersonIds.push(personUuid);
    }

    const emailRequest: SendEmailRequest = {
      apiSendEmailRequest: {
        type: EmailType.OnboardingReminder,
        personIds: selectedPersonIds,
      },
    };

    await sendEmailsMutation.mutate(emailRequest);
  };

  const disablePersonsClickHandler = () => {
    const selectedPersonIds = [];
    for (let i = 0; i < selectionModel.length; i++) {
      const personUuid = selectionModel[i].toString().split(':')[1];
      selectedPersonIds.push(personUuid);
    }

    disablePersonsMutation.mutate({
      apiDisablePersonsRequest: {
        personIds: selectedPersonIds,
      },
    });
  };

  const columns: GridColumns = [
    customColumn({
      minWidth: 240,
      maxWidth: 240,
      renderCell: (params) => {
        const lpId =
          params.row.limitedPartnerType == LimitedPartnerType.NaturalPerson
            ? params.row.person_id
            : params.row.company_id;

        const detailsLink = `/general-partners/${company.id}/limited-partners/${lpId}/${params.row.limitedPartnerType}/edit`;

        const personId = params.row.person_id;
        const companyId =
          params.row.limitedPartnerType == LimitedPartnerType.Company
            ? params.row.company_id
            : 'none';
        const amlLink = `/general-partners/${company.id}/limited-partners/${personId}/${companyId}/aml`;
        return (
          <Box>
            <Button sx={{ marginRight: '10px' }} variant='TertiaryAction' href={detailsLink}>
              Details
            </Button>
            <Button sx={{ marginRight: '10px' }} variant='TertiaryAction' href={amlLink}>
              AML
            </Button>
          </Box>
        );
      },
    }),
    column({
      headerName: 'Type',
      field: 'limitedPartnerType',
      width: 150,
    }),
    customColumn({
      headerName: 'Name',
      field: 'person_lastName',
      align: 'left',
      minWidth: 150,
      renderCell: (params) => {
        const detailsLink = `/general-partners/${company.id}/person/${params.row.person_id}`;
        return (
          <Button sx={{ marginRight: '10px' }} variant='text' size='small' href={detailsLink} style={{textTransform: 'none', justifyContent:'left'}}>
            {params.row.person_firstName} {params.row.person_lastName}
          </Button>
        )
      }
    }),
    column({
      headerName: 'Email',
      field: 'person_email',
      width: 150,
    }),
    {
      headerName: 'Person Aml',
      field: 'personAmlStatus',
      valueGetter: (params) => params.row.personAmlInfo?.amlStatus,
      width: 150,
    },
    {
      headerName: 'Person Aml Info',
      field: 'personAmlInfo',
      valueFormatter: amlInfoValueFormatter,
      sortComparator: amlInfoSorter,
    },
    column({
      headerName: 'Verification',
      field: 'person_veriffStatus',
      width: 150,
    }),
    column({
      headerName: 'Risk',
      field: 'risk',
      width: 75,
    }),
    column({
      headerName: 'Company Name',
      field: 'company_name',
      width: 150,
    }),
    column({
      headerName: 'Registry code',
      field: 'company_registryCode',
      width: 150,
    }),
    {
      headerName: 'Company Aml',
      field: 'companyAmlStatus',
      valueGetter: (params) => params.row.companyAmlInfo?.amlStatus,
      width: 150,
    },
    {
      headerName: 'Company Aml Info',
      field: 'companyAmlInfo',
      valueFormatter: amlInfoValueFormatter,
      sortComparator: amlInfoSorter,
    },
    column({
      headerName: 'Last email at',
      field: 'person_lastEmailAt',
      width: 150,
    }),
  ];

  return (
    <div>
      <Button
        variant='TertiaryGhost'
        onClick={sendOnboardingReminderEmailClickHandler}
        disabled={selectionModel.length == 0}
      >
        Send reminder email
      </Button>
      <Button
        variant='TertiaryGhost'
        onClick={disablePersonsClickHandler}
        disabled={selectionModel.length == 0}
      >
        Disable
      </Button>
      <DataGrid
        columns={columns}
        rows={lps || []}
        noRowsLabel='No companies'
        initialState={initialState}
        apiRef={apiRef}
        checkboxSelection={true}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
      />
    </div>
  );
};

const Representatives: React.FC<TabPanelContent> = ({ company }) => {
  const [mode, setMode] = useState<'details' | 'edit' | 'list'>('list');
  const [selectedRepresentative, setSelectedRepresentative] = useState<ApiAdmin>();
  const [searchParams, setSearchParams] = useSearchParams();

  const apiRef = useGridApiRef();
  React.useEffect(() => {
    handleSortingAndPagingParams(apiRef, setSearchParams);
  }, [apiRef, company.representatives]);

  const initialState: GridInitialStatePro = getInitialState(searchParams);

  const columns: GridColumns = [
    column({ headerName: 'Email', field: 'email', width: 250 }),
    column({ headerName: 'First name', field: 'firstName', width: 150 }),
    column({ headerName: 'Last name', field: 'lastName', width: 150 }),
    column({ headerName: 'Legal powers', field: 'legalPowers', width: 150 }),
    actionColumn(() => {
      return {
        label: 'Details',
        onAction: (params) => {
          setSelectedRepresentative(params.row);
          setMode('details');
        },
      };
    }),
  ];

  const componentMap = {
    list: (
      <DataGrid
        columns={columns}
        rows={company.representatives || []}
        noRowsLabel='No representatives'
        initialState={initialState}
        apiRef={apiRef}
      />
    ),
    details: selectedRepresentative ? (
      <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ gap: '3vh' }}>
        <Button
          variant='back'
          onClick={() => {
            setSelectedRepresentative(undefined);
            setMode('list');
          }}
        >
          Back
        </Button>
        <DisplayField label='Email'>{selectedRepresentative.email}</DisplayField>
        <DisplayField label='First name'>{selectedRepresentative.firstName}</DisplayField>
        <DisplayField label='Last name'>{selectedRepresentative.lastName}</DisplayField>
        <DisplayField label='Legal Powers'>{selectedRepresentative.legalPowers}</DisplayField>
      </Box>
    ) : (
      <></>
    ),
    edit: <></>,
  };

  return componentMap[mode];
};

interface ButtonBarProps {
  tab: number;
  generalPartnerId: string;
}

const ButtonBar: React.FC<ButtonBarProps> = ({ tab, generalPartnerId }) => {
  if (tab === 1) {
    return (
      <Button variant='SecondaryAction' href={`/general-partners/${generalPartnerId}/funds/add`}>
        Add Fund
      </Button>
    );
  } else if (tab === 2) {
    return (
      <Button
        variant='SecondaryAction'
        href={`/general-partners/${generalPartnerId}/limited-partners/add`}
      >
        Add Limited Partner
      </Button>
    );
  } else if (tab === 3) {
    return <Button variant='SecondaryAction'>Add Representative</Button>;
  }
  return null;
};

const urlTabMap = new Map<string, string>([
  ['info', '0'],
  ['funds', '1'],
  ['limited-partners', '2'],
  ['representatives', '3'],
  ['0', 'info'],
  ['1', 'funds'],
  ['2', 'limited-partners'],
  ['3', 'representatives'],
]);

export const GeneralPartnerDashboard = () => {
  const { generalPartnerId, selectedTab } = useParams<Params>();
  const navigate = useNavigate();

  const currentTabName = selectedTab === undefined ? 'info' : selectedTab;
  const currentTabIndex = Number(urlTabMap.get(currentTabName) || '0');

  const [tab, setTab] = useState<number>(currentTabIndex);

  if (generalPartnerId === undefined) {
    return <CircularProgress />;
  }

  const { generalPartner, isGeneralPartnerLoading } = useGeneralPartner(generalPartnerId);

  if (generalPartner === undefined || isGeneralPartnerLoading) {
    return <CircularProgress />;
  }

  const tabNames = ['Company information', 'Funds', 'Limited partners', 'Representatives'];

  return (
    <MainContent
      links={[
        <BreadcrumbLink key={1} to='/general-partners'>
          All companies
        </BreadcrumbLink>,
        <BreadcrumbLink key={2} to='#'>
          {generalPartner.name}
        </BreadcrumbLink>,
      ]}
      headingText={generalPartner.name}
      headerButtons={<ButtonBar tab={tab} generalPartnerId={generalPartner.id} />}
    >
      <Tabs
        value={tab}
        tabs={tabNames}
        onChange={(_, index) => {
          navigate(`../${generalPartnerId}/${urlTabMap.get(index.toString()) || 'info'}`, {
            replace: true,
          });
          setTab(index);
        }}
        panels={(value: number) => {
          return (
            <>
              <TabPanel value={value} index={0}>
                <CompanyInformation company={generalPartner} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Funds company={generalPartner} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <LimitedPartners company={generalPartner} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Representatives company={generalPartner} />
              </TabPanel>
            </>
          );
        }}
      />
    </MainContent>
  );
};
