/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiDocumentLink,
    ApiDocumentLinkFromJSON,
    ApiDocumentLinkToJSON,
} from '../models';

export interface GetDocumentDownloadLinkRequest {
    documentId: string;
}

/**
 * 
 */
export class InvestorDocumentApi extends runtime.BaseAPI {

    /**
     * download document
     */
    async getDocumentDownloadLinkRaw(requestParameters: GetDocumentDownloadLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiDocumentLink>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentDownloadLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/limited-partner/documents/{documentId}/download`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiDocumentLinkFromJSON(jsonValue));
    }

    /**
     * download document
     */
    async getDocumentDownloadLink(requestParameters: GetDocumentDownloadLinkRequest, initOverrides?: RequestInit): Promise<ApiDocumentLink> {
        const response = await this.getDocumentDownloadLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
