import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useLimitedPartnerInvestments } from '../../hooks/useLimitedPartnerInvestments';
import { MainContent } from '../../common/MainContent';
import { actionColumn, column, DataGrid } from '../../common/DataGrid';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import { ApiCompany } from '../../api';
import { useNavigate } from 'react-router-dom';
import { format } from '../../util/currency';

export const DashboardPage: React.FC = () => {
  const { isDashboardLoading, limitedPartnerInvestments } =
    useLimitedPartnerInvestments();
  const navigate = useNavigate();

  const investorDashboardColumns: GridColumns = [
    column({
      headerName: 'Instrument',
      field: 'name',
      width: 200,
    }),
    column({ headerName: 'Symbol', field: 'symbol', width: 150 }),
    column({
      headerName: 'Ownership',
      valueFormatter: (params) =>
        params.value && (params.value as string) + '%',
      field: 'ownership',
      width: 150,
    }),
    column({
      headerName: 'Paid in capital',
      valueFormatter: (params) =>
        params.value && format(params.value as number),
      field: 'paidInCapital',
      width: 150,
    }),
    column({
      headerName: 'Fair value',
      valueFormatter: (params) =>
        params.value && format(params.value as number),
      field: 'fairValue',
      width: 150,
    }),
    actionColumn(() => {
      return {
        label: 'Overview',
        onAction: ({ row }: GridCellParams<any, ApiCompany>) => {
          navigate(`${row.id}/overview`);
        },
      };
    }),
  ];

  let limitedPartnerTableComponent;
  if (limitedPartnerInvestments) {
    limitedPartnerTableComponent = (
      <DataGrid
        columns={investorDashboardColumns}
        rows={limitedPartnerInvestments}
      />
    );
  } else {
    limitedPartnerTableComponent = (
      <Typography>Could not load your investments</Typography>
    );
  }

  return (
    <MainContent headingText='My investments'>
      <Box paddingX={5}>
        {isDashboardLoading ? (
          <Box padding={4} display='flex' justifyContent='center'>
            <CircularProgress />
          </Box>
        ) : (
          limitedPartnerTableComponent
        )}
      </Box>
    </MainContent>
  );
};
