import React, { useEffect } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { BreadcrumbLink } from '../../common/Breadcrumbs';
import { MainContent } from '../../common/MainContent';
import {
  useAddGPCompnay,
  useCompanyInfo,
  useUpdateCompanies,
} from './generalPartnerActions';
import {
  CompanyForm,
  FormDataCompany,
  schema as companySchema,
} from './CompanyForm';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, Toolbar } from '@mui/material';
import { Button } from '../../common/Button';
import { useQueryClient } from 'react-query';
import { UpdateCompanyRequest } from '../../api';

const schema = yup
  .object({
    company: companySchema,
  })
  .required();

export type GPFormParams = {
  generalPartnerId?: string;
};

export type GPFormData = {
  company: FormDataCompany;
};

export const GPCompanyForm = () => {
  const params = useParams<GPFormParams>();
  const { data: generalPartner, isLoading } = useCompanyInfo(
    params.generalPartnerId
  );
  const formHook: UseFormReturn<GPFormData, object> = useForm<GPFormData>({
    mode: 'onBlur',
    defaultValues: {
      company: {
        companyData: {
          address: '',
          registryCode: '',
          name: '',
        },
      },
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (generalPartner) {
      reset({
        company: {
          risk: generalPartner.risk,
          companyData: {
            address: generalPartner.address,
            registryCode: generalPartner.registryCode,
            name: generalPartner.name,
          },
        },
      });
      console.log(generalPartner);
    }
  }, [generalPartner]);

  const updateGPCompanyMutation = useUpdateCompanies(
    params.generalPartnerId ? params.generalPartnerId : '',
    () => {
      queryClient.invalidateQueries('general-partner');
      navigate(`/general-partners/${params.generalPartnerId}/info`);
    }
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const addGPCompanyMutation = useAddGPCompnay(() => {
    queryClient.invalidateQueries('general');
    navigate('/general-partners');
  });

  const cancelRref = generalPartner
    ? `/general-partners/${params.generalPartnerId}/info`
    : '/general-partners';

  const { trigger, getValues, reset } = formHook;
  let breadcrumbs = [
    <BreadcrumbLink key={1} to='/general-partners'>
      All companies
    </BreadcrumbLink>,
  ];
  if (params.generalPartnerId && generalPartner != undefined) {
    breadcrumbs = breadcrumbs.concat([
      <BreadcrumbLink key={2} to={`/general-partners/${generalPartner.id}`}>
        {generalPartner.name}
      </BreadcrumbLink>,
      <BreadcrumbLink key={3} to='#'>
        Edit
      </BreadcrumbLink>,
    ]);
  } else {
    breadcrumbs = breadcrumbs.concat([
      <BreadcrumbLink key={2} to='#'>
        Add Company
      </BreadcrumbLink>,
    ]);
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <MainContent links={breadcrumbs} headingText={generalPartner?.name}>
      <FormProvider {...formHook}>
        <Box
          display='flex'
          flexDirection='column'
          width='780px'
          marginTop='20px'
          rowGap='20px'
          marginLeft='40px'
        >
          <CompanyForm />
          <Toolbar
            sx={{
              paddingLeft: '0px',
              paddingRight: '0px',
              flexGrow: 1,
              columnGap: '5px',
            }}
          >
            <Box flexGrow={1}></Box>
            <Button href={cancelRref}>Cancel</Button>
            <Button
              variant='text'
              onClick={async () => {
                const result = await trigger();
                if (result) {
                  const formParams: GPFormData = getValues();
                  if (generalPartner == undefined) {
                    addGPCompanyMutation.mutate({
                      apiUpdateCompanyRequest: formParams.company,
                    });
                  } else {
                    const payload: UpdateCompanyRequest = {
                      companyId: generalPartner.id,
                      apiUpdateCompanyRequest: {
                        risk: formParams.company.risk,
                        companyData: formParams.company.companyData,
                        representatives: generalPartner.representatives
                          ? generalPartner.representatives.map((r) => {
                              return {
                                adminInfo: r,
                                company: generalPartner.id,
                              };
                            })
                          : [],
                      },
                    };
                    updateGPCompanyMutation.mutate(payload);
                  }
                }
              }}
            >
              Save
            </Button>
          </Toolbar>
        </Box>
      </FormProvider>
    </MainContent>
  );
};
