import { Box, Toolbar } from '@mui/material';
import React from 'react';
import { Breadcrumbs } from './Breadcrumbs';
import { Heading } from './Heading';

export interface Props {
  children?: React.ReactNode;
  links?: Array<React.ReactNode>;
  headingText?: string;
  headingComponent?: React.ReactNode;
  headerButtons?: React.ReactNode;
}
export const MainContent: React.FC<Props> = (props: Props) => {
  const hasLinks = props.links != undefined && props.links.length > 0;
  return (
    <Box
      minWidth='60vw'
      display='flex'
      flexDirection='column'
      style={{
        flexGrow: 1,
        backgroundColor: 'white',
        margin: '40px 40px 0px 40px',
        borderRadius: '8px',
        boxShadow:
          '0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.2)',
      }}
    >
      {hasLinks && (
        <Box style={{ margin: '40px 40px 20px 40px' }}>
          <Breadcrumbs>{props.links}</Breadcrumbs>
        </Box>
      )}
      <Box
        style={{ marginTop: hasLinks ? '0' : '40px', marginLeft: '40px' }}
        display='flex'
      >
        {props.headingComponent ? (
          props.headingComponent
        ) : (
          <Heading text={props.headingText} />
        )}
        <Toolbar
          sx={{
            flexGrow: 1,
            columnGap: '5px',
            flexDirection: 'row-reverse',
          }}
        >
          {props.headerButtons}
        </Toolbar>
      </Box>
      <Box marginLeft='40px'>{props.children}</Box>
    </Box>
  );
};
