import React, { useEffect } from 'react';
import { MainContent } from '../../common/MainContent';
import { BreadcrumbLink } from '../../common/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Toolbar } from '@mui/material';
import { useGeneralPartner } from '../../hooks/useGeneralPartner';
import { useFund, useUpdateFund } from '../../hooks/useFund';
import { ApiFund, ApiInvestment, InstrumentType, UpdateFundRequest } from '../../api';
import { Controller, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { TwoColumnRow } from '../../common/FormElements';
import { CurrencyTextField, TextField } from '../../common/TextField';
import { Select } from '../../common/inputs/Select';
import { Button } from '../../common/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const schema = yup.object({
  name: yup.string().required('Name is a required field!'),
  sector: yup.string().required('Sector is a required field!'),
  instrument: yup.string().required('instrument is a required field!'),
});

export type InvestmentFormParams = {
  generalPartnerId: string;
  fundId: string;
  investmentId?: string;
};

const computeUpdatePayload = (
  fund: ApiFund,
  formParams: ApiInvestment
): UpdateFundRequest | undefined => {
  if (fund.id && fund.registry && fund.investments) {
    const payload: UpdateFundRequest = {
      fundId: fund.id,
      apiUpsertFundRequest: {
        registry: fund.registry,
        investments: [...fund.investments],
        fundSize: fund.fundSize,
        name: fund.name,
        serviceType: fund.serviceType,
        symbol: fund.symbol,
      },
    };
    if (!formParams.id) {
      payload.apiUpsertFundRequest.investments =
        payload.apiUpsertFundRequest.investments.concat(formParams);
    } else {
      const investmentIdx = payload.apiUpsertFundRequest.investments.findIndex(
        (investment) => investment.id == formParams.id
      );
      payload.apiUpsertFundRequest.investments[investmentIdx] = formParams;
    }
    return payload;
  }
  return undefined;
};
export const InvestmentForm = () => {
  const { generalPartnerId, fundId, investmentId } = useParams<InvestmentFormParams>();
  if (fundId === undefined || generalPartnerId === undefined) {
    return <CircularProgress />;
  }
  const navigate = useNavigate();

  const { generalPartner, isGeneralPartnerLoading } = useGeneralPartner(generalPartnerId);
  const { fund, isFundLoading } = useFund(generalPartnerId, fundId);
  const useUpdateFundMutation = useUpdateFund();

  const formHook: UseFormReturn<ApiInvestment, object> = useForm<ApiInvestment>({
    mode: 'onBlur',
    defaultValues: {
      fairValue: 0,
      name: '',
      instrument: InstrumentType.Equity,
      sector: '',
    },
    resolver: yupResolver(schema),
  });
  const { trigger, control, getValues, reset, formState } = formHook;

  useEffect(() => {
    if (investmentId && fund) {
      const selectedInvestment = fund.investments?.find(
        (investment) => investment.id == investmentId
      );
      reset(selectedInvestment);
    }
  }, [fund]);

  if (useUpdateFundMutation.isSuccess) {
    navigate(`/general-partners/${generalPartnerId}/funds/${fundId}/investments`);
  }

  if (
    fund === undefined ||
    generalPartner === undefined ||
    isGeneralPartnerLoading ||
    isFundLoading
  ) {
    return <CircularProgress />;
  }

  return (
    <MainContent
      links={[
        <BreadcrumbLink key={1} to='..'>
          All companies
        </BreadcrumbLink>,
        <BreadcrumbLink key={2} to={`../${generalPartnerId}/funds`}>
          {generalPartner.name}
        </BreadcrumbLink>,
        <BreadcrumbLink key={3} to={`../${generalPartnerId}/funds/${fundId}/info`}>
          {`${fund.name} dashboard`}
        </BreadcrumbLink>,
        <BreadcrumbLink key={4} to={`../${generalPartnerId}/funds/${fundId}/investments`}>
          Investments
        </BreadcrumbLink>,
        <BreadcrumbLink key={5} to='#'>
          {investmentId ? 'Edit' : 'Add'}
        </BreadcrumbLink>,
      ]}
      headingText={investmentId ? 'Edit Investment' : 'Add Investment'}
    >
      <FormProvider {...formHook}>
        <Box
          display='flex'
          flexDirection='column'
          width='780px'
          marginTop='20px'
          rowGap='20px'
          marginLeft='40px'
        >
          <TwoColumnRow>
            <Controller
              control={control}
              name='name'
              render={({ field }) => {
                const { ref, ...rest } = field;
                return (
                  <TextField
                    {...rest}
                    sx={{ flex: '1 1 0px' }}
                    inputRef={ref}
                    label='Name'
                    required={true}
                    error={formState.errors.name != undefined}
                    helperText={formState.errors.name?.message}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name='fairValue'
              render={({ field }) => {
                const { ref, ...rest } = field;
                return (
                  <CurrencyTextField
                    {...rest}
                    sx={{ flex: '1 1 0px' }}
                    label='Fair Value'
                    inputRef={ref}
                    error={formState.errors.fairValue != undefined}
                    helperText={formState.errors.fairValue?.message}
                  />
                );
              }}
            />
          </TwoColumnRow>
          <TwoColumnRow>
            <Controller
              control={control}
              name='sector'
              render={({ field }) => {
                const { ref, ...rest } = field;
                return (
                  <TextField
                    {...rest}
                    sx={{ flex: '1 1 0px' }}
                    inputRef={ref}
                    label='Sector'
                    required={true}
                    error={formState.errors.sector != undefined}
                    helperText={formState.errors.sector?.message}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name='instrument'
              render={({ field }) => {
                const { ref, value, ...rest } = field;
                const options = [
                  { value: InstrumentType.Equity, label: 'Equity' },
                  {
                    value: InstrumentType.Convertible,
                    label: 'Convertible',
                  },
                  { value: InstrumentType.Option, label: 'Option' },
                  { value: InstrumentType.Warrant, label: 'Warrant' },
                  { value: InstrumentType.Loan, label: 'Loan' },
                ];
                return (
                  <Select
                    options={options}
                    {...rest}
                    value={value ? value : options[0].value}
                    inputRef={ref}
                    label='Instrument'
                    required
                  />
                );
              }}
            />
          </TwoColumnRow>
          <Toolbar
            sx={{
              paddingLeft: '0px',
              paddingRight: '0px',
              flexGrow: 1,
              columnGap: '5px',
            }}
          >
            <Box flexGrow={1}></Box>
            <Button href={`/general-partners/${generalPartnerId}/funds/${fundId}/investments`}>
              Cancel
            </Button>
            <Button
              variant='text'
              onClick={async () => {
                const result = await trigger();
                if (result) {
                  const formParams: ApiInvestment = getValues();
                  const payload = computeUpdatePayload(fund, formParams);
                  if (payload) {
                    useUpdateFundMutation.mutate(payload);
                  }
                }
              }}
            >
              Save
            </Button>
          </Toolbar>
        </Box>
      </FormProvider>
    </MainContent>
  );
};
