import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';
import { Button, ButtonVariant } from './Button';

interface ConfirmationDialogProps {
  title?: string;
  message: string;
  confirmButtonLabel?: string;
  confirmButtonVariant?: ButtonVariant;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (
  params: ConfirmationDialogProps
) => {
  const [processing, setProcessing] = useState(false);

  const onConfirmHandler = async () => {
    setProcessing(true);
    try {
      await params.onConfirm();
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Dialog
      open={params.open}
      onClose={params.onCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {params.title || 'Confirmation'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {params.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={params.onCancel} disabled={processing}>
          Cancel
        </Button>
        <Button
          variant={params.confirmButtonVariant}
          onClick={onConfirmHandler}
          autoFocus
          disabled={processing}
        >
          {processing && (
            <CircularProgress
              sx={{
                width: '15px !important',
                height: '15px !important',
                marginRight: '10px',
              }}
            />
          )}
          {params.confirmButtonLabel || 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
