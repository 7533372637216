/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiCreateInvitation,
    ApiCreateInvitationFromJSON,
    ApiCreateInvitationToJSON,
    ApiPersonInvitation,
    ApiPersonInvitationFromJSON,
    ApiPersonInvitationToJSON,
} from '../models';

export interface CreateInvitationRequest {
    personId: string;
    apiCreateInvitation: ApiCreateInvitation;
}

export interface GetInvitationRequest {
    personId: string;
}

/**
 * 
 */
export class OwnerInvitationApi extends runtime.BaseAPI {

    /**
     * returns invitation info
     */
    async createInvitationRaw(requestParameters: CreateInvitationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiPersonInvitation>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling createInvitation.');
        }

        if (requestParameters.apiCreateInvitation === null || requestParameters.apiCreateInvitation === undefined) {
            throw new runtime.RequiredError('apiCreateInvitation','Required parameter requestParameters.apiCreateInvitation was null or undefined when calling createInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/invitation/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiCreateInvitationToJSON(requestParameters.apiCreateInvitation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPersonInvitationFromJSON(jsonValue));
    }

    /**
     * returns invitation info
     */
    async createInvitation(requestParameters: CreateInvitationRequest, initOverrides?: RequestInit): Promise<ApiPersonInvitation> {
        const response = await this.createInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns invitation info
     */
    async getInvitationRaw(requestParameters: GetInvitationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiPersonInvitation>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/invitation/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPersonInvitationFromJSON(jsonValue));
    }

    /**
     * returns invitation info
     */
    async getInvitation(requestParameters: GetInvitationRequest, initOverrides?: RequestInit): Promise<ApiPersonInvitation> {
        const response = await this.getInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
