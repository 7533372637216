import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import React from 'react';

export const TwoColumnRow: React.FC = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: '40px',
        flexGrow: 1,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export const OneColumnRow: React.FC = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: '40px',
        flexGrow: 1,
      }}
      {...props}
    >
      {children}
      <Box sx={{ flex: '1 1 0px' }} />
    </Box>
  );
};

export const OneFullColumnRow: React.FC = ({
  children,
  ...props
}: BoxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: '40px',
        flexGrow: 1,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
