import {
  Button as MaterialButton,
  ButtonProps,
  IconButton,
} from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import { KeyboardBackspace } from '@mui/icons-material';

export type ButtonVariant =
  | 'PrimaryAction'
  | 'SecondaryAction'
  | 'TertiaryAction'
  | 'TertiaryGhost'
  | 'Enabling'
  | 'Disabling'
  | 'AddInForm'
  | 'IconEdit'
  | 'contained'
  | 'text'
  | 'outlined'
  | 'back';

type Props = Omit<ButtonProps, 'variant'> & {
  variant?: ButtonVariant;
};

export const Button: React.FC<Props> = ({
  variant,
  children,
  ...rest
}: Props) => {
  switch (variant) {
    case 'PrimaryAction':
      return (
        <MaterialButton variant='contained' size='large' {...rest}>
          {children}
        </MaterialButton>
      );
    case 'SecondaryAction':
      return (
        <MaterialButton
          variant='contained'
          size='small'
          color='secondary'
          startIcon={<AddIcon />}
          {...rest}
        >
          {children}
        </MaterialButton>
      );
    case 'TertiaryAction':
      return (
        <MaterialButton variant='contained' size='small' {...rest}>
          {children}
        </MaterialButton>
      );
    case 'TertiaryGhost':
      return (
        <MaterialButton variant='outlined' size='small' {...rest}>
          {children}
        </MaterialButton>
      );
    case 'Enabling':
      return <MaterialButton {...rest}>{children}</MaterialButton>;
    case 'Disabling':
      return (
        <MaterialButton color='secondary' {...rest}>
          {children}
        </MaterialButton>
      );
    case 'IconEdit':
      return (
        <IconButton>
          <CreateIcon />
        </IconButton>
      );
    case 'AddInForm':
      return (
        <MaterialButton
          sx={{
            textTransform: 'none',
          }}
          size='small'
          startIcon={<AddIcon />}
          {...rest}
        >
          {children}
        </MaterialButton>
      );
    case 'back':
      return (
        <MaterialButton
          startIcon={<KeyboardBackspace />}
          sx={{
            textTransform: 'none',
          }}
          size='small'
          color='primary'
          {...rest}
        >
          {children}
        </MaterialButton>
      );
    default:
      return (
        <MaterialButton variant={variant} {...rest}>
          {children}
        </MaterialButton>
      );
  }
};
