import React from 'react';
import { Box, Typography } from '@mui/material';
import { ApiInvestorProfile } from '../api';
import { Select } from './inputs/Select';
import { useMutation, useQueryClient } from 'react-query';
import { representativesApi } from '../apis';

export interface Props {
  profile: ApiInvestorProfile | undefined;
}

export const CompanyChooser: React.FC<Props> = ({ profile }: Props) => {
  const queryClient = useQueryClient();

  const selectLP = useMutation(
    'select-lp',
    async (entityId: string) => {
      representativesApi.selectLP({ apiUpdateSelectedEntity: { entityId: entityId } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  if (profile?.companies == undefined || profile.companies.length == 0) {
    return <></>;
  }
  if (profile.companies.length == 1) {
    const company = profile.companies[0];
    return (
      <div>
        <Typography
          style={{
            color: 'white',
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
        >
          {`${company.company?.name} (${company.company?.registryCode})`}
        </Typography>
      </div>
    );
  }
  const options = profile.companies.map((c) => {
    if (c.company == undefined) {
      return {
        value: '',
        label: '',
      };
    }
    return {
      value: c.company?.entityId,
      label: c.company?.name,
    };
  });
  if (profile.entityId != undefined) {
    options.push({
      value: profile.entityId,
      label: `${profile.firstName} ${profile.lastName} (self)`,
    });
  }
  return (
    <Select
      flavor='menubar'
      options={options}
      value={profile.selectedEntityId}
      onChange={(e, value) => {
        const selectedLP = e.target.value as string;
        selectLP.mutate(selectedLP);
      }}
    />
  );
};

export const ProfileBadge: React.FC<Props> = ({ profile }: Props) => {
  return (
    <Box display='flex' flexDirection='column'>
      <Typography
        style={{
          color: 'white',
          fontFamily: 'Roboto',
          fontWeight: 'bold',
          fontSize: '16px',
          marginTop: '7vh',
        }}
      >
        {profile && profile?.firstName + ' ' + profile?.lastName}
      </Typography>
      <CompanyChooser profile={profile} />
    </Box>
  );
};
