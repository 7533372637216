/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailType,
    EmailTypeFromJSON,
    EmailTypeFromJSONTyped,
    EmailTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiSendEmailRequest
 */
export interface ApiSendEmailRequest {
    /**
     * 
     * @type {EmailType}
     * @memberof ApiSendEmailRequest
     */
    type: EmailType;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiSendEmailRequest
     */
    personIds?: Array<string>;
}

export function ApiSendEmailRequestFromJSON(json: any): ApiSendEmailRequest {
    return ApiSendEmailRequestFromJSONTyped(json, false);
}

export function ApiSendEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiSendEmailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': EmailTypeFromJSON(json['type']),
        'personIds': !exists(json, 'personIds') ? undefined : json['personIds'],
    };
}

export function ApiSendEmailRequestToJSON(value?: ApiSendEmailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': EmailTypeToJSON(value.type),
        'personIds': value.personIds,
    };
}

