import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Toolbar } from '@mui/material';
import { MainContent } from '../../common/MainContent';
import { BreadcrumbLink } from '../../common/Breadcrumbs';
import { TabPanel, Tabs } from '../../common/Tabs';
import { DisplayField } from '../../common/DisplayField';
import { format } from '../../util/currency';
import { column, customColumn, DataGrid } from '../../common/DataGrid';
import { GridColDef, GridColumns } from '@mui/x-data-grid';
import { Button } from '../../common/Button';
import { useFund } from '../../hooks/useFund';
import { useGeneralPartner } from '../../hooks/useGeneralPartner';
import { PersonRegistryDelete } from './PersonRegistryDelete';
import { InvestmentDelete } from './InvestmentDelete';
import { FundDocuments } from './FundDocuments';
import { FundDocumentUpload } from './FundDocumentUpload';

type FundDashboardPageParams = {
  generalPartnerId: string;
  fundId: string;
  selectedTab: string;
};

const registryColumns: GridColumns = [
  column({
    headerName: 'Total commitment',
    field: 'totalCommitment',
    width: 150,
    valueFormatter: (params) => params.value && format(params.value as string),
  }),
  column({
    headerName: 'Paid in capital',
    field: 'paidInCapital',
    width: 150,
    valueFormatter: (params) => params.value && format(params.value as number),
  }),
  column({
    headerName: 'Callable capital',
    field: 'callableCapital',
    width: 150,
    valueFormatter: (params) => params.value && format(params.value as number),
  }),
  column({
    headerName: 'Agreement signing date',
    field: 'agreementSigningDate',
    width: 150,
    valueFormatter: (params) => params.value && new Date(params.value as string).toDateString(),
  }),
  column({
    headerName: 'First closing date',
    field: 'firstClosingDate',
    width: 150,
    valueFormatter: (params) => params.value && new Date(params.value as string).toDateString(),
  }),
];

const investmentsTabColumns: GridColDef[] = [
  column({
    field: 'name',
    headerName: 'Name',
    width: 150,
  }),
  column({
    field: 'fairValue',
    headerName: 'Fair value',
    valueFormatter: (params) => params.value && format(params.value as string),
    width: 120,
  }),
  column({
    field: 'sector',
    headerName: 'Sector',
    width: 120,
  }),
  column({
    field: 'instrument',
    headerName: 'Instrument',
    width: 150,
  }),
];

const urlTabMap = new Map<string, string>([
  ['info', '0'],
  ['investments', '1'],
  ['registry', '2'],
  ['documents', '3'],
  ['0', 'info'],
  ['1', 'investments'],
  ['2', 'registry'],
  ['3', 'documents'],
]);

interface ButtonBarProps {
  tab: number;
  generalPartnerId: string;
  fundId: string;
}

const ButtonBar: React.FC<ButtonBarProps> = ({ tab, generalPartnerId, fundId }) => {
  if (tab === 1) {
    return (
      <Button
        variant='SecondaryAction'
        href={`/general-partners/${generalPartnerId}/funds/${fundId}/investment/add`}
      >
        Add Investment
      </Button>
    );
  } else if (tab === 2) {
    return (
      <Button
        variant='SecondaryAction'
        href={`/general-partners/${generalPartnerId}/funds/${fundId}/registry/add`}
      >
        Add Registry
      </Button>
    );
  } else if (tab == 3) {
    // @ts-ignore
    return <FundDocumentUpload fundId={fundId} />;
  }
  return null;
};

export const FundDashboard = () => {
  const { generalPartnerId, fundId, selectedTab } = useParams<FundDashboardPageParams>();
  const navigate = useNavigate();

  const currentTabName = selectedTab === undefined ? 'info' : selectedTab;
  const currentTabIndex = Number(urlTabMap.get(currentTabName) || '0');

  const [tab, setTab] = useState<number>(currentTabIndex);

  if (fundId === undefined || generalPartnerId === undefined) {
    return <CircularProgress />;
  }

  const { generalPartner, isGeneralPartnerLoading } = useGeneralPartner(generalPartnerId);
  const { fund, isFundLoading } = useFund(generalPartnerId, fundId);

  if (
    fund === undefined ||
    generalPartner === undefined ||
    isGeneralPartnerLoading ||
    isFundLoading ||
    fund.registry == undefined
  ) {
    return <CircularProgress />;
  }

  const registry = fund.registry;
  // .map((r) => {
  //   return { id: r.entityId, ...r };
  // });
  // console.log(registry);

  return (
    <MainContent
      headingText={`${fund.name} dashboard`}
      headerButtons={<ButtonBar tab={tab} generalPartnerId={generalPartnerId} fundId={fundId} />}
      links={[
        <BreadcrumbLink key={1} to='..'>
          All companies
        </BreadcrumbLink>,
        <BreadcrumbLink key={2} to={`../${generalPartnerId}/funds`}>
          {generalPartner.name}
        </BreadcrumbLink>,
        <BreadcrumbLink key={3} to='#'>
          {`${fund.name} dashboard`}
        </BreadcrumbLink>,
      ]}
    >
      <Tabs
        value={tab}
        onChange={(_, index) => {
          navigate(
            `../${generalPartnerId}/funds/${fundId}/${urlTabMap.get(index.toString()) || 'info'}`
          );
          setTab(index);
        }}
        tabs={['Fund information', 'Investments', 'Registry', 'Documents']}
        panels={(value) => (
          <>
            <TabPanel value={value} index={0}>
              <Box display='flex' flexDirection='column' style={{ gap: '3vh' }}>
                <DisplayField label='Name'>{fund.name}</DisplayField>
                <DisplayField label='Fund size'>
                  {fund.fundSize && format(fund.fundSize)}
                </DisplayField>
                <DisplayField label='Symbol'>{fund.symbol}</DisplayField>
                <DisplayField label='Service type'>{fund.serviceType}</DisplayField>
              </Box>
              <Toolbar
                sx={{
                  paddingLeft: '0px',
                  paddingRight: '0px',
                  flexGrow: 1,
                  columnGap: '5px',
                }}
              >
                <Box flexGrow={1}></Box>
                <Button
                  variant='text'
                  onClick={() => {
                    navigate(`/general-partners/${generalPartnerId}/funds/${fundId}/edit`);
                  }}
                >
                  Edit
                </Button>
              </Toolbar>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DataGrid
                columns={investmentsTabColumns.concat([
                  customColumn({
                    minWidth: 240,
                    renderCell: (params) => {
                      return (
                        <Box>
                          <Button
                            sx={{ marginRight: '10px' }}
                            variant='TertiaryAction'
                            onClick={() => {
                              navigate(
                                `/general-partners/${generalPartnerId}/funds/${fundId}/investment/${params.row.id}/edit`
                              );
                            }}
                          >
                            Edit
                          </Button>
                          <InvestmentDelete
                            generalPartnerId={generalPartnerId}
                            fundId={fundId}
                            investmentId={params.row.id}
                          />
                        </Box>
                      );
                    },
                  }),
                ])}
                rows={fund.investments || []}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DataGrid
                columns={[
                  column({
                    headerName: 'Name',
                    field: 'name',
                    width: 250,
                    // valueFormatter: (params) => (params.value as ApiInvestorProfile).email,
                  }),
                  column({
                    headerName: 'Type',
                    field: 'type',
                    width: 100,
                    // valueFormatter: (params) => (params.value as ApiInvestorProfile).email,
                  }),
                ]
                  .concat(registryColumns)
                  .concat([
                    customColumn({
                      minWidth: 240,
                      renderCell: (params) => {
                        return (
                          <Box>
                            <Button
                              sx={{ marginRight: '10px' }}
                              variant='TertiaryAction'
                              onClick={() => {
                                navigate(
                                  `/general-partners/${generalPartnerId}/funds/${fundId}/registry/${params.row.entityId}/edit`
                                );
                              }}
                            >
                              Edit
                            </Button>
                            <PersonRegistryDelete
                              generalPartnerId={generalPartnerId}
                              fundId={fundId}
                              registryId={params.row.entityId}
                            />
                          </Box>
                        );
                      },
                    }),
                  ])}
                rows={registry || []}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <FundDocuments fundId={fundId} />
            </TabPanel>
          </>
        )}
      />
    </MainContent>
  );
};
