import React, { CSSProperties } from 'react';
import { Typography } from '@mui/material';

export interface Props {
  style?: CSSProperties;
  text: string | undefined;
}

export const Heading: React.FC<Props> = ({ style, text }: Props) => (
  <Typography
    style={{
      ...style,
      fontFamily: 'Roboto',
      fontSize: '32px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: 'var(--black)',
    }}
  >
    {text}
  </Typography>
);
