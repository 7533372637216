/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiShuftiResponseVerificationDataDocument
 */
export interface ApiShuftiResponseVerificationDataDocument {
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiResponseVerificationDataDocument
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiResponseVerificationDataDocument
     */
    expiryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiResponseVerificationDataDocument
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiResponseVerificationDataDocument
     */
    documentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiResponseVerificationDataDocument
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiResponseVerificationDataDocument
     */
    placeOfIssue?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiResponseVerificationDataDocument
     */
    country?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiShuftiResponseVerificationDataDocument
     */
    selectedType?: Array<string>;
}

export function ApiShuftiResponseVerificationDataDocumentFromJSON(json: any): ApiShuftiResponseVerificationDataDocument {
    return ApiShuftiResponseVerificationDataDocumentFromJSONTyped(json, false);
}

export function ApiShuftiResponseVerificationDataDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiShuftiResponseVerificationDataDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'expiryDate': !exists(json, 'expiry_date') ? undefined : json['expiry_date'],
        'issueDate': !exists(json, 'issue_date') ? undefined : json['issue_date'],
        'documentNumber': !exists(json, 'document_number') ? undefined : json['document_number'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'placeOfIssue': !exists(json, 'place_of_issue') ? undefined : json['place_of_issue'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'selectedType': !exists(json, 'selected_type') ? undefined : json['selected_type'],
    };
}

export function ApiShuftiResponseVerificationDataDocumentToJSON(value?: ApiShuftiResponseVerificationDataDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dob': value.dob,
        'expiry_date': value.expiryDate,
        'issue_date': value.issueDate,
        'document_number': value.documentNumber,
        'gender': value.gender,
        'place_of_issue': value.placeOfIssue,
        'country': value.country,
        'selected_type': value.selectedType,
    };
}

