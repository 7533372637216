/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiDocument,
    ApiDocumentFromJSON,
    ApiDocumentFromJSONTyped,
    ApiDocumentToJSON,
    ApiDocumentShareEntity,
    ApiDocumentShareEntityFromJSON,
    ApiDocumentShareEntityFromJSONTyped,
    ApiDocumentShareEntityToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiFundDocument
 */
export interface ApiFundDocument {
    /**
     * 
     * @type {ApiDocument}
     * @memberof ApiFundDocument
     */
    document: ApiDocument;
    /**
     * 
     * @type {ApiDocumentShareEntity}
     * @memberof ApiFundDocument
     */
    shareEntity?: ApiDocumentShareEntity;
}

export function ApiFundDocumentFromJSON(json: any): ApiFundDocument {
    return ApiFundDocumentFromJSONTyped(json, false);
}

export function ApiFundDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiFundDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document': ApiDocumentFromJSON(json['document']),
        'shareEntity': !exists(json, 'shareEntity') ? undefined : ApiDocumentShareEntityFromJSON(json['shareEntity']),
    };
}

export function ApiFundDocumentToJSON(value?: ApiFundDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document': ApiDocumentToJSON(value.document),
        'shareEntity': ApiDocumentShareEntityToJSON(value.shareEntity),
    };
}

