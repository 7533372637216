import {
  assign,
  createMachine,
  EventObject,
  PropertyAssigner,
  AnyEventObject,
  MachineConfig,
} from 'xstate';
import {
  AmlFormStatus,
  ApiAdminCompany,
  ApiAmlForm,
  ApiInvestorProfile,
  LimitedPartnerType,
} from '../../api';
import { limitedPartnerPlatformApi, ownerAmlApi, queryClient } from '../../apis';

export interface AmlStateContext {
  backLabel?: string;
  nextLabel: string;
  saveLabel?: string;
  isReadonly: boolean;
  isLoading: boolean;
  tabNames: Array<string>;
  tab: number;
  personAml?: ApiAmlForm;
  personAmlData?: any;
  companyAml?: ApiAmlForm;
  companyAmlData?: any;
  targetProfile?: ApiInvestorProfile;
  selectedCompany?: ApiAdminCompany;
}

const findSelectedCompany = (
  profileInfo: ApiInvestorProfile | undefined
): ApiAdminCompany | undefined => {
  if (
    profileInfo != undefined &&
    profileInfo.selectedEntityId != undefined &&
    profileInfo.selectedEntityId != profileInfo.entityId
  ) {
    const company = profileInfo.companies?.find(
      (c) => c.company?.entityId == profileInfo.selectedEntityId
    );
    return company;
  }
  return undefined;
};

const buildPersonAml = (personalAmlForm: ApiAmlForm, targetProfile: ApiInvestorProfile) => {
  if (personalAmlForm && personalAmlForm.formData) {
    return Object.assign({}, personalAmlForm, { createdBy: targetProfile.id });
  }
  const formData = {
    person: {
      name: targetProfile.firstName + ' ' + targetProfile.lastName?.toUpperCase(),
      email: targetProfile.email,
      residency: targetProfile.residency,
    },
  };
  return {
    amlFormStatus: AmlFormStatus.InProgress,
    createdBy: targetProfile.id,
    createdForPerson: targetProfile.id,
    formData: JSON.stringify(formData),
  };
};

const buildCompanyAml = (
  companyAmlForm: ApiAmlForm,
  targetProfile: ApiInvestorProfile,
  company: ApiAdminCompany
): ApiAmlForm => {
  if (companyAmlForm && companyAmlForm.formData) {
    return Object.assign({}, companyAmlForm, { createdBy: targetProfile.id });
  }
  const defaultCompany = {
    name: company.company?.name,
    registrationCode: company.company?.registryCode,
  };

  const formData = {
    company: defaultCompany,
  };
  return {
    amlFormStatus: AmlFormStatus.InProgress,
    createdBy: targetProfile.id,
    createdForCompany: company.company?.id,
    formData: JSON.stringify(formData),
  };
};
export interface AmlEventObject extends EventObject {
  personAml?: ApiAmlForm;
  personAmlData?: any;
  companyAml?: ApiAmlForm;
  companyAmlData?: any;
}

const saveAml =
  (type: 'person' | 'company', targetState?: AmlFormStatus) => (context: AmlStateContext) => {
    const amlProp = type == 'person' ? 'personAml' : 'companyAml';
    const amlDataProp = type == 'person' ? 'personAmlData' : 'companyAmlData';

    if (context[amlProp] && context[amlDataProp]) {
      if (targetState != undefined && context[amlProp]?.amlFormStatus == targetState) {
        return Promise.resolve({});
      }
      const payload = { ...context[amlProp] };
      if (targetState != undefined) {
        payload.amlFormStatus = targetState;
      }
      payload.formData = JSON.stringify(context[amlDataProp]);
      return ownerAmlApi.setOwnerAmlForm({
        apiAmlForm: payload,
      });
    } else {
      return Promise.resolve({});
    }
  };

const saveSubMachine = {
  initial: 'idle',
  states: {
    idle: {
      entry: assign({ isReadonly: false } as PropertyAssigner<AmlStateContext, AmlEventObject>),
      on: {
        save: { target: 'saving' },
      },
    },
    saving: {
      type: 'parallel',
      entry: assign({ isReadonly: false } as PropertyAssigner<AmlStateContext, AmlEventObject>),
      onDone: {
        target: 'idle',
      },
      states: {
        savePerson: {
          type: 'compound',
          initial: 'start',
          states: {
            start: {
              invoke: [
                {
                  id: 'savePersonAml',
                  src: saveAml('person'),
                  onDone: {
                    target: 'finish',
                  },
                },
              ],
            },
            finish: {
              type: 'final',
            },
          },
        },
        saveCompany: {
          type: 'compound',
          initial: 'start',
          states: {
            start: {
              invoke: [
                {
                  id: 'saveCompanyAml',
                  src: saveAml('company'),
                  onDone: {
                    target: 'finish',
                  },
                },
              ],
            },
            finish: {
              type: 'final',
            },
          },
        },
      },
    },
  },
} as MachineConfig<AmlStateContext, any, AmlEventObject>;

export const amlStateMachine = createMachine<AmlStateContext, AmlEventObject>(
  {
    id: 'aml-form',
    initial: 'loadProfile',

    context: {
      backLabel: undefined,
      nextLabel: '',
      tab: 0,
      isLoading: true,
      tabNames: [],
      isReadonly: false,
    } as AmlStateContext,

    on: {
      amlFormUpdate: {
        actions: 'amlAssign',
      },
      reset: {
        actions: assign({
          isLoading: true,
        } as PropertyAssigner<AmlStateContext, AmlEventObject>),
        target: '.loadProfile',
      },
    },

    states: {
      loadProfile: {
        type: 'compound',
        initial: 'init',
        onDone: {
          target: 'loadPersonAml',
        },
        states: {
          init: {
            invoke: [
              {
                id: 'loadProfile',
                src: (_context, _event) => {
                  const ret = queryClient.fetchQuery(['investorProfile'], () => {
                    return limitedPartnerPlatformApi.getInvestorProfileById({
                      limitedPartnerId: 'self',
                    });
                  });
                  return ret;
                },
                onDone: {
                  target: 'success',
                  actions: assign({
                    targetProfile: (_context, event) => event.data,
                    selectedCompany: (_context, event) => findSelectedCompany(event.data),
                  }),
                },
              },
            ],
          },
          success: {
            type: 'final',
          },
        },
      },
      loadPersonAml: {
        type: 'compound',
        initial: 'init',
        onDone: {
          target: 'loadCompanyAml',
        },
        states: {
          init: {
            invoke: [
              {
                id: 'loadPersonAml',
                src: (context, _event) => {
                  return queryClient.fetchQuery(['person-aml', context.targetProfile?.id], () => {
                    const targetProfile = context.targetProfile!;
                    return ownerAmlApi.getOwnerAmlForm({
                      limitedPartnerId: targetProfile.id,
                      limitedPartnerType: LimitedPartnerType.NaturalPerson,
                    });
                  });
                },
                onDone: {
                  target: 'success',
                  actions: assign((context, event) => {
                    const personalAmlForm = event.data;
                    const targetProfile = context.targetProfile!;
                    const personAml = buildPersonAml(personalAmlForm, targetProfile);
                    return {
                      personAml: personAml,
                      personAmlData: JSON.parse(personAml.formData!),
                      tabNames: ['Natural Person'],
                    };
                  }),
                },
              },
            ],
          },
          success: {
            type: 'final',
          },
        },
      },
      loadCompanyAml: {
        type: 'compound',
        initial: 'init',
        onDone: {
          target: 'switchstate',
        },
        states: {
          init: {
            always: [
              {
                target: 'fetch',
                cond: (context, _event) =>
                  context.selectedCompany != undefined &&
                  context.selectedCompany.company != undefined,
              },
              {
                target: 'success',
              },
            ],
          },
          fetch: {
            invoke: [
              {
                id: 'loadCompanyAml',
                src: (context, _event) => {
                  return queryClient.fetchQuery(
                    ['company-aml', context.selectedCompany?.company?.id],
                    () => {
                      const company = context.selectedCompany!.company!;
                      return ownerAmlApi.getOwnerAmlForm({
                        limitedPartnerId: company.id,
                        limitedPartnerType: LimitedPartnerType.Company,
                      });
                    }
                  );
                },
                onDone: {
                  target: 'success',
                  actions: assign((context, event) => {
                    const companyAmlForm = event.data;
                    const targetProfile = context.targetProfile!;
                    const company = context.selectedCompany!;

                    const companyAml = buildCompanyAml(companyAmlForm, targetProfile, company);
                    return {
                      companyAml: companyAml,
                      companyAmlData: JSON.parse(companyAml.formData!),
                      tabNames: context.tabNames.concat('Entity'),
                    };
                  }),
                },
              },
            ],
          },
          success: {
            type: 'final',
          },
        },
      },
      switchstate: {
        always: [
          {
            target: 'INPROGRESS_INPROGRESS',
            cond: (context, _event) =>
              context.personAml?.amlFormStatus == AmlFormStatus.InProgress &&
              context.companyAml?.amlFormStatus == AmlFormStatus.InProgress,
          },
          {
            target: 'REVIEW_INPROGRESS',
            cond: (context, _event) =>
              context.personAml?.amlFormStatus == AmlFormStatus.Review &&
              context.companyAml?.amlFormStatus == AmlFormStatus.InProgress,
          },
          {
            target: 'SUBMITTED_INPROGRESS',
            cond: (context, _event) => {
              return (
                (context.personAml?.amlFormStatus == AmlFormStatus.Submitted ||
                  context.personAml?.amlFormStatus == AmlFormStatus.Approved) &&
                context.companyAml?.amlFormStatus == AmlFormStatus.InProgress
              );
            },
          },
          {
            target: 'INPROGRESS_SUBMITTED',
            cond: (context, _event) =>
              context.personAml?.amlFormStatus == AmlFormStatus.InProgress &&
              (context.companyAml?.amlFormStatus == AmlFormStatus.Submitted ||
                context.companyAml?.amlFormStatus == AmlFormStatus.Approved),
          },
          {
            target: 'INPROGRESS',
            cond: (context, _event) =>
              context.personAml?.amlFormStatus == AmlFormStatus.InProgress &&
              context.companyAml == undefined,
          },
          {
            target: 'VIEW_ONLY',
            cond: (context, _event) => {
              return (
                (context.personAml?.amlFormStatus == AmlFormStatus.Submitted ||
                  context.personAml?.amlFormStatus == AmlFormStatus.Approved) &&
                (context.companyAml?.amlFormStatus == AmlFormStatus.Submitted ||
                  context.companyAml?.amlFormStatus == AmlFormStatus.Approved ||
                  context.companyAml == undefined)
              );
            },
          },
          {
            target: 'INVALID',
          },
        ],
        exit: assign({ isLoading: false } as PropertyAssigner<AmlStateContext, AmlEventObject>),
      },
      INPROGRESS: {
        entry: assign({
          nextLabel: 'Submit AML Form',
          backLabel: undefined,
          saveLabel: 'Save for Later',
          tab: 0,
        } as PropertyAssigner<AmlStateContext, AmlEventObject>),
        on: {
          next: {
            target: '#sending',
          },
        },
        ...saveSubMachine,
      },

      INPROGRESS_INPROGRESS: {
        entry: assign({
          nextLabel: 'Company AML Form',
          backLabel: undefined,
          saveLabel: 'Save for Later',
          tab: 0,
        } as PropertyAssigner<AmlStateContext, AmlEventObject>),
        on: {
          next: {
            target: 'REVIEW_INPROGRESS',
          },
        },
        ...saveSubMachine,
      },

      REVIEW_INPROGRESS: {
        entry: assign((context) => {
          const ret: Partial<AmlStateContext> = {
            nextLabel: 'Submit AML Forms',
            backLabel: 'Natural Person AML Form',
            saveLabel: 'Save for Later',
            tab: 1,
            personAml: context.personAml,
          };
          if (ret.personAml) ret.personAml.amlFormStatus = AmlFormStatus.Review;
          console.log('REVIEW_INPROGRESS', ret);
          return ret;
        }),
        on: {
          next: {
            target: '#sending',
          },
          prev: {
            target: 'INPROGRESS_INPROGRESS',
          },
        },
        ...saveSubMachine,
      },

      SUBMITTED_INPROGRESS: {
        initial: 'EDIT_C',
        states: {
          VIEW_NP: {
            entry: assign({
              nextLabel: 'Entity AML Form',
              backLabel: undefined,
              saveLabel: undefined,
              isReadonly: true,
              tab: 0,
            } as PropertyAssigner<AmlStateContext, AmlEventObject>),
            on: {
              next: {
                target: 'EDIT_C',
              },
            },
            meta: {
              message: 'Form Already Submitted',
            },
          },
          save: {
            invoke: [
              {
                id: 'saveCompanyAml',
                src: saveAml('company'),
                onDone: {
                  target: 'EDIT_C',
                },
              },
            ],
          },
          EDIT_C: {
            entry: assign({
              nextLabel: 'Submit Entity AML Form',
              backLabel: 'Natural Person AML Form',
              saveLabel: 'Save for Later',
              isReadonly: false,
              tab: 1,
            } as PropertyAssigner<AmlStateContext, AmlEventObject>),
            on: {
              next: {
                target: '#sending',
              },
              save: {
                target: 'save',
              },
              prev: {
                target: 'VIEW_NP',
              },
            },
          },
        },
      },

      INPROGRESS_SUBMITTED: {
        initial: 'EDIT_P',
        states: {
          VIEW_C: {
            entry: assign({
              nextLabel: 'Person AML Form',
              backLabel: undefined,
              saveLabel: undefined,
              isReadonly: true,
              tab: 1,
            } as PropertyAssigner<AmlStateContext, AmlEventObject>),
            on: {
              next: {
                target: 'EDIT_P',
              },
            },
            meta: {
              message: 'Form Already Submitted',
            },
          },
          save: {
            invoke: [
              {
                id: 'savePersonAml',
                src: saveAml('person'),
                onDone: {
                  target: 'EDIT_P',
                },
              },
            ],
          },
          EDIT_P: {
            entry: assign({
              nextLabel: 'Submit Person AML Form',
              backLabel: 'Entity AML Form',
              saveLabel: 'Save for Later',
              isReadonly: false,
              tab: 0,
            } as PropertyAssigner<AmlStateContext, AmlEventObject>),
            on: {
              next: {
                target: '#sending',
              },
              save: {
                target: 'save',
              },
              prev: {
                target: 'VIEW_C',
              },
            },
          },
        },
      },

      SENDING: {
        id: 'sending',
        type: 'parallel',
        onDone: {
          target: 'VIEW_ONLY',
        },
        states: {
          personAml: {
            type: 'compound',
            initial: 'init',
            states: {
              init: {
                always: [
                  {
                    target: 'success',
                    cond: (context, _event) =>
                      context.personAml?.amlFormStatus == AmlFormStatus.Submitted ||
                      context.personAml?.amlFormStatus == AmlFormStatus.Approved,
                  },
                  {
                    target: 'submit',
                  },
                ],
              },
              submit: {
                invoke: [
                  {
                    id: 'submitPersonAml',
                    src: saveAml('person', AmlFormStatus.Submitted),
                    onDone: {
                      target: 'success',
                      actions: 'amlInvokeAssign',
                    },
                  },
                ],
              },
              success: {
                type: 'final',
              },
            },
          },
          companyAml: {
            type: 'compound',
            initial: 'init',
            states: {
              init: {
                always: [
                  {
                    target: 'success',
                    cond: (context, _event) =>
                      context.companyAml == undefined ||
                      context.companyAml?.amlFormStatus == AmlFormStatus.Submitted ||
                      context.companyAml?.amlFormStatus == AmlFormStatus.Approved,
                  },
                  {
                    target: 'submit',
                  },
                ],
              },
              submit: {
                invoke: [
                  {
                    id: 'submitCompanyAml',
                    src: saveAml('company', AmlFormStatus.Submitted),
                    onDone: {
                      target: 'success',
                      actions: 'amlInvokeAssign',
                    },
                  },
                ],
              },
              success: {
                type: 'final',
              },
            },
          },
        },
      },

      VIEW_ONLY: {
        entry: assign((context) => {
          const tab = context.tab != undefined ? context.tab : 0;
          let nextLabel = undefined;
          if (context.companyAml != undefined) {
            nextLabel = tab == 0 ? 'Entity Form' : 'Natural Person Form';
          }
          const ret: Partial<AmlStateContext> = {
            isReadonly: true,
            tab: tab,
            nextLabel: nextLabel,
            saveLabel: undefined,
            backLabel: undefined,
          };
          return ret;
        }),
        on: {
          next: {
            actions: assign((context) => {
              const tab = context.tab == 0 ? 1 : 0;
              const ret: Partial<AmlStateContext> = {
                tab: tab,
                nextLabel: tab == 0 ? 'Entity Form' : 'Natural Person Form',
              };

              return ret;
            }),
          },
        },
        meta: {
          message: 'Form Already Submitted',
        },
      },

      INVALID: {
        entry: () => {
          console.log('INVALID');
        },
      },
    },
  },
  {
    actions: {
      amlAssign: assign({
        companyAmlData: (context, event) =>
          event.companyAmlData != undefined ? event.companyAmlData : context.companyAmlData,

        personAmlData: (context, event) =>
          event.personAmlData != undefined ? event.personAmlData : context.personAmlData,
      }),
      amlInvokeAssign: assign({
        companyAml: (context, event: AnyEventObject) => {
          if (event.type.indexOf('CompanyAml') > 0) {
            if ('data' in event) {
              return event.data;
            }
          }
          return context.companyAml;
        },
        personAml: (context, event: AnyEventObject) => {
          if (event.type.indexOf('PersonAml') > 0) {
            if ('data' in event) {
              return event.data;
            }
          }
          return context.personAml;
        },
      }),
    },
  }
);
