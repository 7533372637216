/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiDocumentType,
    ApiDocumentTypeFromJSON,
    ApiDocumentTypeFromJSONTyped,
    ApiDocumentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiDocument
 */
export interface ApiDocument {
    /**
     * 
     * @type {string}
     * @memberof ApiDocument
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDocument
     */
    filename: string;
    /**
     * upload timestamp in milliseconds
     * @type {number}
     * @memberof ApiDocument
     */
    uploadTimeMs: number;
    /**
     * 
     * @type {ApiDocumentType}
     * @memberof ApiDocument
     */
    type: ApiDocumentType;
}

export function ApiDocumentFromJSON(json: any): ApiDocument {
    return ApiDocumentFromJSONTyped(json, false);
}

export function ApiDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filename': json['filename'],
        'uploadTimeMs': json['uploadTimeMs'],
        'type': ApiDocumentTypeFromJSON(json['type']),
    };
}

export function ApiDocumentToJSON(value?: ApiDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'filename': value.filename,
        'uploadTimeMs': value.uploadTimeMs,
        'type': ApiDocumentTypeToJSON(value.type),
    };
}

