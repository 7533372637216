/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AmlFormStatus,
    AmlFormStatusFromJSON,
    AmlFormStatusFromJSONTyped,
    AmlFormStatusToJSON,
} from './';

/**
 * Aml Form info
 * @export
 * @interface ApiAmlForm
 */
export interface ApiAmlForm {
    /**
     * 
     * @type {string}
     * @memberof ApiAmlForm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAmlForm
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAmlForm
     */
    createdForCompany?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAmlForm
     */
    createdForPerson?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAmlForm
     */
    formData?: string;
    /**
     * 
     * @type {AmlFormStatus}
     * @memberof ApiAmlForm
     */
    amlFormStatus?: AmlFormStatus;
    /**
     * 
     * @type {Date}
     * @memberof ApiAmlForm
     */
    createdTime?: Date;
}

export function ApiAmlFormFromJSON(json: any): ApiAmlForm {
    return ApiAmlFormFromJSONTyped(json, false);
}

export function ApiAmlFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAmlForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdForCompany': !exists(json, 'createdForCompany') ? undefined : json['createdForCompany'],
        'createdForPerson': !exists(json, 'createdForPerson') ? undefined : json['createdForPerson'],
        'formData': !exists(json, 'formData') ? undefined : json['formData'],
        'amlFormStatus': !exists(json, 'amlFormStatus') ? undefined : AmlFormStatusFromJSON(json['amlFormStatus']),
        'createdTime': !exists(json, 'createdTime') ? undefined : (new Date(json['createdTime'])),
    };
}

export function ApiAmlFormToJSON(value?: ApiAmlForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdBy': value.createdBy,
        'createdForCompany': value.createdForCompany,
        'createdForPerson': value.createdForPerson,
        'formData': value.formData,
        'amlFormStatus': AmlFormStatusToJSON(value.amlFormStatus),
        'createdTime': value.createdTime === undefined ? undefined : (value.createdTime.toISOString()),
    };
}

