import { LimitedPartnerType, RiskStatus } from '../../api';

const representativeUISchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          label: 'First Name',
          scope: '#/properties/adminInfo/properties/firstName',
        },
        {
          type: 'Control',
          label: 'Last Name',
          scope: '#/properties/adminInfo/properties/lastName',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          label: 'Email',
          scope: '#/properties/adminInfo/properties/email',
        },
        {
          type: 'Control',
          label: 'Residency',
          scope: '#/properties/adminInfo/properties/residency',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          label: 'Phone',
          scope: '#/properties/adminInfo/properties/phone',
        },
        {
          type: 'Control',
          label: 'Legal Powers',
          options: {
            options: ['Board member', 'Under a Power of Attorney'],
            freeSolo: true,
          },
          scope: '#/properties/adminInfo/properties/legalPowers',
        },
      ],
    },
  ],
};

export const lpFormUISchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          options: {
            enumType: LimitedPartnerType,
            autocomplete: false,
          },
          label: 'Name',
          scope: '#/properties/lpTypeSelect',
          rule: {
            effect: 'DISABLE',
            condition: {
              type: 'OR',
              conditions: [
                {
                  type: 'AND',
                  conditions: [
                    {
                      scope: '#/properties',
                      schema: {
                        required: ['person'],
                      },
                    },
                    {
                      scope: '#/properties/person/properties',
                      schema: {
                        required: ['id'],
                      },
                    },
                  ],
                },
                {
                  type: 'AND',
                  conditions: [
                    {
                      scope: '#/properties',
                      schema: {
                        required: ['company'],
                      },
                    },
                    {
                      scope: '#/properties/company/properties',
                      schema: {
                        required: ['companyData'],
                      },
                    },
                    {
                      scope: '#/properties/company/properties/companyData/properties',
                      schema: {
                        required: ['id'],
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
        {
          type: 'Control',
          options: {
            enumType: RiskStatus,
            autocomplete: false,
          },
          label: 'Risk',
          scope: '#/properties/risk',
        }
      ],
    },
    //Company
    {
      type: 'VerticalLayout',
      rule: {
        effect: 'SHOW',
        condition: {
          scope: '#/properties/lpTypeSelect',
          schema: {
            const: 'COMPANY',
          },
        },
      },
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Company Name',
              scope: '#/properties/company/properties/companyData/properties/name',
            },
            {
              type: 'Control',
              label: 'Registry Code',
              scope: '#/properties/company/properties/companyData/properties/registryCode',
            },
          ],
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Residency',
              scope: '#/properties/company/properties/companyData/properties/residency',
            },
            {
              type: 'Control',
              label: 'Tax Residency',
              scope: '#/properties/company/properties/companyData/properties/taxResidency',
            },
          ],
        },
        {
          type: 'Control',
          label: 'Address',
          scope: '#/properties/company/properties/companyData/properties/address',
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Contact Email',
              scope: '#/properties/company/properties/companyData/properties/contactEmail',
            },
            {
              type: 'Control',
              label: 'Bank Account',
              scope: '#/properties/company/properties/companyData/properties/bankAccount',
            },
          ],
        },
        {
          type: 'Group',
          label: 'Representatives',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/company/properties/representatives',
              options: {
                elementLabelProp: 'firstName',
                detail: representativeUISchema,
                custom: true,
                addButtonLabel: 'Add Representative',
              },
            },
          ],
        },
      ],
    },
    // Natural Person
    {
      type: 'VerticalLayout',
      rule: {
        effect: 'SHOW',
        condition: {
          scope: '#/properties/lpTypeSelect',
          schema: {
            const: 'NATURAL_PERSON',
          },
        },
      },
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'First Name',
              scope: '#/properties/person/properties/firstName',
            },
            {
              type: 'Control',
              label: 'Last Name',
              scope: '#/properties/person/properties/lastName',
            },
          ],
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Residency',
              scope: '#/properties/person/properties/residency',
            },
            {
              type: 'Control',
              label: 'Tax Residency',
              scope: '#/properties/person/properties/taxResidency',
            },
          ],
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Email',
              scope: '#/properties/person/properties/email',
            },
            {
              type: 'Control',
              label: 'Bank Account',
              scope: '#/properties/person/properties/bankAccount',
            },
          ],
        },
        {
          type: 'Control',
          label: 'Address',
          scope: '#/properties/person/properties/address',
        },
      ],
    },
  ],
};
