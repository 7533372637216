/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAmlForm,
    ApiAmlFormFromJSON,
    ApiAmlFormFromJSONTyped,
    ApiAmlFormToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiOnboardCompanyAml
 */
export interface ApiOnboardCompanyAml {
    /**
     * 
     * @type {string}
     * @memberof ApiOnboardCompanyAml
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOnboardCompanyAml
     */
    companyName?: string;
    /**
     * 
     * @type {ApiAmlForm}
     * @memberof ApiOnboardCompanyAml
     */
    aml?: ApiAmlForm;
}

export function ApiOnboardCompanyAmlFromJSON(json: any): ApiOnboardCompanyAml {
    return ApiOnboardCompanyAmlFromJSONTyped(json, false);
}

export function ApiOnboardCompanyAmlFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiOnboardCompanyAml {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'aml': !exists(json, 'aml') ? undefined : ApiAmlFormFromJSON(json['aml']),
    };
}

export function ApiOnboardCompanyAmlToJSON(value?: ApiOnboardCompanyAml | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'companyName': value.companyName,
        'aml': ApiAmlFormToJSON(value.aml),
    };
}

