import {
  Configuration,
  Middleware,
  LimitedPartnerPlatformApi,
  LimitedPartnersApi,
  FundsApi,
  CompaniesApi,
  DemoApi,
  InvestorDocumentApi,
  FundDocumentApi,
  LimitedPartnersAmlApi,
  OwnerApi,
  InvestorFundDocumentApi,
  RepresentativesApi,
  OnboardApi,
  OwnerInvitationApi,
  OwnerShuftiStatusApi
} from './api';
import { Auth } from '@aws-amplify/auth';
import { QueryClient } from 'react-query';

const basePath = '/api/v1';

const middleware: Middleware = {
  pre: async (context) => {
    try {
      const cognitoUserSession = await Auth.currentSession();
      const idToken = cognitoUserSession.getIdToken();
      const jwtToken = idToken.getJwtToken();

      const newHeaders = new Headers(context.init.headers);
      newHeaders.set('Authorization', `Bearer ${jwtToken}`);
      const impersonate = localStorage.getItem('impersonate');
      if (impersonate != null) {
        newHeaders.set('Impersonate', impersonate);
      }

      const newRequest = { ...context.init, headers: newHeaders };

      return { url: context.url, init: newRequest };
    } catch (e) {
      if (e == 'No current user' && context.url.indexOf('/onboard') > 0) {
        return { url: context.url, init: context.init };
      }
    }
  },
};

export const configuration = new Configuration({
  basePath,
  middleware: process.env.STORYBOOK ? [] : [middleware],
});

export const limitedPartnerPlatformApi = new LimitedPartnerPlatformApi(configuration);
export const fundsApi = new FundsApi(configuration);
export const companiesApi = new CompaniesApi(configuration);
export const onboardApi = new OnboardApi(configuration);
export const limitedPartnersApi = new LimitedPartnersApi(configuration);
export const demoApi = new DemoApi(configuration);
export const fundDocumentApi = new FundDocumentApi(configuration);
export const investorDocumentApi = new InvestorDocumentApi(configuration);
export const investorFundDocumentApi = new InvestorFundDocumentApi(configuration);
export const ownerAmlApi = new LimitedPartnersAmlApi(configuration);
export const ownerApi = new OwnerApi(configuration);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
export const representativesApi = new RepresentativesApi(configuration);
export const ownerInvitationApi = new OwnerInvitationApi(configuration);
export const ownerShuftiApi = new OwnerShuftiStatusApi(configuration);
export * from './api';
