/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiInvestment,
    ApiInvestmentFromJSON,
    ApiInvestmentFromJSONTyped,
    ApiInvestmentToJSON,
    ApiRegistry,
    ApiRegistryFromJSON,
    ApiRegistryFromJSONTyped,
    ApiRegistryToJSON,
    ServiceType,
    ServiceTypeFromJSON,
    ServiceTypeFromJSONTyped,
    ServiceTypeToJSON,
} from './';

/**
 * Fund object
 * @export
 * @interface ApiFund
 */
export interface ApiFund {
    /**
     * 
     * @type {string}
     * @memberof ApiFund
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFund
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ApiFund
     */
    fundSize?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiFund
     */
    symbol?: string;
    /**
     * 
     * @type {ServiceType}
     * @memberof ApiFund
     */
    serviceType: ServiceType;
    /**
     * 
     * @type {Array<ApiInvestment>}
     * @memberof ApiFund
     */
    investments?: Array<ApiInvestment>;
    /**
     * 
     * @type {Array<ApiRegistry>}
     * @memberof ApiFund
     */
    registry?: Array<ApiRegistry>;
}

export function ApiFundFromJSON(json: any): ApiFund {
    return ApiFundFromJSONTyped(json, false);
}

export function ApiFundFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiFund {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'fundSize': !exists(json, 'fundSize') ? undefined : json['fundSize'],
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
        'serviceType': ServiceTypeFromJSON(json['serviceType']),
        'investments': !exists(json, 'investments') ? undefined : ((json['investments'] as Array<any>).map(ApiInvestmentFromJSON)),
        'registry': !exists(json, 'registry') ? undefined : ((json['registry'] as Array<any>).map(ApiRegistryFromJSON)),
    };
}

export function ApiFundToJSON(value?: ApiFund | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'fundSize': value.fundSize,
        'symbol': value.symbol,
        'serviceType': ServiceTypeToJSON(value.serviceType),
        'investments': value.investments === undefined ? undefined : ((value.investments as Array<any>).map(ApiInvestmentToJSON)),
        'registry': value.registry === undefined ? undefined : ((value.registry as Array<any>).map(ApiRegistryToJSON)),
    };
}

