import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { TextField } from '../../common/TextField';
import * as yup from 'yup';
import { ApiCreateAdminRequest, CompanyData, RiskStatus } from '../../api';
import { Button } from '../../common/Button';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { LimitedPartnerFormData } from './LimitedPartnerFormWithStateMachine';
import { OneColumnRow, TwoColumnRow } from '../../common/FormElements';
import { Autocomplete } from '../../common/inputs/Autocomplete';
import { Select } from '../../common/inputs/Select';

export type FormDataCompany = {
  risk: RiskStatus;
  companyData: CompanyData;
  representatives: Array<ApiCreateAdminRequest>;
};

export const schema = yup.object({
  companyData: yup.object({
    name: yup.string().required(),
    registryCode: yup.string().required(),
  }),
  representatives: yup.array().of(
    yup.object({
      adminInfo: yup.object({
        email: yup
          .string()
          .email('Email must be a valid email')
          .required('Email is a required field'),
        firstName: yup.string().required('First name is a required field'),
        lastName: yup.string().required('Last name is a required field'),
        phone: yup.string().required('Phone is a required field'),
        legalPowers: yup.string().required('Legal Powers is a required field'),
      }),
    })
  ),
});

export const CompanyForm = () => {
  const formHook = useFormContext<LimitedPartnerFormData>();
  const { control, formState, getValues } = formHook;

  const { fields, append } = useFieldArray({
    control,
    name: 'company.representatives',
  });

  return (
    <>
      <OneColumnRow>
        <Controller
          control={control}
          name='company.risk'
          render={({ field }) => {
            const { ref, value, ...rest } = field;
            const options = [
              { value: RiskStatus.Low, label: RiskStatus.Low },
              { value: RiskStatus.Medium, label: RiskStatus.Medium },
              { value: RiskStatus.High, label: RiskStatus.High },
            ];
            return (
              <Select
                options={options}
                {...rest}
                value={value ? value : options[0].value}
                inputRef={ref}
                label='Risk'
                required
              />
            );
          }}
        />
      </OneColumnRow>
      <TwoColumnRow>
        <Controller
          control={control}
          name='company.companyData.name'
          render={({ field }) => {
            const { ref, ...rest } = field;
            return (
              <TextField
                {...rest}
                sx={{ flex: '1 1 0px' }}
                inputRef={ref}
                label='Company Name'
                required={true}
                error={formState.errors.company?.companyData?.name != undefined}
                helperText={formState.errors.company?.companyData?.name?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name='company.companyData.registryCode'
          render={({ field }) => {
            const { ref, ...rest } = field;
            return (
              <TextField
                {...rest}
                sx={{ flex: '1 1 0px' }}
                inputRef={ref}
                label='Registry Code'
                required={true}
                error={formState.errors.company?.companyData?.registryCode != undefined}
                helperText={formState.errors.company?.companyData?.registryCode?.message}
              />
            );
          }}
        />
      </TwoColumnRow>
      <Typography>Representatives</Typography>
      {fields.map((item, index) => {
        const errorObj =
          formState.errors.company?.representatives != undefined
            ? formState.errors.company?.representatives[index]
            : undefined;
        return (
          <Box key={item.id} flexGrow='1'>
            <OneColumnRow>
              <Controller
                control={control}
                name={`company.representatives.${index}.adminInfo.email`}
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return (
                    <TextField
                      {...rest}
                      sx={{ flex: '1 1 0px' }}
                      inputRef={ref}
                      label='Email'
                      required={true}
                      error={errorObj?.adminInfo?.email != undefined}
                      helperText={errorObj?.adminInfo?.email?.message}
                    />
                  );
                }}
              />
            </OneColumnRow>
            <TwoColumnRow>
              <Controller
                control={control}
                name={`company.representatives.${index}.adminInfo.firstName`}
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return (
                    <TextField
                      {...rest}
                      sx={{ flex: '1 1 0px' }}
                      inputRef={ref}
                      label='First Name'
                      required={true}
                      error={errorObj?.adminInfo?.firstName != undefined}
                      helperText={errorObj?.adminInfo?.firstName?.message}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name={`company.representatives.${index}.adminInfo.lastName`}
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return (
                    <TextField
                      {...rest}
                      sx={{ flex: '1 1 0px' }}
                      inputRef={ref}
                      label='Last Name'
                      required={true}
                      error={errorObj?.adminInfo?.lastName != undefined}
                      helperText={errorObj?.adminInfo?.lastName?.message}
                    />
                  );
                }}
              />
            </TwoColumnRow>
            <TwoColumnRow>
              <Controller
                control={control}
                name={`company.representatives.${index}.adminInfo.phone`}
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return (
                    <TextField
                      {...rest}
                      sx={{ flex: '1 1 0px' }}
                      inputRef={ref}
                      label='Phone'
                      required={true}
                      error={errorObj?.adminInfo?.phone != undefined}
                      helperText={errorObj?.adminInfo?.phone?.message}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name={`company.representatives.${index}.adminInfo.legalPowers`}
                render={({ field }) => {
                  const { ref, onChange, ...rest } = field;
                  return (
                    <Autocomplete
                      label='Legal Powers'
                      sx={{ flex: '1 1 0px' }}
                      options={['Board Member', 'Power of Attorney']}
                      freeSolo
                      onChange={(e, value) => {
                        onChange(value != null ? value : '');
                      }}
                      inputRef={ref}
                      error={errorObj?.adminInfo?.legalPowers != undefined}
                      helperText={errorObj?.adminInfo?.legalPowers?.message}
                      {...rest}
                    />
                  );
                }}
              />
            </TwoColumnRow>
          </Box>
        );
      })}

      <OneColumnRow>
        <Button
          variant='AddInForm'
          onClick={() => {
            const formParams: LimitedPartnerFormData = getValues();
            append({
              adminInfo: {
                email: '',
                firstName: '',
                lastName: '',
                legalPowers: '',
                phone: '',
              },
              company: formParams.company.companyData.id,
            });
          }}
        >
          Add Representative
        </Button>
      </OneColumnRow>
    </>
  );
};
