import {
  and,
  ControlProps,
  EnumCellProps,
  EnumOption,
  JsonSchema,
  RankedTester,
  rankWith,
  UISchemaElement,
  uiTypeIs,
  WithClassname,
} from '@jsonforms/core';
import { MaterialInputControl } from '@jsonforms/material-renderers';
import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { ReactNode } from 'react';
import merge from 'lodash/merge';
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  Input,
} from '@mui/material';

export interface WithOptionLabel {
  getOptionLabel?(option: EnumOption): string;
  renderOption?(
    props: React.HTMLAttributes<HTMLLIElement>,
    option: EnumOption,
    state: AutocompleteRenderOptionState
  ): ReactNode;
  filterOptions?(options: EnumOption[], state: FilterOptionsState<EnumOption>): EnumOption[];
}

export const MuiAutocomplete = (props: EnumCellProps & WithClassname & WithOptionLabel) => {
  const {
    data,
    className,
    id,
    enabled,
    uischema,
    path,
    handleChange,
    config,
    renderOption,
    filterOptions,
  } = props;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const [inputValue, setInputValue] = React.useState(data ?? '');

  const options = uischema.options?.options != undefined ? uischema.options.options : [];

  return (
    <Autocomplete
      className={className}
      id={id}
      disabled={!enabled}
      value={data}
      onChange={(_event: any, newValue: string | EnumOption | null) => {
        if (typeof newValue == 'string') {
          handleChange(path, newValue);
        } else {
          handleChange(path, newValue?.value);
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
        handleChange(path, newInputValue);
      }}
      autoHighlight
      // autoSelect
      // autoComplete
      fullWidth
      freeSolo
      options={options != undefined ? options : []}
      style={{ marginTop: 16 }}
      renderInput={(params) => (
        <Input
          style={{ width: '100%' }}
          type='text'
          inputProps={params.inputProps}
          inputRef={params.InputProps.ref}
          autoFocus={appliedUiSchemaOptions.focus}
          disabled={!enabled}
        />
      )}
      renderOption={renderOption}
      filterOptions={filterOptions}
    />
  );
};

export const enumFreeSoloRenderer = (props: ControlProps) => {
  return <MaterialInputControl {...props} input={MuiAutocomplete} />;
};

export default withJsonFormsControlProps(enumFreeSoloRenderer);

export const isEnumFreeSoloControl = and(
  uiTypeIs('Control'),
  (uischema: UISchemaElement, _schema: JsonSchema) =>
    uischema.options != undefined && 'freeSolo' in uischema.options
);

export const enumFreeSoloTester: RankedTester = rankWith(3, isEnumFreeSoloControl);
