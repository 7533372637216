import { CircularProgress } from '@mui/material';
import React from 'react';
import { useQueryClient } from 'react-query';
import { ApiCompany, ApiFund, LimitedPartnerType, UpdateFundRequest } from '../../api';
import { Button } from '../../common/Button';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import {
  buildLimitedPartnerOptions,
  LimitedPartnerOption,
  useFund,
  useUpdateFund,
} from '../../hooks/useFund';
import { useGeneralPartner } from '../../hooks/useGeneralPartner';
import { FundDashboardRegistryParams } from './RegistryEntryForm';

const findLimitedPartnerOption = (
  generalPartner: ApiCompany,
  fund: ApiFund,
  registryId: string
): LimitedPartnerOption | undefined => {
  const limitedPartners: Array<LimitedPartnerOption> = buildLimitedPartnerOptions(
    generalPartner,
    fund,
    false
  );
  const retLimitedPartnerOption = limitedPartners.find(
    (limitedPartneOption) => limitedPartneOption.value == registryId
  );
  return retLimitedPartnerOption;
};

const computeUpdatePayload = (
  fund: ApiFund,
  limitedPartnerOption: LimitedPartnerOption
): UpdateFundRequest | undefined => {
  if (fund.id && fund.registry && fund.investments) {
    const payload: UpdateFundRequest = {
      fundId: fund.id,
      apiUpsertFundRequest: {
        registry: [...fund.registry],
        investments: fund.investments,
        fundSize: fund.fundSize,
        name: fund.name,
        serviceType: fund.serviceType,
        symbol: fund.symbol,
      },
    };
    if (payload.apiUpsertFundRequest.registry != undefined) {
      const personRegistryindex = payload.apiUpsertFundRequest.registry.findIndex(
        (p) => p.entityId == limitedPartnerOption.value
      );
      payload.apiUpsertFundRequest.registry.splice(personRegistryindex, 1);
    }
    return payload;
  }
  return undefined;
};

export const PersonRegistryDelete: React.FC<FundDashboardRegistryParams> = (
  params: FundDashboardRegistryParams
) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { generalPartner, isGeneralPartnerLoading } = useGeneralPartner(params.generalPartnerId);
  const { fund, isFundLoading } = useFund(params.generalPartnerId, params.fundId);
  const queryClient = useQueryClient();
  const useUpdateFundMutation = useUpdateFund();
  if (isGeneralPartnerLoading || isFundLoading || !generalPartner || !fund || !params.registryId) {
    return <CircularProgress />;
  }
  const limitedPartnerOption = findLimitedPartnerOption(generalPartner, fund, params.registryId);
  if (!limitedPartnerOption) {
    return <CircularProgress />;
  }
  if (open && useUpdateFundMutation.isSuccess) {
    queryClient.invalidateQueries('fund');
    setOpen(false);
  }
  return (
    <>
      <Button variant='TertiaryAction' onClick={handleClickOpen}>
        Delete
      </Button>
      <ConfirmationDialog
        title='Registry Delete!'
        message={`Are you sure you want to delete ${limitedPartnerOption.label} from
      registry?`}
        confirmButtonLabel='Delete'
        confirmButtonVariant='Disabling'
        open={open}
        onCancel={handleClose}
        onConfirm={() => {
          const payload = computeUpdatePayload(fund, limitedPartnerOption);
          if (payload) {
            useUpdateFundMutation.mutate(payload);
          }
        }}
      />
    </>
  );
};
