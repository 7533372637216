/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAmlInfo,
    ApiAmlInfoFromJSON,
    ApiAmlInfoFromJSONTyped,
    ApiAmlInfoToJSON,
    ApiCompany,
    ApiCompanyFromJSON,
    ApiCompanyFromJSONTyped,
    ApiCompanyToJSON,
    ApiInvestorProfile,
    ApiInvestorProfileFromJSON,
    ApiInvestorProfileFromJSONTyped,
    ApiInvestorProfileToJSON,
    LimitedPartnerType,
    LimitedPartnerTypeFromJSON,
    LimitedPartnerTypeFromJSONTyped,
    LimitedPartnerTypeToJSON,
    RiskStatus,
    RiskStatusFromJSON,
    RiskStatusFromJSONTyped,
    RiskStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiLimitedPartner
 */
export interface ApiLimitedPartner {
    /**
     * 
     * @type {ApiInvestorProfile}
     * @memberof ApiLimitedPartner
     */
    person?: ApiInvestorProfile;
    /**
     * 
     * @type {ApiCompany}
     * @memberof ApiLimitedPartner
     */
    company?: ApiCompany;
    /**
     * 
     * @type {LimitedPartnerType}
     * @memberof ApiLimitedPartner
     */
    limitedPartnerType: LimitedPartnerType;
    /**
     * 
     * @type {ApiAmlInfo}
     * @memberof ApiLimitedPartner
     */
    personAmlInfo?: ApiAmlInfo;
    /**
     * 
     * @type {ApiAmlInfo}
     * @memberof ApiLimitedPartner
     */
    companyAmlInfo?: ApiAmlInfo;
    /**
     * 
     * @type {RiskStatus}
     * @memberof ApiLimitedPartner
     */
    risk?: RiskStatus;
}

export function ApiLimitedPartnerFromJSON(json: any): ApiLimitedPartner {
    return ApiLimitedPartnerFromJSONTyped(json, false);
}

export function ApiLimitedPartnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiLimitedPartner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'person': !exists(json, 'person') ? undefined : ApiInvestorProfileFromJSON(json['person']),
        'company': !exists(json, 'company') ? undefined : ApiCompanyFromJSON(json['company']),
        'limitedPartnerType': LimitedPartnerTypeFromJSON(json['limitedPartnerType']),
        'personAmlInfo': !exists(json, 'personAmlInfo') ? undefined : ApiAmlInfoFromJSON(json['personAmlInfo']),
        'companyAmlInfo': !exists(json, 'companyAmlInfo') ? undefined : ApiAmlInfoFromJSON(json['companyAmlInfo']),
        'risk': !exists(json, 'risk') ? undefined : RiskStatusFromJSON(json['risk']),
    };
}

export function ApiLimitedPartnerToJSON(value?: ApiLimitedPartner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'person': ApiInvestorProfileToJSON(value.person),
        'company': ApiCompanyToJSON(value.company),
        'limitedPartnerType': LimitedPartnerTypeToJSON(value.limitedPartnerType),
        'personAmlInfo': ApiAmlInfoToJSON(value.personAmlInfo),
        'companyAmlInfo': ApiAmlInfoToJSON(value.companyAmlInfo),
        'risk': RiskStatusToJSON(value.risk),
    };
}

