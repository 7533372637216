import React from 'react';
import Logo from '../assets/logo.png';
import { ApiInvestorProfile, RoleType } from '../api';
import './Sidebar.css';
import { Box, CircularProgress } from '@mui/material';
import { UserType } from '../routing/routes';
import { NavigationLink } from './navigation/NavigationLink';
import { ProfileBadge } from './ProfileBadge';

export interface SidebarProps {
  userType: UserType;
  switchUserType: (type: UserType) => void;
  generalPartnerLinks?: React.ReactNode;
  limitedPartnerLinks?: React.ReactNode;
  actionButtons?: React.ReactNode;
  profile: ApiInvestorProfile | undefined;
  isProfileLoading: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({
  generalPartnerLinks,
  limitedPartnerLinks,
  profile,
  isProfileLoading,
  actionButtons,
  userType,
  switchUserType,
}: SidebarProps) => {
  const personHasAnyOfRoles = (personRoles: RoleType[], roles: RoleType[]): boolean => {
    return personRoles.some((pr) => roles.indexOf(pr) >= 0);
  };

  const canSwitchProfile = profile && personHasAnyOfRoles(profile.roles, [RoleType.Owner]);

  const profileSwitcher = canSwitchProfile ? (
    userType === 'LimitedPartner' ? (
      <NavigationLink
        to={'/general-partners'}
        clickHandler={() => switchUserType('GeneralPartner')}
      >
        Switch to Admin view
      </NavigationLink>
    ) : (
      <NavigationLink
        to={'/limited-partners/dashboard'}
        clickHandler={() => switchUserType('LimitedPartner')}
      >
        Switch to LP view
      </NavigationLink>
    )
  ) : (
    <></>
  );

  return (
    <Box className='profile-section' display='flex' flexDirection='column'>
      <img
        style={{ maxWidth: '176px', width: 'auto', height: 'auto' }}
        src={Logo}
        alt='Poolside logo'
      />
      {isProfileLoading ? (
        <CircularProgress style={{ color: 'white', margin: '0 auto 0 auto' }} />
      ) : (
        <ProfileBadge profile={profile} />
      )}

      <div className='separator-line' />

      {(userType === 'GeneralPartner' && generalPartnerLinks) || limitedPartnerLinks}

      {profileSwitcher}

      <Box marginTop='auto'>{actionButtons}</Box>
    </Box>
  );
};
