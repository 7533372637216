import React, { useState } from 'react';
import { DashboardPage } from '../pages/dashboard/DashboardPage';
import { VerificationPage } from '../pages/verification/VerificationPage';
import { InvestmentBreakdownPage } from '../pages/dashboard/breakdown/InvestmentBreakdownPage';
import { InvestmentDocumentsPage } from '../pages/reporting/InvestmentDocumentsPage';
import { InvestmentReportsPage } from '../pages/reporting/InvestmentReportsPage';
import { InvestmentFormsPage } from '../pages/reporting/InvestmentFormsPage';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { GeneralPartnersList } from '../pages/generalpartner/GeneralPartnersList';
import { Sidebar } from '../common/Sidebar';
import { Box } from '@mui/material';
import { Footer } from '../common/Footer';
import { useInvestorProfile } from '../hooks/useInvestorProfile';
import { FundDashboard } from '../pages/generalpartner/FundDashboard';
import { GeneralPartnerDashboard } from '../pages/generalpartner/GeneralPartnerDashboard';
import { NavigationLink } from '../common/navigation/NavigationLink';
import { SignOutButton } from '../pages/auth/SignOutButton';
import { RegistryEntryForm } from '../pages/generalpartner/RegistryEntryForm';
import { InvestmentForm } from '../pages/generalpartner/InvestmentForm';
import { FundInfoForm } from '../pages/generalpartner/FundInfoForm';
import { AmlForm } from '../pages/aml/AmlForm';
import { GPCompanyForm } from '../pages/generalpartner/GPCompanyForm';
import { AmlOwnerForm } from '../pages/aml/AmlOwnerForm';
import { LimitedPartnerFormSM } from '../pages/generalpartner/LimitedPartnerFormWithStateMachine';
import { OnBoardingPage } from '../pages/onboard/OnBoardingPage';
import { Authenticator } from '../pages/auth/Authenticator';
import { PersonDetails } from '../pages/generalpartner/PersonDetails';
import { VerificationEnd } from '../pages/verification/VerificationEnd';

export type UserType = 'GeneralPartner' | 'LimitedPartner' | 'Onboarding';

const Layout = () => {
  const { isProfileLoading, profile } = useInvestorProfile();
  const { pathname } = useLocation();
  const [type, setType] = useState<UserType>(
    (pathname.startsWith('/general-partners') && 'GeneralPartner') || 'LimitedPartner'
  );
  return (
    <Box>
      <Box display='flex' flexDirection='row'>
        <Sidebar
          userType={type}
          switchUserType={setType}
          profile={profile}
          isProfileLoading={isProfileLoading}
          generalPartnerLinks={[
            <NavigationLink key='root' to='/general-partners'>
              All companies
            </NavigationLink>,
          ]}
          limitedPartnerLinks={[
            <NavigationLink key='dashboard' to='/limited-partners/dashboard'>
              My investments
            </NavigationLink>,
            // <NavigationLink key='documents' to='/limited-partners/documents'>
            //   Investment documents
            // </NavigationLink>,
            // <NavigationLink key='reports' to='/limited-partners/reports'>
            //   Investment reports
            // </NavigationLink>,
            // <NavigationLink key='forms' to='/limited-partners/forms'>
            //   Investment forms
            // </NavigationLink>,
            <NavigationLink key='aml' to='/limited-partners/aml'>
              AML
            </NavigationLink>,
          ]}
          actionButtons={<SignOutButton color='white' />}
        />
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/limited-partners/dashboard' />} />
      <Route path='/onboard' element={<OnBoardingPage />} />
      <Route path='/verification-end' element={<VerificationEnd />} />
      <Route
        element={
          <Authenticator>
            <Outlet />
          </Authenticator>
        }
      >
        <Route path='/verification' element={<VerificationPage />} />

        <Route path='/limited-partners' element={<Layout />}>
          <Route
            path='dashboard'
            element={
              <DashboardPage />
            }
          />
          <Route
            path='dashboard/:id/:selectedTab'
            element={
              <InvestmentBreakdownPage />
            }
          />
          <Route
            path='documents'
            element={
              <InvestmentDocumentsPage />
            }
          />
          <Route
            path='reports'
            element={
              <InvestmentReportsPage />
            }
          />
          <Route
            path='forms'
            element={
              <InvestmentFormsPage />
            }
          />
          <Route
            path='aml'
            element={
              <AmlForm />
            }
          />
        </Route>
        <Route path='/general-partners' element={<Layout />}>
          <Route index element={<GeneralPartnersList />} />
          <Route path='add' element={<GPCompanyForm />} />
          <Route path=':generalPartnerId/edit' element={<GPCompanyForm />} />
          <Route path=':generalPartnerId/:selectedTab' element={<GeneralPartnerDashboard />} />
          <Route path=':generalPartnerId/funds/:fundId/edit' element={<FundInfoForm />} />
          <Route path=':generalPartnerId/funds/add' element={<FundInfoForm />} />
          <Route path=':generalPartnerId/funds/:fundId/:selectedTab' element={<FundDashboard />} />
          <Route
            path=':generalPartnerId/funds/:fundId/investment/add'
            element={<InvestmentForm />}
          />
          <Route
            path=':generalPartnerId/funds/:fundId/investment/:investmentId/edit'
            element={<InvestmentForm />}
          />
          <Route
            path=':generalPartnerId/funds/:fundId/registry/add'
            element={<RegistryEntryForm />}
          />
          <Route
            path=':generalPartnerId/funds/:fundId/registry/:registryId/edit'
            element={<RegistryEntryForm />}
          />
          <Route path=':generalPartnerId' element={<GeneralPartnerDashboard />} />
          <Route path=':generalPartnerId/limited-partners/add' element={<LimitedPartnerFormSM />} />
          <Route
            path=':generalPartnerId/limited-partners/:limitedPartnerId/:limitedPartnerType/edit'
            element={<LimitedPartnerFormSM />}
          />
          <Route
            path=':generalPartnerId/limited-partners/:personId/:companyId/aml'
            element={<AmlOwnerForm />}
          />
          <Route
            path=':generalPartnerId/person/:personId'
            element={<PersonDetails />}
          />
        </Route>
      </Route>
    </Routes>
  );
};
