import {
  and,
  ControlProps,
  EnumOption,
  JsonSchema,
  or,
  RankedTester,
  rankWith,
  schemaMatches,
  UISchemaElement,
  uiTypeIs,
} from '@jsonforms/core';
import { Unwrapped } from '@jsonforms/material-renderers';
import { withJsonFormsControlProps } from '@jsonforms/react';
import React from 'react';

const { MaterialEnumControl } = Unwrapped;

export const typeScriptEnumListControl = (props: ControlProps) => {
  const options: EnumOption[] = [];
  const enumType = (props.uischema.options as any)['enumType'];
  for (const k of Object.keys(enumType)) {
    options.push({
      value: enumType[k],
      label: k,
    });
  }
  return <MaterialEnumControl {...props} options={options} />;
};

export default withJsonFormsControlProps(typeScriptEnumListControl);

export const isTypeScriptEnumListControl = and(
  uiTypeIs('Control'),
  (uischema: UISchemaElement, schema: JsonSchema) =>
    uischema.options != undefined && 'enumType' in uischema.options
);

export const typeScriptEnumListControlTester: RankedTester = rankWith(
  3,
  isTypeScriptEnumListControl
);
