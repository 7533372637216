import { Box, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ApiInvestorProfile, RoleType } from '../../api';
import { representativesApi } from '../../apis';
import { useInvestorProfile } from '../../hooks/useInvestorProfile';
import { Button } from '../Button';
import { Autocomplete } from '../inputs/Autocomplete';

export interface Props {
  profile: ApiInvestorProfile | undefined;
}

const IMPERSONATE = 'impersonate';
export const RepresentativeChooser = () => {
  const { data, isLoading } = useQuery('allReps', async () => {
    return representativesApi.getAllRepresentatives();
  });
  const queryClient = useQueryClient();

  const [impersonate, setImpersonate] = useState(localStorage.getItem(IMPERSONATE));

  if (isLoading || data == undefined) {
    return <></>;
  }
  const options = data.map((rep) => {
    return {
      value: rep.id,
      label: rep.email,
    };
  });

  const currentImpersonate = options.find((option) => option.value == impersonate);

  return (
    <Autocomplete
      value={currentImpersonate}
      label='Impersonate'
      options={options}
      onChange={(e, value) => {
        if (value != null) {
          const recordValue = value as Record<string, string>;
          localStorage.setItem(IMPERSONATE, recordValue['value']);
          setImpersonate(recordValue['value']);
        } else {
          localStorage.removeItem(IMPERSONATE);
          setImpersonate(null);
        }
        queryClient.invalidateQueries();
      }}
    />
  );
};

export const Impersonator = () => {
  const { isProfileLoading, profile, isProfileError } = useInvestorProfile();
  const queryClient = useQueryClient();
  if (isProfileError) {
    return <></>;
  }
  if (isProfileLoading || profile == undefined) {
    return <CircularProgress />;
  }
  const personHasAnyOfRoles = (personRoles: RoleType[], roles: RoleType[]): boolean => {
    return personRoles.some((pr) => roles.indexOf(pr) >= 0);
  };
  const canImpersonate = profile && personHasAnyOfRoles(profile.roles, [RoleType.Owner]);

  if (!canImpersonate) {
    const isImpersonate = localStorage.getItem(IMPERSONATE) != null;
    if (isImpersonate) {
      return (
        <Box display='flex' flexDirection='column'>
          <Button
            variant='TertiaryGhost'
            onClick={() => {
              localStorage.removeItem(IMPERSONATE);
              queryClient.invalidateQueries();
            }}
          >
            End Impersonation
          </Button>
        </Box>
      );
    } else {
      return <></>;
    }
  }

  return (
    <Box display='flex' flexDirection='column'>
      <RepresentativeChooser />
    </Box>
  );
};
