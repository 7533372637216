import { useNavigate, useParams } from 'react-router-dom';
import { BreadcrumbLink } from '../../common/Breadcrumbs';
import { MainContent } from '../../common/MainContent';
import { limitedPartnerFormStateMachine } from './limitedPartnerFormStateMachine';
import { useMachine } from '@xstate/react';
import React, { useEffect } from 'react';
import { Box, CircularProgress, Toolbar } from '@mui/material';
import { JsonForms } from '@jsonforms/react';
import { poolSideJsonFormRenderers } from '../../common/renderers';
import { materialCells } from '@jsonforms/material-renderers';
import { lpFormSchema } from './lpFormDataSchema';
import { lpFormUISchema } from './lpFormUISchema';
import { Button } from '../../common/Button';
import { LimitedPartnerType, RiskStatus } from '../../api';
import { FormDataPerson } from './NaturalPersonForm';
import { FormDataCompany } from './CompanyForm';

export type LPFormParams = {
  generalPartnerId: string;
  limitedPartnerId: string;
  limitedPartnerType: LimitedPartnerType;
};

export type LimitedPartnerFormData = {
  lpTypeSelect: LimitedPartnerType;
  risk: RiskStatus;
  person: FormDataPerson;
  company: FormDataCompany;
};

export const LimitedPartnerFormSM = () => {
  const params = useParams<LPFormParams>();
  const [current, send] = useMachine(limitedPartnerFormStateMachine);
  const navigate = useNavigate();

  useEffect(() => {
    send({
      type: 'init',
      formParams: params,
    });
  }, [params]);

  if (current.matches('finish')) {
    navigate(`/general-partners/${current.context.generalPartner?.id}/limited-partners`);
  }
  if (!current.matches('loaded')) {
    return <CircularProgress />;
  }

  return (
    <MainContent
      links={[
        <BreadcrumbLink key={1} to='/general-partners'>
          All companies
        </BreadcrumbLink>,
        <BreadcrumbLink key={2} to={`/general-partners/${current.context.generalPartner?.id}`}>
          {current.context.generalPartner?.name}
        </BreadcrumbLink>,
        <BreadcrumbLink
          key={2}
          to={`/general-partners/${current.context.generalPartner?.id}/limited-partners/add`}
        >
          {params.limitedPartnerId ? 'Update Limited Partner' : 'Add Limited Partner'}
        </BreadcrumbLink>,
      ]}
      headingText={current.context.generalPartner?.name}
    >
      <Box display='flex' flexDirection='column' width='780px' marginTop='20px' rowGap='20px'>
        <JsonForms
          schema={lpFormSchema}
          uischema={lpFormUISchema}
          data={current.context.formData}
          renderers={poolSideJsonFormRenderers}
          cells={materialCells}
          onChange={({ data }) => {
            console.log(data);
            send({
              type: 'amlFormUpdate',
              formData: data,
            });
          }}
        />
        <Toolbar
          disableGutters
          sx={{
            paddingLeft: '0px',
            paddingRight: '0px',
            flexGrow: 1,
            columnGap: '5px',
          }}
        >
          <Box flexGrow={1}></Box>
          <Button href={`/general-partners/${current.context.generalPartner?.id}/limited-partners`}>
            Cancel
          </Button>
          <Button
            variant='text'
            onClick={() => {
              send({ type: 'save' });
            }}
          >
            Save
          </Button>
        </Toolbar>
      </Box>
    </MainContent>
  );
};
