import React from 'react';
import { column, customColumn, DataGrid } from '../../../common/DataGrid';
import {
  getDocumentDownloadLink,
  userInvestorFundDocuments,
} from '../../../hooks/useDocuments';
import { ApiDocument } from '../../../api/models';
import { Button } from '../../../common/Button';
import { Box, CircularProgress } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DateTime, SystemZone } from 'luxon';
import { useAlert } from 'react-alert';

export interface Props {
  fundId: string;
}

export const InvestmentFundDocuments: React.FC<Props> = ({ fundId }: Props) => {
  const documentsTableColumns: GridColDef[] = [
    column({
      field: 'filename',
      headerName: 'File name',
      flex: 60,
    }),
    column({
      field: 'type',
      headerName: 'Type',
      flex: 20,
    }),
    column({
      field: 'uploadedTime',
      headerName: 'Uploaded time',
      flex: 20,
    }),
  ];

  const alert = useAlert();

  const downloadClickHandler = async (docId: string, htmlElement: any) => {
    htmlElement.disabled = true;
    try {
      const documentLink = await getDocumentDownloadLink(docId);
      window.location.href = documentLink.link;
    } catch (e: any) {
      alert.show('Error: ' + (e.statusText || ''), { type: 'error' });
    } finally {
      htmlElement.disabled = false;
    }
  };

  const { fundDocuments, isLoading } = userInvestorFundDocuments(fundId);

  if (isLoading) return <CircularProgress />;

  const formatDocumentRow = (fundDocuments: Array<ApiDocument>) => {
    return fundDocuments.map((row: ApiDocument) => {
      return {
        id: row.id,
        filename: row.filename,
        type: row.type,
        uploadedTime: DateTime.fromMillis(row.uploadTimeMs, {
          zone: SystemZone.instance,
        }).toFormat('yyyy/MM/dd HH:mm'),
      };
    });
  };

  return (
    <>
      <DataGrid
        columns={documentsTableColumns.concat([
          customColumn({
            minWidth: 240,
            renderCell: (params) => {
              return (
                <Box>
                  <Button
                    variant='TertiaryAction'
                    onClick={(event) =>
                      downloadClickHandler(params.row.id, event.target)
                    }
                  >
                    Download
                  </Button>
                </Box>
              );
            },
          }),
        ])}
        rows={(fundDocuments && formatDocumentRow(fundDocuments)) || []}
      />
    </>
  );
};
