/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiDisablePersonsRequest
 */
export interface ApiDisablePersonsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiDisablePersonsRequest
     */
    personIds?: Array<string>;
}

export function ApiDisablePersonsRequestFromJSON(json: any): ApiDisablePersonsRequest {
    return ApiDisablePersonsRequestFromJSONTyped(json, false);
}

export function ApiDisablePersonsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDisablePersonsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personIds': !exists(json, 'personIds') ? undefined : json['personIds'],
    };
}

export function ApiDisablePersonsRequestToJSON(value?: ApiDisablePersonsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personIds': value.personIds,
    };
}

