/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AmlFormStatus,
    AmlFormStatusFromJSON,
    AmlFormStatusFromJSONTyped,
    AmlFormStatusToJSON,
    RiskStatus,
    RiskStatusFromJSON,
    RiskStatusFromJSONTyped,
    RiskStatusToJSON,
} from './';

/**
 * data required to create a company or fund record
 * @export
 * @interface CompanyData
 */
export interface CompanyData {
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    residency?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    taxResidency?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    registryCode?: string;
    /**
     * 
     * @type {AmlFormStatus}
     * @memberof CompanyData
     */
    amlStatus?: AmlFormStatus;
    /**
     * 
     * @type {RiskStatus}
     * @memberof CompanyData
     */
    risk?: RiskStatus;
}

export function CompanyDataFromJSON(json: any): CompanyData {
    return CompanyDataFromJSONTyped(json, false);
}

export function CompanyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'residency': !exists(json, 'residency') ? undefined : json['residency'],
        'taxResidency': !exists(json, 'taxResidency') ? undefined : json['taxResidency'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'bankAccount': !exists(json, 'bankAccount') ? undefined : json['bankAccount'],
        'registryCode': !exists(json, 'registryCode') ? undefined : json['registryCode'],
        'amlStatus': !exists(json, 'amlStatus') ? undefined : AmlFormStatusFromJSON(json['amlStatus']),
        'risk': !exists(json, 'risk') ? undefined : RiskStatusFromJSON(json['risk']),
    };
}

export function CompanyDataToJSON(value?: CompanyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'address': value.address,
        'residency': value.residency,
        'taxResidency': value.taxResidency,
        'contactEmail': value.contactEmail,
        'bankAccount': value.bankAccount,
        'registryCode': value.registryCode,
        'amlStatus': AmlFormStatusToJSON(value.amlStatus),
        'risk': RiskStatusToJSON(value.risk),
    };
}

