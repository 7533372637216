import React from 'react';
import {
  Autocomplete as MaterialAutocomplete,
  AutocompleteProps,
} from '@mui/material';
import { TextField } from '../TextField';

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  label: string;
  inputRef?: React.Ref<any>;
  error?: boolean;
  helperText?: string;
}

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  label,
  inputRef,
  error,
  helperText,
  ...rest
}: Props<T, Multiple, DisableClearable, FreeSolo>) => {
  return (
    <MaterialAutocomplete
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          label={label}
          error={error}
          helperText={helperText}
          variant='standard'
        />
      )}
    />
  );
};
