import { useMutation, useQuery } from 'react-query';
import {
  ApiCompany,
  ApiFund,
  CreateFundRequest,
  LimitedPartnerType,
  UpdateFundRequest,
} from '../api';
import { fundsApi } from '../apis';
import { useGeneralPartner } from './useGeneralPartner';

export type LimitedPartnerOption = {
  label: string;
  type: LimitedPartnerType;
  value: string;
};

export const useFund = (generalPartnerId: string, fundId?: string) => {
  const { generalPartner } = useGeneralPartner(generalPartnerId);
  const companyName = generalPartner?.name;
  const { data, isLoading } = useQuery(
    ['fund', fundId],
    async () => {
      if (fundId) {
        return fundsApi.getFund({ fundId: fundId });
      } else {
        return undefined;
      }
    },
    {
      enabled: !!companyName && fundId != undefined,
    }
  );
  // generalPartner &&
  //   data?.companyRegistry?.forEach((registryEntry) => {
  //     (registryEntry as any).id = registryEntry.companyId;
  //     (registryEntry as any).company =
  //       generalPartner.limitedPartnerCompanies?.find(
  //         (limitedPartner) => limitedPartner.id === registryEntry.companyId
  //       );
  //   });

  // generalPartner &&
  //   data?.personRegistry?.forEach((registryEntry) => {
  //     (registryEntry as any).id = registryEntry.personId;
  //     (registryEntry as any).person = generalPartner.limitedPartnerPeople?.find(
  //       (limitedPartner) => limitedPartner.id === registryEntry.personId
  //     );
  //   });
  return {
    fund: data,
    isFundLoading: isLoading,
  };
};

export const useUpdateFund = () => {
  return useMutation('fund', async (params: UpdateFundRequest) => {
    return fundsApi.updateFund(params);
  });
};

export const useCreateFund = () => {
  return useMutation(['general-partner'], async (params: CreateFundRequest) => {
    return fundsApi.createFund(params);
  });
};

export const buildLimitedPartnerOptions = (
  generalPartner: ApiCompany | undefined,
  fund: ApiFund | undefined,
  excludeExisting: boolean
) => {
  let limitedPartners: Array<LimitedPartnerOption> = [];
  if (generalPartner?.limitedPartnerCompanies) {
    limitedPartners = limitedPartners.concat(
      generalPartner.limitedPartnerCompanies.map((company) => {
        return {
          label: `${company.name} | company`,
          type: LimitedPartnerType.Company,
          value: company.entityId ? company.entityId : '',
        };
      })
    );
  }
  if (generalPartner?.limitedPartnerPeople) {
    limitedPartners = limitedPartners.concat(
      generalPartner.limitedPartnerPeople.map((person) => {
        return {
          label: `${person.firstName} ${person.lastName} | person`,
          type: LimitedPartnerType.NaturalPerson,
          value: person.entityId ? person.entityId : '',
        };
      })
    );
  }
  if (excludeExisting && fund) {
    const ret = limitedPartners.filter((limitedPartner) => {
      if (
        fund.registry &&
        fund.registry.findIndex((entry) => entry.entityId == limitedPartner.value) >= 0
      ) {
        return false;
      }
      return true;
    });
    return ret;
  }
  return limitedPartners;
};
