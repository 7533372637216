import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm, UseFormReturn, FormProvider, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ApiFund,
  ApiUpsertFundRequest,
  ServiceType,
  UpdateFundRequest,
  CreateFundRequest,
} from '../../api';
import { BreadcrumbLink } from '../../common/Breadcrumbs';
import { MainContent } from '../../common/MainContent';
import { useFund, useUpdateFund, useCreateFund } from '../../hooks/useFund';
import { useGeneralPartner } from '../../hooks/useGeneralPartner';

import * as yup from 'yup';
import { CurrencyTextField, TextField } from '../../common/TextField';
import { TwoColumnRow } from '../../common/FormElements';
import { Select } from '../../common/inputs/Select';
import { Button } from '../../common/Button';
import { useQueryClient } from 'react-query';

export const schema = yup.object({
  name: yup.string().required('Name is a required field!'),
  fundSize: yup.number().positive(),
});

export type FundInfoParams = {
  generalPartnerId: string;
  fundId?: string;
};

export type FundInfoFormParams = Omit<
  ApiUpsertFundRequest,
  'investments' | 'companyRegistry' | 'personRegistry'
>;

const computeCreatePayload = (
  formParams: FundInfoFormParams,
  generalPartnerId: string
): CreateFundRequest => {
  return {
    id: generalPartnerId,
    apiUpsertFundRequest: {
      ...formParams,
      registry: [],
      investments: [],
    },
  };
};

const computeUpdatePayload = (
  formParams: FundInfoFormParams,
  fund?: ApiFund
): UpdateFundRequest | undefined => {
  if (fund && fund.id && fund.registry && fund.investments) {
    const payload: UpdateFundRequest = {
      fundId: fund.id,
      apiUpsertFundRequest: {
        registry: fund.registry,
        investments: fund.investments,
        ...formParams,
      },
    };
    return payload;
  }
  return undefined;
};

export const FundInfoForm = () => {
  const { generalPartnerId, fundId } = useParams<FundInfoParams>();
  if (generalPartnerId === undefined) {
    return <CircularProgress />;
  }
  const { generalPartner, isGeneralPartnerLoading } = useGeneralPartner(generalPartnerId);
  const navigate = useNavigate();

  const { fund } = useFund(generalPartnerId, fundId);
  const useUpdateFundMutation = useUpdateFund();
  const useCreateFundMutation = useCreateFund();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (fund) {
      reset({
        name: fund.name,
        fundSize: fund.fundSize,
        serviceType: fund.serviceType,
        symbol: fund.symbol,
      });
    }
  }, [fund]);
  const formHook: UseFormReturn<FundInfoFormParams, object> = useForm<FundInfoFormParams>({
    mode: 'onBlur',
    defaultValues: {
      serviceType: ServiceType.FundStructure,
    },
    resolver: yupResolver(schema),
  });
  const { trigger, control, getValues, reset } = formHook;
  if (useUpdateFundMutation.isSuccess) {
    navigate(`/general-partners/${generalPartnerId}/funds/${fundId}/info`);
  }

  if (useCreateFundMutation.isSuccess) {
    queryClient.invalidateQueries('general-partner');
    navigate(`/general-partners/${generalPartnerId}/funds`);
  }

  if (generalPartner === undefined || isGeneralPartnerLoading) {
    return <CircularProgress />;
  }

  let links = [
    <BreadcrumbLink key={1} to='..'>
      All companies
    </BreadcrumbLink>,
    <BreadcrumbLink key={2} to={`../${generalPartnerId}/funds`}>
      {generalPartner.name}
    </BreadcrumbLink>,
  ];
  if (fund) {
    links = links.concat([
      <BreadcrumbLink key={3} to={`../${generalPartnerId}/funds/${fundId}/info`}>
        {`${fund.name} dashboard`}
      </BreadcrumbLink>,
      <BreadcrumbLink key={4} to='#'>
        Edit Info
      </BreadcrumbLink>,
    ]);
  } else {
    links = links.concat([
      <BreadcrumbLink key={4} to='#'>
        Add
      </BreadcrumbLink>,
    ]);
  }

  return (
    <MainContent links={links} headingText={fund ? `Edit ${fund.name} info` : 'Add Fund'}>
      <FormProvider {...formHook}>
        <Box
          display='flex'
          flexDirection='column'
          width='780px'
          marginTop='20px'
          rowGap='20px'
          marginLeft='40px'
        >
          <TwoColumnRow>
            <Controller
              control={control}
              name='name'
              render={({ field, fieldState }) => {
                const { ref, ...rest } = field;
                return (
                  <TextField
                    {...rest}
                    sx={{ flex: '1 1 0px' }}
                    inputRef={ref}
                    label='Name'
                    required={true}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name='fundSize'
              render={({ field, fieldState }) => {
                const { ref, ...rest } = field;
                return (
                  <CurrencyTextField
                    {...rest}
                    sx={{ flex: '1 1 0px' }}
                    label='Fund Size'
                    inputRef={ref}
                    required={true}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
          </TwoColumnRow>
          <TwoColumnRow>
            <Controller
              control={control}
              name='symbol'
              render={({ field, fieldState }) => {
                const { ref, ...rest } = field;
                return (
                  <TextField
                    {...rest}
                    sx={{ flex: '1 1 0px' }}
                    inputRef={ref}
                    label='Symbol'
                    required={true}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name='serviceType'
              render={({ field }) => {
                const { ref, value, ...rest } = field;
                const options = [
                  { value: ServiceType.FundStructure, label: 'Fund Structure' },
                  {
                    value: ServiceType.PortfolioManagement,
                    label: 'Fund Structure & Portfolio Management',
                  },
                ];
                return (
                  <Select
                    options={options}
                    {...rest}
                    value={value ? value : options[0].value}
                    inputRef={ref}
                    label='Service Type'
                    required
                  />
                );
              }}
            />
          </TwoColumnRow>
        </Box>
        <Toolbar
          sx={{
            paddingLeft: '0px',
            paddingRight: '0px',
            flexGrow: 1,
            columnGap: '5px',
          }}
        >
          <Box flexGrow={1}></Box>
          <Button href={`/general-partners/${generalPartnerId}/funds/${fundId}/info`}>
            Cancel
          </Button>
          <Button
            variant='text'
            onClick={async () => {
              const result = await trigger();
              if (result) {
                const formParams = getValues();
                if (fundId != undefined) {
                  const payload = computeUpdatePayload(formParams, fund);
                  if (payload) {
                    useUpdateFundMutation.mutate(payload);
                  }
                } else {
                  const payload = computeCreatePayload(formParams, generalPartnerId);
                  if (payload) {
                    console.log(payload);
                    useCreateFundMutation.mutate(payload);
                  }
                }
              }
            }}
          >
            Save
          </Button>
        </Toolbar>
      </FormProvider>
    </MainContent>
  );
};
