import { Box } from '@mui/material/node_modules/@mui/system';
import React, { useEffect } from 'react';
import { Footer } from '../../common/Footer';
import Logo from '../../assets/logo.png';
import { useMachine } from '@xstate/react';
import { onBoardingStateMachine } from './onBoardingStateMachine';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { MainContent } from '../../common/MainContent';
import { ConfirmEmailStep } from './ConfirmEmailStep';
import { VeriffStep } from './VeriffStep';
import { OnboardAmlForm } from './OnboardAmlForm';
import { LimitedPartnerType } from '../../api';
import { LoginStep } from './LoginStep';
import { Heading } from '../../common/Heading';

export const OnBoardingPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [current, send] = useMachine(onBoardingStateMachine);

  console.log(current.context, current.value);
  let content = <></>;
  let showStepper = true;
  if (['init', 'loading'].some(current.matches)) {
    content = <CircularProgress />;
    showStepper = false;
  }
  if (current.matches('preInit')) {
    showStepper = false;
    content = (
      <LoginStep msg={current.context.msg} onNext={(pin) => send({
        type: 'init',
        invitationId: searchParams.get('invitation'),
        pin: pin
      })} />
    );
  } else if (current.matches('confirmEmail')) {
    content = (
      <ConfirmEmailStep
        email={current.context.onBoardProfile.email}
        firstName={current.context.onBoardProfile.firstName}
        lastName={current.context.onBoardProfile.lastName}
        onNext={(confirmedParams) => {
          send({
            type: 'next',
            confirmedParams: confirmedParams,
          });
        }}
      />
    );
  } else if (current.matches('veriff')) {
    content = (
      <VeriffStep
        shufti={current.context.verification}
        profile={current.context.onBoardProfile}
        onNext={() => {
          send({
            type: 'next',
          });
        }}
      />
    );
  } else if (current.matches('aml')) {
    content = (
      <>
        <OnboardAmlForm
          formData={current.context.currentCompanyAml}
          formType={LimitedPartnerType.Company}
          onChange={(data) => {
            send({
              type: 'change',
              amlData: data,
            });
          }}
          onNext={() => {
            send({ type: 'next' });
          }}
        />
      </>
    );
  } else if (current.matches('personAml')) {
    content = (
      <>
        <OnboardAmlForm
          formData={current.context.personAmlForm}
          formType={LimitedPartnerType.NaturalPerson}
          onChange={(data) => {
            send({
              type: 'change',
              personAmlData: data,
            });
          }}
          onNext={() => {
            send({ type: 'next' });
          }}
        />
      </>
    );
  } else if (current.matches('thankyou')) {
    content = <>
      <Box marginTop='25px'>
        <Heading text="Thank you!"></Heading>
      </Box>
    </>;
  }

  return (
    <Box>
      <Box display='flex' flexDirection='row'>
        <Box className='profile-section' display='flex' flexDirection='column'>
          <img
            style={{ maxWidth: '176px', width: 'auto', height: 'auto' }}
            src={Logo}
            alt='Poolside logo'
          />
        </Box>
        <MainContent headingText='Poolside Onboarding'>
          <Box>
            <Box marginLeft='-8px'>
              {showStepper && <Stepper activeStep={current.context.currentStep}>
                {
                  current.context.steps.map(label => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))
                }
              </Stepper>}
            </Box>
            {content}
          </Box>
        </MainContent>
      </Box>
      <Footer />
    </Box>
  );
};
