import { RankedTester, rankWith, uiTypeIs, LayoutProps } from '@jsonforms/core';
import { Hidden, Typography } from '@mui/material';
import React from 'react';
import { withJsonFormsLayoutProps } from '@jsonforms/react';

const styleFirst = {
  marginTop: '16px',
};
const styleLast = {
  marginBotton: '16px',
  paddingBottom: '16px',
};
const styleCommon = {
  color: 'black',
  fontSize: '1rem',
};
export const InfoTextControl = (props: LayoutProps) => {
  const displayText: string = props.uischema.options?.text;
  const texts = [];
  if (displayText) {
    const splits = displayText.split('\n');
    if (splits.length == 1) {
      texts.push(
        <Typography
          sx={{
            ...styleFirst,
            ...styleCommon,
            ...styleLast,
          }}
        >
          {displayText}
        </Typography>
      );
    } else {
      texts.push(
        <Typography
          sx={{
            ...styleFirst,
            ...styleCommon,
          }}
        >
          {splits[0]}
        </Typography>
      );
      for (let i = 1; i < splits.length - 1; i++) {
        texts.push(
          <Typography
            sx={{
              ...styleCommon,
            }}
          >
            {splits[i]}
          </Typography>
        );
      }
      texts.push(
        <Typography
          sx={{
            ...styleCommon,
            ...styleLast,
          }}
        >
          {splits[splits.length - 1]}
        </Typography>
      );
    }
  }
  return <Hidden xsUp={!props.visible}>{texts}</Hidden>;
};

const HeadingTextControl = (props: LayoutProps) => {
  const displayText = props.uischema.options?.text;
  return (
    <Hidden xsUp={!props.visible}>
      <Typography
        sx={{
          fontWeight: 'bold',
          color: 'var(--peacock-blue)',
          fontSize: '1.2rem',
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
          margin: '16px 0px 16px 0px',
          paddingBottom: '18px',
        }}
      >
        {displayText}
      </Typography>
    </Hidden>
  );
};

export const HeadingTextRenderer = withJsonFormsLayoutProps(HeadingTextControl);
export const InfoTextRenderer = withJsonFormsLayoutProps(InfoTextControl);

export const infoTextTester: (type: string) => RankedTester = (type: string) =>
  rankWith(1, uiTypeIs(type));
