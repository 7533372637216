import {
  and,
  ControlProps,
  or,
  RankedTester,
  rankWith,
  schemaMatches,
  uiTypeIs,
} from '@jsonforms/core';
import { Unwrapped } from '@jsonforms/material-renderers';
import { withJsonFormsControlProps } from '@jsonforms/react';
import React from 'react';
import { countryList } from './countries';

const { MaterialEnumControl } = Unwrapped;

export const countryListControl = (props: ControlProps) => {
  const options = countryList.map((c) => {
    return {
      label: c.name,
      value: c.name,
    };
  });
  return <MaterialEnumControl {...props} options={options} />;
};

export default withJsonFormsControlProps(countryListControl);

export const isCountryControl = and(
  uiTypeIs('Control'),
  or(schemaMatches((schema) => 'countryList' in schema))
);

export const countryControlTester: RankedTester = rankWith(3, isCountryControl);
