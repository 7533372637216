/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AmlFormStatus,
    AmlFormStatusFromJSON,
    AmlFormStatusFromJSONTyped,
    AmlFormStatusToJSON,
    ApiAdmin,
    ApiAdminFromJSON,
    ApiAdminFromJSONTyped,
    ApiAdminToJSON,
    ApiFund,
    ApiFundFromJSON,
    ApiFundFromJSONTyped,
    ApiFundToJSON,
    ApiInvestorProfile,
    ApiInvestorProfileFromJSON,
    ApiInvestorProfileFromJSONTyped,
    ApiInvestorProfileToJSON,
    ApiLimitedPartner,
    ApiLimitedPartnerFromJSON,
    ApiLimitedPartnerFromJSONTyped,
    ApiLimitedPartnerToJSON,
    RiskStatus,
    RiskStatusFromJSON,
    RiskStatusFromJSONTyped,
    RiskStatusToJSON,
} from './';

/**
 * Company object
 * @export
 * @interface ApiCompany
 */
export interface ApiCompany {
    /**
     * 
     * @type {string}
     * @memberof ApiCompany
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompany
     */
    entityId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompany
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompany
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompany
     */
    residency?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompany
     */
    taxResidency?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompany
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompany
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCompany
     */
    registryCode?: string;
    /**
     * 
     * @type {AmlFormStatus}
     * @memberof ApiCompany
     */
    amlStatus?: AmlFormStatus;
    /**
     * 
     * @type {RiskStatus}
     * @memberof ApiCompany
     */
    risk?: RiskStatus;
    /**
     * 
     * @type {Array<ApiFund>}
     * @memberof ApiCompany
     */
    funds?: Array<ApiFund>;
    /**
     * 
     * @type {Array<ApiAdmin>}
     * @memberof ApiCompany
     */
    representatives?: Array<ApiAdmin>;
    /**
     * 
     * @type {Array<ApiInvestorProfile>}
     * @memberof ApiCompany
     */
    limitedPartnerPeople?: Array<ApiInvestorProfile>;
    /**
     * 
     * @type {Array<ApiCompany>}
     * @memberof ApiCompany
     */
    limitedPartnerCompanies?: Array<ApiCompany>;
    /**
     * 
     * @type {Array<ApiLimitedPartner>}
     * @memberof ApiCompany
     */
    limitedPartners?: Array<ApiLimitedPartner>;
}

export function ApiCompanyFromJSON(json: any): ApiCompany {
    return ApiCompanyFromJSONTyped(json, false);
}

export function ApiCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCompany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'name': json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'residency': !exists(json, 'residency') ? undefined : json['residency'],
        'taxResidency': !exists(json, 'taxResidency') ? undefined : json['taxResidency'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'bankAccount': !exists(json, 'bankAccount') ? undefined : json['bankAccount'],
        'registryCode': !exists(json, 'registryCode') ? undefined : json['registryCode'],
        'amlStatus': !exists(json, 'amlStatus') ? undefined : AmlFormStatusFromJSON(json['amlStatus']),
        'risk': !exists(json, 'risk') ? undefined : RiskStatusFromJSON(json['risk']),
        'funds': !exists(json, 'funds') ? undefined : ((json['funds'] as Array<any>).map(ApiFundFromJSON)),
        'representatives': !exists(json, 'representatives') ? undefined : ((json['representatives'] as Array<any>).map(ApiAdminFromJSON)),
        'limitedPartnerPeople': !exists(json, 'limitedPartnerPeople') ? undefined : ((json['limitedPartnerPeople'] as Array<any>).map(ApiInvestorProfileFromJSON)),
        'limitedPartnerCompanies': !exists(json, 'limitedPartnerCompanies') ? undefined : ((json['limitedPartnerCompanies'] as Array<any>).map(ApiCompanyFromJSON)),
        'limitedPartners': !exists(json, 'limitedPartners') ? undefined : ((json['limitedPartners'] as Array<any>).map(ApiLimitedPartnerFromJSON)),
    };
}

export function ApiCompanyToJSON(value?: ApiCompany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'entityId': value.entityId,
        'name': value.name,
        'address': value.address,
        'residency': value.residency,
        'taxResidency': value.taxResidency,
        'contactEmail': value.contactEmail,
        'bankAccount': value.bankAccount,
        'registryCode': value.registryCode,
        'amlStatus': AmlFormStatusToJSON(value.amlStatus),
        'risk': RiskStatusToJSON(value.risk),
        'funds': value.funds === undefined ? undefined : ((value.funds as Array<any>).map(ApiFundToJSON)),
        'representatives': value.representatives === undefined ? undefined : ((value.representatives as Array<any>).map(ApiAdminToJSON)),
        'limitedPartnerPeople': value.limitedPartnerPeople === undefined ? undefined : ((value.limitedPartnerPeople as Array<any>).map(ApiInvestorProfileToJSON)),
        'limitedPartnerCompanies': value.limitedPartnerCompanies === undefined ? undefined : ((value.limitedPartnerCompanies as Array<any>).map(ApiCompanyToJSON)),
        'limitedPartners': value.limitedPartners === undefined ? undefined : ((value.limitedPartners as Array<any>).map(ApiLimitedPartnerToJSON)),
    };
}

