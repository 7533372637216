/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EntityType {
    Company = 'COMPANY',
    NaturalPerson = 'NATURAL_PERSON'
}

export function EntityTypeFromJSON(json: any): EntityType {
    return EntityTypeFromJSONTyped(json, false);
}

export function EntityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityType {
    return json as EntityType;
}

export function EntityTypeToJSON(value?: EntityType | null): any {
    return value as any;
}

