import { JsonForms } from '@jsonforms/react';
import { Box, CircularProgress, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AmlFormStatus, ApiAmlForm, LimitedPartnerType } from '../../api';
import { amlSchema } from './dataSchema';
import { poolSideJsonFormRenderers } from '../../common/renderers';
import { materialCells } from '@jsonforms/material-renderers';
import { Button } from '../../common/Button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ownerAmlApi } from '../../apis';
import { UISchemaElement } from '@jsonforms/core';
import { Select } from '../../common/inputs/Select';
import { amlCompanyUISchema, amlPersonUISchema } from './uiSchemas';

export interface Props {
  personId?: string;
  companyId?: string;
  backLink: string;
}

export const AmlHistoryForm = ({ personId, companyId, backLink }: Props) => {
  const queryClient = useQueryClient();
  const [selectedForm, setSelectedForm] = useState<ApiAmlForm | undefined>();

  const personAmlHistoryQ = useQuery(['aml-history', personId], () => {
    if (personId != undefined) {
      return ownerAmlApi.getOwnerAmlFormHistory({
        limitedPartnerId: personId,
        limitedPartnerType: LimitedPartnerType.NaturalPerson,
      });
    }
  });
  const companyAmlHistoryQ = useQuery(['aml-history', companyId], () => {
    if (companyId != undefined) {
      return ownerAmlApi.getOwnerAmlFormHistory({
        limitedPartnerId: companyId,
        limitedPartnerType: LimitedPartnerType.Company,
      });
    }
  });
  if (personAmlHistoryQ.isLoading || companyAmlHistoryQ.isLoading) {
    return <CircularProgress />;
  }
  let entries: Array<{ value: any; label: string }> = [];
  let forms: Array<ApiAmlForm> = [];
  let isCompany = false;
  if (personAmlHistoryQ.data != undefined) {
    forms = personAmlHistoryQ.data;
  } else if (companyAmlHistoryQ.data != undefined) {
    isCompany = true;
    forms = companyAmlHistoryQ.data;
  }
  entries = forms.map((form, idx) => ({
    label: form.createdTime!.toLocaleString(),
    value: idx,
  }));
  console.log(selectedForm);
  return (
    <Box display='flex' flexDirection='column' width='780px' rowGap='20px'>
      <Select
        options={entries}
        onChange={(e) => {
          const selectedIdx = e.target.value as number;
          setSelectedForm(forms[selectedIdx]);
        }}
      />
      {selectedForm && (
        <JsonForms
          schema={amlSchema}
          uischema={isCompany ? amlCompanyUISchema : amlPersonUISchema}
          data={JSON.parse(selectedForm.formData!)}
          renderers={poolSideJsonFormRenderers}
          cells={materialCells}
          readonly={true}
        />
      )}
    </Box>
  );
};
