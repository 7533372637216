import { Box, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Controller, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { Button } from '../../common/Button';
import { OneColumnRow, TwoColumnRow } from '../../common/FormElements';
import { TextField } from '../../common/TextField';

export type ConfirmedParams = {
  confirmedEmail: string;
  confirmedFirstName: string;
  confirmedLastName: string;
};

type Params = {
  email?: string;
  firstName?: string;
  lastName?: string;
  onNext?: (newEmail: ConfirmedParams) => void;
};

export const ConfirmEmailStep = ({ email, firstName, lastName, onNext }: Params) => {
  const formHook: UseFormReturn<ConfirmedParams, object> = useForm<ConfirmedParams>({
    mode: 'onBlur',
    defaultValues: {
      confirmedEmail: email,
      confirmedFirstName: firstName,
      confirmedLastName: lastName,
    },
  });
  const { trigger, control, watch, getValues, reset } = formHook;
  return (
    <FormProvider {...formHook}>
      <Box display='flex' flexDirection='column' width='780px' marginTop='20px' rowGap='20px'>
        <OneColumnRow>
          <Typography>
            Please confirm your personal details. If you wish to change your email address please
            enter a new one.
          </Typography>
        </OneColumnRow>
        <OneColumnRow>
          <Controller
            control={control}
            name='confirmedEmail'
            render={({ field, fieldState }) => {
              const { ref, ...rest } = field;
              return (
                <TextField
                  {...rest}
                  sx={{ flex: '1 1 0px' }}
                  inputRef={ref}
                  label='Email'
                  required={true}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        </OneColumnRow>
        <OneColumnRow>
          <Controller
            control={control}
            name='confirmedFirstName'
            render={({ field, fieldState }) => {
              const { ref, ...rest } = field;
              return (
                <TextField
                  {...rest}
                  sx={{ flex: '1 1 0px' }}
                  inputRef={ref}
                  label='First Name'
                  required={true}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        </OneColumnRow>
        <OneColumnRow>
          <Controller
            control={control}
            name='confirmedLastName'
            render={({ field, fieldState }) => {
              const { ref, ...rest } = field;
              return (
                <TextField
                  {...rest}
                  sx={{ flex: '1 1 0px' }}
                  inputRef={ref}
                  label='Last Name'
                  required={true}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        </OneColumnRow>
        <Toolbar
          sx={{
            paddingLeft: '0px',
            paddingRight: '0px',
            flexGrow: 1,
            columnGap: '5px',
          }}
        >
          <Box flexGrow={1}></Box>
          <Button
            onClick={async () => {
              const result = await trigger();
              if (result) {
                const formParams: ConfirmedParams = getValues();
                if (onNext != undefined) {
                  onNext(formParams);
                }
              }
            }}
          >
            Next
          </Button>
        </Toolbar>
      </Box>
    </FormProvider>
  );
};
