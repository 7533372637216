/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiPersonInvitation
 */
export interface ApiPersonInvitation {
    /**
     * 
     * @type {string}
     * @memberof ApiPersonInvitation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPersonInvitation
     */
    personId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPersonInvitation
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPersonInvitation
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPersonInvitation
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiPersonInvitation
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApiPersonInvitation
     */
    validUntil?: Date;
}

export function ApiPersonInvitationFromJSON(json: any): ApiPersonInvitation {
    return ApiPersonInvitationFromJSONTyped(json, false);
}

export function ApiPersonInvitationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPersonInvitation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'personId': !exists(json, 'personId') ? undefined : json['personId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'validUntil': !exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
    };
}

export function ApiPersonInvitationToJSON(value?: ApiPersonInvitation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'personId': value.personId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'created': value.created === undefined ? undefined : (value.created.toISOString().substr(0,10)),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString().substr(0,10)),
    };
}

