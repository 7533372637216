import React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  children: React.ReactNode;
  label: string;
}

export const DisplayField = ({ children, label }: Props) => (
  <Box>
    <Typography style={{ color: '#afafaf' }}>{label}</Typography>
    <Typography>{children}</Typography>
  </Box>
);
