/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiDocument,
    ApiDocumentFromJSON,
    ApiDocumentToJSON,
} from '../models';

export interface GetInvestorFundDocumentsRequest {
    fundId: string;
}

/**
 * 
 */
export class InvestorFundDocumentApi extends runtime.BaseAPI {

    /**
     * get list of investor(LP) fund documents
     */
    async getInvestorFundDocumentsRaw(requestParameters: GetInvestorFundDocumentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiDocument>>> {
        if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
            throw new runtime.RequiredError('fundId','Required parameter requestParameters.fundId was null or undefined when calling getInvestorFundDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/limited-partner/investments/{fundId}/documents`.replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiDocumentFromJSON));
    }

    /**
     * get list of investor(LP) fund documents
     */
    async getInvestorFundDocuments(requestParameters: GetInvestorFundDocumentsRequest, initOverrides?: RequestInit): Promise<Array<ApiDocument>> {
        const response = await this.getInvestorFundDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
