import React from 'react';
import { ExampleDocumentsPagePlaceholder } from './ExampleDocumentsPagePlaceholder';

export const InvestmentDocumentsPage: React.FC = () => {
  function createData(srNo: number, documentName: string, uploadedAt: string) {
    return { srNo, documentName, uploadedAt };
  }

  const rows = [
    createData(1, 'Document One', '03-12-2021'),
    createData(2, 'Document Two', '15-12-2021'),
    createData(3, 'Document Three', '27-12-2021'),
    createData(4, 'Document Four', '03-01-2022'),
    createData(5, 'Document Five', '11-01-2022'),
  ];

  return (
    <ExampleDocumentsPagePlaceholder
      rows={rows}
      fileName='example_document.pdf'
      title='My investment documents'
    />
  );
};
