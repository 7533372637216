import { useQuery } from 'react-query';
import { ApiLimitedPartnerInvestment } from '../api';
import { fundsApi } from '../apis';

export const useLimitedPartnerInvestments = (): {
  isDashboardLoading: boolean;
  limitedPartnerInvestments: ApiLimitedPartnerInvestment[] | undefined;
} => {
  const profileQuery = useQuery(
    'fundsDashboard',
    async () => {
      return fundsApi.getLimitedPartnerInvestments();
    },
    { retry: 1, refetchOnWindowFocus: false, retryDelay: 5000 }
  );
  return {
    isDashboardLoading: profileQuery.isLoading,
    limitedPartnerInvestments: profileQuery.data,
  };
};
