/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiShuftiResponse,
    ApiShuftiResponseFromJSON,
    ApiShuftiResponseFromJSONTyped,
    ApiShuftiResponseToJSON,
    ShuftiStatus,
    ShuftiStatusFromJSON,
    ShuftiStatusFromJSONTyped,
    ShuftiStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiShuftiStatus
 */
export interface ApiShuftiStatus {
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiStatus
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiStatus
     */
    reference?: string;
    /**
     * 
     * @type {ShuftiStatus}
     * @memberof ApiShuftiStatus
     */
    status?: ShuftiStatus;
    /**
     * 
     * @type {Date}
     * @memberof ApiShuftiStatus
     */
    received?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiStatus
     */
    url?: string;
    /**
     * 
     * @type {ApiShuftiResponse}
     * @memberof ApiShuftiStatus
     */
    payload?: ApiShuftiResponse;
}

export function ApiShuftiStatusFromJSON(json: any): ApiShuftiStatus {
    return ApiShuftiStatusFromJSONTyped(json, false);
}

export function ApiShuftiStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiShuftiStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'status': !exists(json, 'status') ? undefined : ShuftiStatusFromJSON(json['status']),
        'received': !exists(json, 'received') ? undefined : (new Date(json['received'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'payload': !exists(json, 'payload') ? undefined : ApiShuftiResponseFromJSON(json['payload']),
    };
}

export function ApiShuftiStatusToJSON(value?: ApiShuftiStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': value.reference,
        'status': ShuftiStatusToJSON(value.status),
        'received': value.received === undefined ? undefined : (value.received.toISOString()),
        'url': value.url,
        'payload': ApiShuftiResponseToJSON(value.payload),
    };
}

