import React, { useState } from 'react';
import { useLimitedPartnerInvestments } from '../../../hooks/useLimitedPartnerInvestments';
import { Box, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { MainContent } from '../../../common/MainContent';
import { BreadcrumbLink } from '../../../common/Breadcrumbs';
import { GridColumns } from '@mui/x-data-grid';
import { column, DataGrid } from '../../../common/DataGrid';
import { TabPanel, Tabs } from '../../../common/Tabs';
import { InvestmentFundDocuments } from './InvestmentFundDocuments';

type InvestmentBreakdownPageParams = {
  id: string;
  selectedTab: string;
};

const investmentBreakdownColumns: GridColumns = [
  column({
    headerName: 'Name of the investment',
    field: 'name',
    width: 200,
  }),
  column({ headerName: 'Sector', field: 'sector', width: 250 }),
  column({ headerName: 'Instrument', field: 'instrument', width: 150 }),
];

const urlTabMap = new Map<string, string>([
  ['overview', '0'],
  ['documents', '1'],
  ['0', 'overview'],
  ['1', 'documents'],
]);

export const InvestmentBreakdownPage: React.FC = () => {
  const { id, selectedTab } = useParams<InvestmentBreakdownPageParams>();
  const navigate = useNavigate();
  const currentTabName = selectedTab === undefined ? 'info' : selectedTab;
  const currentTabIndex = Number(urlTabMap.get(currentTabName) || '0');
  const [tab, setTab] = useState<number>(currentTabIndex);

  const { limitedPartnerInvestments } = useLimitedPartnerInvestments();

  const investment =
    limitedPartnerInvestments &&
    limitedPartnerInvestments.find((it) => it.id === id);

  return (
    <MainContent
      headingText={investment?.name}
      links={[
        <BreadcrumbLink key={1} to='/limited-partners/dashboard'>
          My investments
        </BreadcrumbLink>,
        <BreadcrumbLink key={2} to='#'>
          {investment?.name}
        </BreadcrumbLink>,
      ]}
    >
      <Tabs
        value={tab}
        tabs={['Fund investments', 'Documents & Reports']}
        panels={(value) => (
          <>
            <TabPanel value={value} index={0}>
              {investment ? (
                <Box paddingX={5}>
                  <DataGrid
                    columns={investmentBreakdownColumns}
                    rows={investment.investmentBreakdown || []}
                  />
                </Box>
              ) : (
                <CircularProgress />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              <InvestmentFundDocuments fundId={id!} />
            </TabPanel>
          </>
        )}
        onChange={(_, index) => {
          navigate(
            `/limited-partners/dashboard/${id}/${
              urlTabMap.get(index.toString()) || 'overview'
            }`
          );
          setTab(index);
        }}
      />
    </MainContent>
  );
};
