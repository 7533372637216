import { useQuery } from 'react-query';
import { companiesApi } from '../apis';

export const useGeneralPartner = (companyId: string) => {
  const { data, isLoading } = useQuery(
    ['general-partner', companyId],
    async () => {
      return companiesApi.getCompany({ companyId });
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  return {
    generalPartner: data,
    isGeneralPartnerLoading: isLoading,
  };
};
