/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiShuftiReferenceRequest,
    ApiShuftiReferenceRequestFromJSON,
    ApiShuftiReferenceRequestToJSON,
    ApiShuftiStatus,
    ApiShuftiStatusFromJSON,
    ApiShuftiStatusToJSON,
    ApiShuftiVerification,
    ApiShuftiVerificationFromJSON,
    ApiShuftiVerificationToJSON,
} from '../models';

export interface GenerateRealtimeShuftiLinkRequest {
    personId: string;
}

export interface GetShuftiStatusRequest {
    personId: string;
}

export interface RefreshShuftiStatusRequest {
    personId: string;
}

export interface SetReferenceRequest {
    apiShuftiReferenceRequest: ApiShuftiReferenceRequest;
}

/**
 * 
 */
export class OwnerShuftiStatusApi extends runtime.BaseAPI {

    /**
     * Generates a RealTime (Video) link for Shufti authentication
     */
    async generateRealtimeShuftiLinkRaw(requestParameters: GenerateRealtimeShuftiLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiShuftiVerification>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling generateRealtimeShuftiLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/shufti-realtime/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiShuftiVerificationFromJSON(jsonValue));
    }

    /**
     * Generates a RealTime (Video) link for Shufti authentication
     */
    async generateRealtimeShuftiLink(requestParameters: GenerateRealtimeShuftiLinkRequest, initOverrides?: RequestInit): Promise<ApiShuftiVerification> {
        const response = await this.generateRealtimeShuftiLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns ShuftiPro status info
     */
    async getShuftiStatusRaw(requestParameters: GetShuftiStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiShuftiStatus>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getShuftiStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/shufti-status/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiShuftiStatusFromJSON(jsonValue));
    }

    /**
     * returns ShuftiPro status info
     */
    async getShuftiStatus(requestParameters: GetShuftiStatusRequest, initOverrides?: RequestInit): Promise<ApiShuftiStatus> {
        const response = await this.getShuftiStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns ShuftiPro status info
     */
    async refreshShuftiStatusRaw(requestParameters: RefreshShuftiStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiShuftiStatus>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling refreshShuftiStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/shufti-status/{personId}/refresh`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiShuftiStatusFromJSON(jsonValue));
    }

    /**
     * returns ShuftiPro status info
     */
    async refreshShuftiStatus(requestParameters: RefreshShuftiStatusRequest, initOverrides?: RequestInit): Promise<ApiShuftiStatus> {
        const response = await this.refreshShuftiStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns ShuftiPro status info
     */
    async setReferenceRaw(requestParameters: SetReferenceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiShuftiStatus>> {
        if (requestParameters.apiShuftiReferenceRequest === null || requestParameters.apiShuftiReferenceRequest === undefined) {
            throw new runtime.RequiredError('apiShuftiReferenceRequest','Required parameter requestParameters.apiShuftiReferenceRequest was null or undefined when calling setReference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/shufti-status/reference`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiShuftiReferenceRequestToJSON(requestParameters.apiShuftiReferenceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiShuftiStatusFromJSON(jsonValue));
    }

    /**
     * returns ShuftiPro status info
     */
    async setReference(requestParameters: SetReferenceRequest, initOverrides?: RequestInit): Promise<ApiShuftiStatus> {
        const response = await this.setReferenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
