export const naturalPersonSchema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      minLength: 3,
      description: 'Please enter your name',
    },
    residency: {
      type: 'string',
      countryList: true,
    },
    taxResidency: {
      type: 'string',
      description: 'Please enter your Tax Residency',
      countryList: true,
    },
    citizenship: {
      type: 'string',
      countryList: true,
    },
    idCode: {
      type: 'string',
      description: 'Please enter your Identification Code / Tax Number ',
    },
    identificationNumber: {
      type: 'string',
      description: 'Please enter your Document Number',
    },
    identificationDocument: {
      type: 'string',
      description: 'Please enter your Identification Document',
    },
    idIssueDate: {
      type: 'string',
      format: 'date',
      description: 'Please enter your Identification Document',
    },
    idIssueCountry: {
      type: 'string',
      countryList: true,
    },
    homeAddress: {
      type: 'string',
      description: 'Please enter your Home Address',
    },
    email: {
      type: 'string',
      description: 'Please enter your Email',
    },
    phone: {
      type: 'string',
      description: 'Please enter your Phone',
    },
    isPep: {
      type: 'string',
      title: 'Are you a politically exposed person?',
      enum: ['yes', 'no'],
    },
    pepInfo: {
      type: 'string',
      title: 'Please explain your PEP status',
    },
    isPepRelated: {
      type: 'boolean',
      title: 'Are you a related to a politically exposed person?',
    },
    sourceOfFunds: {
      type: 'string',
      description: 'Source of funds',
    },
  },
  required: ['name'],
};

export const boardMember = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      minLength: 3,
      description: "Please enter the Board's Member Name",
    },
    citizenship: {
      type: 'string',
      countryList: true,
    },
    identificationCodeOrBirthDate: {
      type: 'string',
      description: "Please enter Board's Member Identification Code or Date of Birth",
    },
    identificationNumber: {
      type: 'string',
      description: "Please enter Board's Member Identification Number",
    },
    identificationDocument: {
      type: 'string',
      description: "Please enter Board's Member Identification Document",
    },
    address: {
      type: 'string',
      description: "Please enter Board's Member Address",
    },
    email: {
      type: 'string',
      description: "Please enter Board's Member Email",
    },
    phone: {
      type: 'string',
      description: "Please enter Board's Member Phone",
    },
  },
};

export const beneficialOwner = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      minLength: 3,
      description: "Please enter the Beneficial Owner's Name",
    },
    identificationCodeOrBirthDate: {
      type: 'string',
      description: "Please enter Beneficial Owner's Identification Code or Date of Birth",
    },
    identificationNumber: {
      type: 'string',
      description: "Please enter Beneficial Owner's Document Number",
    },
    identificationDocument: {
      type: 'string',
      description: "Please enter Beneficial Owner's Identification Document",
    },
    address: {
      type: 'string',
      description: "Please enter Beneficial Owner's Address",
    },
    ownership: {
      type: 'number',
      description: 'Ownership % of the entity (if at least 25%)',
    },
    isPepRelated: {
      type: 'string',
      title: 'PEP or related to PEP?',
      enum: ['yes', 'no'],
    },
    pepInfo: {
      type: 'string',
      title: 'Please explain the PEP status',
    },
  },
};

export const companyEntitySchema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      minLength: 3,
      description: 'Please enter the Entity Name',
    },
    country: {
      type: 'string',
      countryList: true,
    },
    registrationCode: {
      type: 'string',
      minLength: 3,
      description: 'Please enter the Entity Registration Code',
    },
    registrationDate: {
      type: 'string',
      format: 'date',
      description: 'Please enter the Entity Registration Date',
    },
    registrationAddress: {
      type: 'string',
      description: 'Please enter the Entity Registration Address',
    },
    email: {
      type: 'string',
      description: "Please enter the Entity' Email",
    },
    phone: {
      type: 'string',
      description: "Please enter the Entity' Phone",
    },
    basisOfRepresentation: {
      type: 'string',
      enum: ['Board member', 'Under a Power of Attorney'],
    },
    isFinancialInstitution: {
      type: 'boolean',
      title: 'Is the entity a financial institution?',
    },
    mainActivityField: {
      type: 'string',
    },
    soleProprietorship: {
      type: 'boolean',
      title: 'The company is a sole-properietorship company.',
    },
    boardMembers: {
      type: 'array',
      items: boardMember,
    },
    hasNot25Owners: {
      type: 'boolean',
      title:
        'The beneficial owners of a legal person do not include natural persons who directly or indirectly own or control more than 25% of the shares, voting rights or voting rights of the legal person or who otherwise control the management of the legal person.',
    },
    beneficialOwners: {
      type: 'array',
      items: beneficialOwner,
    },
  },
};

export const amlSchema = {
  type: 'object',
  properties: {
    person: naturalPersonSchema,
    isRepresentative: {
      type: 'string',
      title: 'Are you a Company Representative?',
      enum: ['yes', 'no'],
    },
    company: companyEntitySchema,
  },
};
