/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiInvestorProfile,
    ApiInvestorProfileFromJSON,
    ApiInvestorProfileToJSON,
} from '../models';

export interface GetInvestorProfileByIdRequest {
    limitedPartnerId: string;
}

/**
 * 
 */
export class LimitedPartnerPlatformApi extends runtime.BaseAPI {

    /**
     * returns the InvestorProfile represented by {limitedPartnerId}
     */
    async getInvestorProfileByIdRaw(requestParameters: GetInvestorProfileByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiInvestorProfile>> {
        if (requestParameters.limitedPartnerId === null || requestParameters.limitedPartnerId === undefined) {
            throw new runtime.RequiredError('limitedPartnerId','Required parameter requestParameters.limitedPartnerId was null or undefined when calling getInvestorProfileById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/limited-partners/{limitedPartnerId}`.replace(`{${"limitedPartnerId"}}`, encodeURIComponent(String(requestParameters.limitedPartnerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInvestorProfileFromJSON(jsonValue));
    }

    /**
     * returns the InvestorProfile represented by {limitedPartnerId}
     */
    async getInvestorProfileById(requestParameters: GetInvestorProfileByIdRequest, initOverrides?: RequestInit): Promise<ApiInvestorProfile> {
        const response = await this.getInvestorProfileByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
