/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiDocumentShareScope,
    ApiDocumentShareScopeFromJSON,
    ApiDocumentShareScopeFromJSONTyped,
    ApiDocumentShareScopeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiDocumentShareEntity
 */
export interface ApiDocumentShareEntity {
    /**
     * 
     * @type {ApiDocumentShareScope}
     * @memberof ApiDocumentShareEntity
     */
    scope?: ApiDocumentShareScope;
    /**
     * 
     * @type {string}
     * @memberof ApiDocumentShareEntity
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDocumentShareEntity
     */
    id?: string;
}

export function ApiDocumentShareEntityFromJSON(json: any): ApiDocumentShareEntity {
    return ApiDocumentShareEntityFromJSONTyped(json, false);
}

export function ApiDocumentShareEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDocumentShareEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scope': !exists(json, 'scope') ? undefined : ApiDocumentShareScopeFromJSON(json['scope']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ApiDocumentShareEntityToJSON(value?: ApiDocumentShareEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scope': ApiDocumentShareScopeToJSON(value.scope),
        'name': value.name,
        'id': value.id,
    };
}

