import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDayjs';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TwoColumnRow, OneFullColumnRow } from '../../common/FormElements';
import { CurrencyTextField, TextField } from '../../common/TextField';
import { RegistryEntry } from './RegistryEntryForm';
import * as yup from 'yup';
import { CountrySelect } from '../../common/inputs/Select';

export const schema = yup.object({
  totalCommitment: yup.number().positive().required('Total Commitment is a required field!'),
  paidInCapital: yup.number().positive().required('Paid in Capital is a required field!'),
  agreementSigningDate: yup
    .date()
    .required('Agreement Signing Date is a required field!')
    .typeError('Invalid Date'),
  firstClosingDate: yup
    .date()
    .required('First Closing Date is a required field!')
    .typeError('Invalid Date'),
});

export const PersonRegistryEntryForm = () => {
  const formHook = useFormContext<RegistryEntry>();
  const { control, formState } = formHook;
  return (
    <>
      <TwoColumnRow>
        <Controller
          control={control}
          name='registry.totalCommitment'
          render={({ field }) => {
            const { ref, ...rest } = field;
            return (
              <CurrencyTextField
                {...rest}
                sx={{ flex: '1 1 0px' }}
                label='Total Commitment'
                inputRef={ref}
                required={true}
                error={formState.errors.registry?.totalCommitment != undefined}
                helperText={formState.errors.registry?.totalCommitment?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name='registry.paidInCapital'
          render={({ field }) => {
            const { ref, ...rest } = field;
            return (
              <CurrencyTextField
                {...rest}
                sx={{ flex: '1 1 0px' }}
                label='PaidIn Capital'
                inputRef={ref}
                required={true}
                error={formState.errors.registry?.paidInCapital != undefined}
                helperText={formState.errors.registry?.paidInCapital?.message}
              />
            );
          }}
        />
      </TwoColumnRow>
      <TwoColumnRow>
        <Controller
          control={control}
          name='registry.agreementSigningDate'
          render={({ field, fieldState }) => {
            const { ref, onBlur, value, ...rest } = field;
            return (
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  label='Agreement Signing Date'
                  {...rest}
                  value={value ? value : null}
                  inputRef={ref}
                  renderInput={(params) => (
                    <TextField
                      sx={{ flex: '1 1 0px' }}
                      {...params}
                      onBlur={onBlur}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            );
          }}
        />
        <Controller
          control={control}
          name='registry.firstClosingDate'
          render={({ field, fieldState }) => {
            const { ref, onBlur, value, ...rest } = field;
            return (
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  {...rest}
                  label='First Closing Date'
                  value={value ? value : null}
                  inputRef={ref}
                  renderInput={(params) => (
                    <TextField
                      sx={{ flex: '1 1 0px' }}
                      {...params}
                      onBlur={onBlur}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            );
          }}
        />
      </TwoColumnRow>
    </>
  );
};
