import { QueryClient } from 'react-query';

export const listener = async (
  data: { payload: { event: any } },
  queryClient: QueryClient
): Promise<void> => {
  if (
    data.payload.event === 'signOut' ||
    data.payload.event === 'signIn' ||
    data.payload.event === 'signUp'
  ) {
    await queryClient.invalidateQueries();
  }
};
