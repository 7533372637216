import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { ownerShuftiApi, queryClient } from '../../apis';
import { DisplayField } from '../DisplayField';

type Params = {
  id?: string;
  personId: string;
  reference?: string;
}

export const ShuftiRef = ({ id, personId, reference }: Params) => {

  const [editMode, setEditMode] = useState(false);
  const [ref, setRef] = useState(reference);
  const updateStatus = useMutation(["set-shufti-ref", personId], async () => {
    return ownerShuftiApi.setReference({
      apiShuftiReferenceRequest: {
        id: id,
        reference: ref,
        personId: personId
      }
    });
  },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shufti-status', personId]);
        setEditMode(false)
      }
    });

  return (
    <Box width='500px' sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {editMode ?
        <>
          <TextField sx={{ flexDirection: 'column', flexGrow: 1 }} label="Reference" defaultValue={ref} onChange={(e) => setRef(e.target.value)} />
          <Button onClick={() => {
            updateStatus.mutate();
          }}>Save</Button>
          <Button onClick={() => setEditMode(false)}>Cancel</Button>
        </>
        :
        <>
          <DisplayField label='Reference'>{reference}</DisplayField>
          <Button onClick={() => setEditMode(true)}>Edit</Button>
        </>
      }
    </Box>
  )
}
