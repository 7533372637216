import { useMutation, useQuery } from 'react-query';
import {
  DisablePersonsRequest,
  RegisterCompanyRequest,
  SendEmailRequest,
  UpdateCompanyRequest,
} from '../../api';
import { companiesApi, ownerApi } from '../../apis';

export const useCompanyInfo = (companyId?: string) => {
  return useQuery('company', async () => {
    if (companyId) {
      return companiesApi.getCompany({ companyId: companyId });
    } else {
      return undefined;
    }
  });
};

export const useAddGPCompnay = (onSuccess?: () => void) => {
  return useMutation(
    'company',
    async (reqParams: RegisterCompanyRequest) => {
      return ownerApi.registerCompany(reqParams);
    },
    { onSuccess: onSuccess }
  );
};

export const useUpdateCompanies = (limitedPartnerId: string, onSuccess?: () => void) => {
  return useMutation(
    'company' + limitedPartnerId,
    async (reqParams: UpdateCompanyRequest) => {
      return companiesApi.updateCompany(reqParams);
    },
    { onSuccess: onSuccess }
  );
};

export const useSendEmails = (onSuccess?: () => void) => {
  return useMutation(
    async (reqParams: SendEmailRequest) => {
      return ownerApi.sendEmail(reqParams);
    },
    { onSuccess: onSuccess }
  );
};

export const useDisablePersons = (onSuccess?: () => void) => {
  return useMutation(
    async (reqParams: DisablePersonsRequest) => {
      return ownerApi.disablePersons(reqParams);
    },
    { onSuccess: onSuccess }
  );
};
