/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiCreateInvitation
 */
export interface ApiCreateInvitation {
    /**
     * 
     * @type {string}
     * @memberof ApiCreateInvitation
     */
    personId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateInvitation
     */
    pin?: string;
}

export function ApiCreateInvitationFromJSON(json: any): ApiCreateInvitation {
    return ApiCreateInvitationFromJSONTyped(json, false);
}

export function ApiCreateInvitationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCreateInvitation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personId': !exists(json, 'personId') ? undefined : json['personId'],
        'pin': !exists(json, 'pin') ? undefined : json['pin'],
    };
}

export function ApiCreateInvitationToJSON(value?: ApiCreateInvitation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personId': value.personId,
        'pin': value.pin,
    };
}

