import {
  GridSortDirection,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid/models/gridSortModel';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { createSearchParams } from 'react-router-dom';

export function getSortParam(sortItem: GridSortItem): string {
  return sortItem.sort ? sortItem.field + ',' + sortItem.sort : sortItem.field;
}

function getSortField(sortParamParts: Array<string>): string {
  return sortParamParts[0];
}

function getSortDir(sortParamParts: Array<string>): GridSortDirection {
  if (sortParamParts.length > 1) {
    const direction = sortParamParts[1];
    if (direction == 'asc' || direction == 'desc') {
      return direction as GridSortDirection;
    }
  }
  return null;
}

export function getInitialState(searchParams: URLSearchParams): GridInitialStatePro {
  let initialState: GridInitialStatePro = {};

  const sortParamsAll = searchParams.getAll('sort');
  if (sortParamsAll !== undefined) {
    const gridSortModel: GridSortModel = sortParamsAll.map(function (val, index) {
      const sortParamParts = val?.split(',');
      const sortField: string = getSortField(sortParamParts);
      const sortDir: GridSortDirection = getSortDir(sortParamParts);
      return { field: sortField, sort: sortDir };
    });

    const pageSize: number = Number(searchParams.get('pageSize')) || 10;
    const page: number = Number(searchParams.get('page')) || 0;

    const paginationModel = {
      pageSize: pageSize,
      page: page,
    };

    initialState = {
      sorting: {
        sortModel: gridSortModel,
      },
      pagination: paginationModel,
    };
  }
  return initialState;
}

export function handleSortingAndPagingParams(apiRef: any, setSearchParams: any) {
  apiRef.current.subscribeEvent('pageChange', (page: number) => {
    const newParams = createSearchParams(new URLSearchParams(window.location.search));
    newParams.set('page', String(page));
    setSearchParams(newParams);
  });

  apiRef.current.subscribeEvent('pageSizeChange', (pageSize: number) => {
    const newParams = createSearchParams(new URLSearchParams(window.location.search));
    newParams.set('pageSize', String(pageSize));
    setSearchParams(newParams);
  });

  apiRef.current.subscribeEvent('sortModelChange', (sortModel: GridSortModel) => {
    const params = new URLSearchParams(window.location.search);

    if (sortModel.length) {
      const sortParams = sortModel.map(function (val) {
        return getSortParam(val);
      });
      // append sort params
      const newParams = createSearchParams(params);
      newParams.delete('sort');
      for (let i = 0; i < sortParams.length; i++) {
        newParams.append('sort', sortParams[i]);
      }
      setSearchParams(newParams);
    } else {
      const newParams = createSearchParams(params);
      newParams.delete('sort');
      setSearchParams(newParams);
    }
  });
}
