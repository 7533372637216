/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShuftiStatus,
    ShuftiStatusFromJSON,
    ShuftiStatusFromJSONTyped,
    ShuftiStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiShuftiVerification
 */
export interface ApiShuftiVerification {
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiVerification
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiVerification
     */
    url?: string;
    /**
     * 
     * @type {ShuftiStatus}
     * @memberof ApiShuftiVerification
     */
    status?: ShuftiStatus;
}

export function ApiShuftiVerificationFromJSON(json: any): ApiShuftiVerification {
    return ApiShuftiVerificationFromJSONTyped(json, false);
}

export function ApiShuftiVerificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiShuftiVerification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'status': !exists(json, 'status') ? undefined : ShuftiStatusFromJSON(json['status']),
    };
}

export function ApiShuftiVerificationToJSON(value?: ApiShuftiVerification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reference': value.reference,
        'url': value.url,
        'status': ShuftiStatusToJSON(value.status),
    };
}

