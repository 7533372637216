import { Box, TextField, Toolbar, Typography } from '@mui/material';
import { color } from '@mui/material/node_modules/@mui/system';
import React from 'react';
import { Controller, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { Button } from '../../common/Button';
import { OneColumnRow } from '../../common/FormElements';


export type LoginParams = {
    pin: string;
};

type Params = {
    msg?: string;
    onNext?: (pin: string) => void;
};

export const LoginStep = ({ msg, onNext }: Params) => {
    const formHook: UseFormReturn<LoginParams, object> = useForm<LoginParams>();
    const { trigger, control, watch, getValues, reset } = formHook;
    return (
        <FormProvider {...formHook}>
            <Box display='flex' flexDirection='column' width='780px' marginTop='20px' rowGap='20px'>
                <OneColumnRow>
                    <Typography>
                        Please enter the Invitation PIN!
                    </Typography>
                </OneColumnRow>
                {msg && <OneColumnRow>
                    <Typography sx={{color:'red'}}>
                        {msg}
                    </Typography>
                </OneColumnRow>}
                <OneColumnRow>
                    <Controller
                        control={control}
                        name='pin'
                        render={({ field, fieldState }) => {
                            const { ref, ...rest } = field;
                            return (
                                <TextField
                                    {...rest}
                                    sx={{ flex: '1 1 0px' }}
                                    inputRef={ref}
                                    label='PIN'
                                    required={true}
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message}
                                />
                            );
                        }}
                    />
                </OneColumnRow>
                <Toolbar
                    sx={{
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        flexGrow: 1,
                        columnGap: '5px',
                    }}>
                    <Box flexGrow={1}></Box>
                    <Button
                        onClick={async () => {
                            const result = await trigger();
                            if (result) {
                                const formParams: LoginParams = getValues();
                                if (onNext != undefined) {
                                    onNext(formParams.pin);
                                }
                            }
                        }}>
                        Next
                    </Button>
                </Toolbar>
            </Box>
        </FormProvider>

    );
}
