/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AmlFormStatus,
    AmlFormStatusFromJSON,
    AmlFormStatusFromJSONTyped,
    AmlFormStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiAmlInfo
 */
export interface ApiAmlInfo {
    /**
     * 
     * @type {AmlFormStatus}
     * @memberof ApiAmlInfo
     */
    amlStatus?: AmlFormStatus;
    /**
     * 
     * @type {Date}
     * @memberof ApiAmlInfo
     */
    lastAmlEvent?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApiAmlInfo
     */
    lastAmlUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAmlInfo
     */
    lastAmlUser?: string;
}

export function ApiAmlInfoFromJSON(json: any): ApiAmlInfo {
    return ApiAmlInfoFromJSONTyped(json, false);
}

export function ApiAmlInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAmlInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amlStatus': !exists(json, 'amlStatus') ? undefined : AmlFormStatusFromJSON(json['amlStatus']),
        'lastAmlEvent': !exists(json, 'lastAmlEvent') ? undefined : (new Date(json['lastAmlEvent'])),
        'lastAmlUserId': !exists(json, 'lastAmlUserId') ? undefined : json['lastAmlUserId'],
        'lastAmlUser': !exists(json, 'lastAmlUser') ? undefined : json['lastAmlUser'],
    };
}

export function ApiAmlInfoToJSON(value?: ApiAmlInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amlStatus': AmlFormStatusToJSON(value.amlStatus),
        'lastAmlEvent': value.lastAmlEvent === undefined ? undefined : (value.lastAmlEvent.toISOString().substr(0,10)),
        'lastAmlUserId': value.lastAmlUserId,
        'lastAmlUser': value.lastAmlUser,
    };
}

