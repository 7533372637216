import { assign, createMachine } from 'xstate';
import {
  AddLimitedPartnerCompaniesRequest,
  AddLimitedPartnerPeopleRequest,
  AmlFormStatus,
  ApiCompany,
  ApiCreateAdminRequest,
  LimitedPartnerType,
  RiskStatus,
  UpdateCompanyRequest,
  UpdateLimitedPartnerPersonRequest,
} from '../../api';
import { companiesApi, limitedPartnersApi, queryClient } from '../../apis';
import { LimitedPartnerFormData, LPFormParams } from './LimitedPartnerFormWithStateMachine';

export interface LimitedPartnerFormContext {
  formData: LimitedPartnerFormData;
  generalPartner: ApiCompany;
  formParams: LPFormParams;
}

export const limitedPartnerFormStateMachine = createMachine<LimitedPartnerFormContext, any>({
  id: 'lp-form',
  initial: 'preInit',
  on: {
    amlFormUpdate: {
      actions: assign({ formData: (_context, event) => event.formData }),
    },
  },
  states: {
    preInit: {
      on: {
        init: {
          target: 'loading',
          actions: assign({
            formParams: (_context, event) => event.formParams,
            formData: (_context, _event) => {
              return {
                lpTypeSelect: LimitedPartnerType.NaturalPerson,
                risk: RiskStatus.High,
                person: {
                  email: '',
                  firstName: '',
                  lastName: '',
                  residency: '',
                  amlStatus: AmlFormStatus.InProgress,
                },
                company: {
                  risk: RiskStatus.High,
                  companyData: {
                    address: '',
                    name: '',
                    registryCode: '',
                  },
                  representatives: [],
                },
              };
            },
          }),
        },
      },
    },
    //Loading
    loading: {
      type: 'parallel',
      onDone: {
        target: 'loaded',
      },
      states: {
        //Fetch the GP
        loadGP: {
          type: 'compound',
          initial: 'init',
          states: {
            init: {
              invoke: [
                {
                  id: 'loadGP',
                  src: (context, _event) => {
                    return queryClient.fetchQuery(['company'], () => {
                      return companiesApi.getCompany({
                        companyId: context.formParams.generalPartnerId,
                      });
                    });
                  },
                  onDone: {
                    target: 'success',
                    actions: assign({ generalPartner: (_context, event) => event.data }),
                  },
                },
              ],
            },
            success: {
              type: 'final',
            },
          },
        },

        //Fetch LP Company
        loadLPCompany: {
          type: 'compound',
          initial: 'init',
          states: {
            init: {
              always: [
                {
                  target: 'fetch',
                  cond: (context, _event) =>
                    context.formParams.limitedPartnerType == LimitedPartnerType.Company,
                },
                {
                  target: 'success',
                },
              ],
            },
            fetch: {
              invoke: [
                {
                  id: 'loadLPCompany',
                  src: (context, _event) => {
                    const limitedPartnerId = context.formParams.limitedPartnerId;
                    return queryClient.fetchQuery(
                      ['limited-partner-company', limitedPartnerId],
                      () => {
                        return companiesApi
                          .getCompany({ companyId: limitedPartnerId })
                          .then((apiCompany) => {
                            let reps: Array<ApiCreateAdminRequest> = [];
                            if (apiCompany.representatives !== undefined) {
                              reps = apiCompany.representatives.map((rep) => {
                                return { adminInfo: rep, company: apiCompany.id };
                              });
                            }
                            return {
                              lpTypeSelect: LimitedPartnerType.Company,
                              risk: apiCompany.risk,
                              company: {
                                companyData: {
                                  address: apiCompany.address,
                                  name: apiCompany.name,
                                  residency: apiCompany.residency,
                                  taxResidency: apiCompany.taxResidency,
                                  registryCode: apiCompany.registryCode,
                                  contactEmail: apiCompany.contactEmail,
                                  bankAccount: apiCompany.bankAccount,
                                  id: limitedPartnerId,
                                },
                                representatives: reps,
                              },
                            };
                          });
                      }
                    );
                  },
                  onDone: {
                    target: 'success',
                    actions: assign({ formData: (_context, event) => event.data }),
                  },
                },
              ],
            },
            success: {
              type: 'final',
            },
          },
        },

        // Fetch Natural Person
        loadLPNaturalPerson: {
          type: 'compound',
          initial: 'init',
          states: {
            init: {
              always: [
                {
                  target: 'fetch',
                  cond: (context, _event) =>
                    context.formParams.limitedPartnerType == LimitedPartnerType.NaturalPerson,
                },
                {
                  target: 'success',
                },
              ],
            },
            fetch: {
              invoke: [
                {
                  id: 'loadLPNaturalPerson',
                  src: (context, _event) => {
                    const limitedPartnerId = context.formParams.limitedPartnerId;
                    return queryClient.fetchQuery(
                      ['limited-partner-people', limitedPartnerId],
                      () => {
                        return limitedPartnersApi
                          .getLimitedPartnerPerson({ personId: limitedPartnerId })
                          .then((person) => {
                            return {
                              lpTypeSelect: LimitedPartnerType.NaturalPerson,
                              risk: person.risk,
                              person: person,
                            };
                          });
                      }
                    );
                  },
                  onDone: {
                    target: 'success',
                    actions: assign({ formData: (_context, event) => event.data }),
                  },
                },
              ],
            },
            success: {
              type: 'final',
            },
          },
        },
      },
    },
    loaded: {
      entry: (context, _event) => {
        console.log('Loaded', context);
      },
      on: {
        save: {
          target: 'saving',
        },
      },
    },
    saving: {
      always: [
        {
          target: 'updateNaturalPerson',
          cond: (context, _event) =>
            context.formParams.limitedPartnerType == LimitedPartnerType.NaturalPerson &&
            context.formParams.limitedPartnerId != undefined,
        },
        {
          target: 'updateCompany',
          cond: (context, _event) =>
            context.formParams.limitedPartnerType == LimitedPartnerType.Company &&
            context.formParams.limitedPartnerId != undefined,
        },
        {
          target: 'addNaturalPerson',
          cond: (context, _event) =>
            context.formData.lpTypeSelect == LimitedPartnerType.NaturalPerson,
        },
        {
          target: 'addCompany',
          cond: (context, _event) => context.formData.lpTypeSelect == LimitedPartnerType.Company,
        },
        {
          target: 'finish',
        },
      ],
    },
    updateNaturalPerson: {
      invoke: [
        {
          id: 'saveNaturalPerson',
          src: (context, _event) => {
            const lpPeople: UpdateLimitedPartnerPersonRequest = {
              personId: context.formParams.limitedPartnerId,
              apiUpdateLimitedPartnerPersonRequest: {...context.formData.person, risk: context.formData.risk},
            };
            return limitedPartnersApi.updateLimitedPartnerPerson(lpPeople);
          },
          onDone: {
            target: 'finish',
          },
        },
      ],
    },
    addNaturalPerson: {
      invoke: [
        {
          id: 'addNaturalPerson',
          src: (context, _event) => {
            const lpPeople: AddLimitedPartnerPeopleRequest = {
              companyId: context.formParams.generalPartnerId,
              apiUpdateLimitedPartnerPersonRequest: [{...context.formData.person, risk: context.formData.risk}],
            };
            return limitedPartnersApi.addLimitedPartnerPeople(lpPeople);
          },
          onDone: {
            target: 'finish',
          },
        },
      ],
    },
    updateCompany: {
      invoke: [
        {
          id: 'saveCompany',
          src: (context, _event) => {
            const lpCompany: UpdateCompanyRequest = {
              companyId: context.formParams.limitedPartnerId,
              apiUpdateCompanyRequest: {
                risk: context.formData.risk,
                companyData: context.formData.company.companyData,
                representatives: context.formData.company.representatives,
              },
            };
            return companiesApi.updateCompany(lpCompany);
          },
          onDone: {
            target: 'finish',
          },
        },
      ],
    },
    addCompany: {
      invoke: [
        {
          id: 'saveCompany',
          src: (context, _event) => {
            const lpCompany: AddLimitedPartnerCompaniesRequest = {
              companyId: context.formParams.generalPartnerId,
              apiUpdateCompanyRequest: [
                {
                  risk: context.formData.risk,
                  companyData: context.formData.company.companyData,
                  representatives: context.formData.company.representatives,
                },
              ],
            };
            return limitedPartnersApi.addLimitedPartnerCompanies(lpCompany);
          },
          onDone: {
            target: 'finish',
          },
        },
      ],
    },
    finish: {
      entry: (context, _event) => {
        console.log('Finish', context);
      },
    },
  },
});
