import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
  to: string;
  clickHandler?: () => void;
}

export const NavigationLink: React.FC<Props> = ({
  children,
  to,
  clickHandler,
}: Props) => {
  return (
    <NavLink
      to={to}
      onClick={clickHandler}
      style={({ isActive }) => ({
        fontFamily: 'Roboto',
        color: 'white',
        textDecoration: 'none',
        opacity: isActive ? 1 : 0.7,
      })}
    >
      {children}
    </NavLink>
  );
};
