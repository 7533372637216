import React, { useEffect } from 'react';
// @ts-ignore
import { Veriff } from '@veriff/js-sdk';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { isDemo } from '../../util/environment';
import { isStage } from '../../util/environment';

type Params = {
  email?: string;
  givenName?: string;
  lastName?: string;
  onFinish: () => void;
};

export const VerificationForm = ({ email, givenName, lastName, onFinish }: Params) => {
  const navigate = useNavigate();
  let veriffEmail: string | undefined;
  if (email != undefined) {
    veriffEmail = email;
  } else {
    const { user } = useAuthenticator();
    veriffEmail = user.username;
  }

  let veriffApiKey = 'd82403dc-3a73-43ec-8247-ae8bde33ab90'; // production
  if (isStage) {
    veriffApiKey = 'fb9f0ca1-61ec-4488-8b5a-73d4f872ecaa';
  } else if (isDemo) {
    veriffApiKey = '5f1c27ec-1bfa-48e5-bf2c-ce9f39c3890c';
  }

  const submitBtnText = isDemo ? 'Get verified (demo)' : 'Get verified';

  useEffect(() => {
    const veriff: any = Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey: veriffApiKey,
      parentId: 'veriff-root',
      onSession(err: any, response: { verification: { url: any } }) {
        createVeriffFrame({
          url: response.verification.url,
          onEvent: (msg: MESSAGES) => {
            console.log('Veriff Event:', msg);
            if (msg === MESSAGES.FINISHED) {
              onFinish();
              // navigate('/limited-partners/dashboard');
            }
          },
        });
      },
    });
    let veriffParams;
    if (givenName != undefined) {
      veriffParams = {
        person: {
          givenName: givenName,
          lastName: lastName,
        },
        vendorData: veriffEmail,
      };
    } else {
      veriffParams = {
        vendorData: veriffEmail,
      };
    }
    veriff.setParams(veriffParams);
    veriff.mount({
      formLabel: {
        givenName: 'First name',
        lastName: 'Last name',
        vendorData: 'Email',
      },
      submitBtnText,
      loadingText: 'Please wait...',
    });
  }, []);

  return <div id='veriff-root' />;
};
