import { column, customColumn, DataGrid } from '../../common/DataGrid';
import { Box, CircularProgress } from '@mui/material';
import { Button } from '../../common/Button';
import React, { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import {
  getFundDocumentDownloadLink,
  useFundDocuments,
} from '../../hooks/useDocuments';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import { deleteFundDocument } from './fundDocumentActions';
import { useQueryClient } from 'react-query';
import { useAlert } from 'react-alert';
import { ApiFundDocument } from '../../api/models';
import { DateTime, SystemZone } from 'luxon';

export interface Props {
  fundId: string;
}

export const FundDocuments: React.FC<Props> = ({ fundId }: Props) => {
  const documentsTableColumns: GridColDef[] = [
    column({
      field: 'filename',
      headerName: 'File name',
      flex: 60,
    }),
    column({
      field: 'type',
      headerName: 'Type',
      flex: 10,
    }),
    column({
      field: 'scope',
      headerName: 'Scope',
      flex: 10,
    }),
    column({
      field: 'owner',
      headerName: 'Owner',
      flex: 15,
    }),
    column({
      field: 'uploadedTime',
      headerName: 'Uploaded time',
      flex: 15,
    }),
  ];

  const downloadClickHandler = async (docId: string, htmlElement: any) => {
    htmlElement.disabled = true;
    try {
      const documentLink = await getFundDocumentDownloadLink(fundId, docId);
      window.location.href = documentLink.link;
    } catch (e: any) {
      alert.show('Error: ' + (e.statusText || ''), { type: 'error' });
    } finally {
      htmlElement.disabled = false;
    }
  };

  const queryClient = useQueryClient();
  const alert = useAlert();

  const { fundDocuments, isLoading } = useFundDocuments(fundId);

  const deleteClickHandler = async (documentId: string) => {
    showConfirmationDialog(
      <ConfirmationDialog
        message='Delete this file?'
        onConfirm={async () => {
          try {
            await deleteFundDocument(fundId, documentId);
            queryClient.invalidateQueries('fund-documents');
          } catch (e: any) {
            alert.show('Error: ' + (e.statusText || ''), { type: 'error' });
          } finally {
            showConfirmationDialog(<></>);
          }
        }}
        onCancel={() => showConfirmationDialog(<></>)}
        open={true}
      />
    );
  };

  const [confirmationDialog, showConfirmationDialog] = useState(<></>);

  if (isLoading) return <CircularProgress />;

  const formatDocumentRow = (fundDocuments: Array<ApiFundDocument>) => {
    return fundDocuments.map((row: ApiFundDocument) => {
      return {
        id: row.document.id,
        filename: row.document.filename,
        type: row.document.type,
        scope: row.shareEntity?.scope,
        owner: row.shareEntity?.name,
        uploadedTime: DateTime.fromMillis(row.document.uploadTimeMs, {
          zone: SystemZone.instance,
        }).toFormat('yyyy/MM/dd HH:mm'),
      };
    });
  };

  return (
    <>
      <DataGrid
        columns={documentsTableColumns.concat([
          customColumn({
            minWidth: 240,
            renderCell: (params) => {
              return (
                <Box>
                  <Button
                    variant='TertiaryAction'
                    onClick={(event) =>
                      downloadClickHandler(params.row.id, event.target)
                    }
                  >
                    Download
                  </Button>
                  <Button
                    sx={{ margin: '0 10px' }}
                    variant='TertiaryAction'
                    onClick={() => deleteClickHandler(params.row.id)}
                  >
                    Delete
                  </Button>
                </Box>
              );
            },
          }),
        ])}
        rows={(fundDocuments && formatDocumentRow(fundDocuments)) || []}
      />
      {confirmationDialog}
    </>
  );
};
