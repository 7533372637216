const boardMemberUISchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          label: 'Name',
          scope: '#/properties/name',
        },
        {
          type: 'Control',
          label: 'Citizenship',
          scope: '#/properties/citizenship',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          label: 'Identification code / Date of birth / Tax Number',
          scope: '#/properties/identificationCodeOrBirthDate',
        },
        {
          type: 'InfoText',
          options: {
            text: ' ',
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'InfoText',
          options: {
            text: 'Unique Identification Code / Citizen Number / Tax Identification Number.',
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          label: 'Identification Document Type',
          scope: '#/properties/identificationDocument',
          options: {
            options: ['Passport', 'ID Card'],
            freeSolo: true,
          },
        },
        {
          type: 'Control',
          label: 'Identification Document Number',
          scope: '#/properties/identificationNumber',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          label: 'Email',
          scope: '#/properties/email',
        },
        {
          type: 'Control',
          label: 'Phone Number',
          scope: '#/properties/phone',
        },
      ],
    },
    {
      type: 'Control',
      label: 'Address',
      scope: '#/properties/address',
    },
  ],
};

const beneficialOwnerUISchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          label: 'Name',
          scope: '#/properties/name',
        },
        {
          type: 'Control',
          label: 'Ownership % of the entity (if at least 25%)',
          scope: '#/properties/ownership',
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          label: 'Identification code /  Tax Number / Date of birth',
          scope: '#/properties/identificationCodeOrBirthDate',
        },
        {
          type: 'InfoText',
          options: {
            text: ' ',
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'InfoText',
          options: {
            text: 'Unique Identification Code / Citizen Number / Tax Identification Number.',
          },
        },
      ],
    },
    {
      type: 'HorizontalLayout',
      elements: [
        {
          type: 'Control',
          label: 'Identification Document Type',
          scope: '#/properties/identificationDocument',
          options: {
            options: ['Passport', 'ID Card'],
            freeSolo: true,
          },
        },
        {
          type: 'Control',
          label: 'Identification Document Number',
          scope: '#/properties/identificationNumber',
        },
      ],
    },
    {
      type: 'Control',
      label: 'Address',
      scope: '#/properties/address',
    },
    {
      type: 'Control',
      label: 'Is the Beneficial Owner a PEP or related to a PEP?',
      options: {
        format: 'radio',
      },
      scope: '#/properties/isPepRelated',
    },
    {
      type: 'Control',
      scope: '#/properties/pepInfo',
      rule: {
        effect: 'HIDE',
        condition: {
          scope: '#/properties/isPepRelated',
          schema: {
            const: 'no',
          },
        },
      },
    },
  ],
};

export const amlCompanyUISchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Group',
      label: 'Company details',
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Name',
              scope: '#/properties/company/properties/name',
            },
            {
              type: 'Control',
              label: 'Country of Establishment',
              scope: '#/properties/company/properties/country',
            },
          ],
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Registration Code',
              scope: '#/properties/company/properties/registrationCode',
            },
            {
              type: 'Control',
              label: 'Date of Registration',
              scope: '#/properties/company/properties/registrationDate',
            },
          ],
        },
      ],
    },
    {
      type: 'Group',
      label: 'Company contact information',
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Email',
              scope: '#/properties/company/properties/email',
            },
            {
              type: 'Control',
              label: 'Phone Number',
              scope: '#/properties/company/properties/phone',
            },
          ],
        },
        {
          type: 'Control',
          label: 'Registration Address',
          scope: '#/properties/company/properties/registrationAddress',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Additional information',
      elements: [
        {
          type: 'Control',
          label: 'Basis Of Representation',
          scope: '#/properties/company/properties/basisOfRepresentation',
          options: {
            format: 'radio',
          },
        },
        {
          type: 'Control',
          label: 'The entity is a financial institution',
          scope: '#/properties/company/properties/isFinancialInstitution',
        },
        {
          type: 'Control',
          label: 'Main activity field',
          scope: '#/properties/company/properties/mainActivityField',
        },
        {
          type: 'Control',
          label: 'The company is a sole-properietorship company.',
          scope: '#/properties/company/properties/soleProprietorship',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Board Members',
      rule: {
        effect: 'HIDE',
        condition: {
          scope: '#/properties/company/properties/soleProprietorship',
          schema: {
            const: true,
          },
        },
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/company/properties/boardMembers',
          options: {
            elementLabelProp: 'name',
            detail: boardMemberUISchema,
            custom: true,
            addButtonLabel: 'Add Board Member',
          },
        },
      ],
    },
    {
      type: 'Group',
      label: 'Beneficial Owners',
      rule: {
        effect: 'HIDE',
        condition: {
          scope: '#/properties/company/properties/soleProprietorship',
          schema: {
            const: true,
          },
        },
      },
      elements: [
        {
          type: 'Control',
          label:
            'The beneficial owners of a legal person do not include natural persons who directly or indirectly own or control more than 25% of the shares, voting rights or voting rights of the legal person or who otherwise control the management of the legal person.',
          scope: '#/properties/company/properties/hasNot25Owners',
        },
        {
          type: 'Control',
          label: '',
          scope: '#/properties/company/properties/beneficialOwners',
          rule: {
            effect: 'HIDE',
            condition: {
              scope: '#/properties/company/properties/hasNot25Owners',
              schema: {
                const: true,
              },
            },
          },
          options: {
            elementLabelProp: 'name',
            detail: beneficialOwnerUISchema,
            custom: true,
            addButtonLabel: 'Add Beneficial Owner',
          },
        },
      ],
    },
  ],
};

export const amlPersonUISchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Group',
      label: 'Personal details',
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Name',
              scope: '#/properties/person/properties/name',
            },
            {
              type: 'Control',
              label: 'Residency',
              scope: '#/properties/person/properties/residency',
            },
          ],
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Tax Residency',
              scope: '#/properties/person/properties/taxResidency',
            },
            {
              type: 'Control',
              label: 'Citizenship',
              scope: '#/properties/person/properties/citizenship',
            },
          ],
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Idetification Code / Tax Number',
              scope: '#/properties/person/properties/idCode',
            },
            {
              type: 'InfoText',
              options: {
                text: ' ',
              },
            },
          ],
        },
        {
          type: 'InfoText',
          options: {
            text: 'Your unique Identification Code / Citizen Number / Tax Identification Number.',
          },
        },
      ],
    },
    {
      type: 'Group',
      label: 'Contact information',
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Email',
              scope: '#/properties/person/properties/email',
            },
            {
              type: 'Control',
              label: 'Phone number',
              scope: '#/properties/person/properties/phone',
            },
          ],
        },
        {
          type: 'Control',
          label: 'Home Address',
          scope: '#/properties/person/properties/homeAddress',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Identification details',
      elements: [
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Identification Document',
              scope: '#/properties/person/properties/identificationDocument',
              options: {
                options: ['Passport', 'ID Card'],
                freeSolo: true,
              },
            },
            {
              type: 'Control',
              label: 'Document Number',
              scope: '#/properties/person/properties/identificationNumber',
            },
          ],
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              label: 'Document Issue Date',
              scope: '#/properties/person/properties/idIssueDate',
            },
            {
              type: 'Control',
              label: 'Document Issue Country',
              scope: '#/properties/person/properties/idIssueCountry',
            },
          ],
        },
      ],
    },
    {
      type: 'Group',
      label: 'More details',
      elements: [
        {
          type: 'Control',
          label: 'Are you a politically exposed person (PEP) or related to a PEP?',
          scope: '#/properties/person/properties/isPep',
          options: {
            format: 'radio',
          },
        },
        {
          type: 'Control',
          scope: '#/properties/person/properties/pepInfo',
          rule: {
            effect: 'HIDE',
            condition: {
              scope: '#/properties/person/properties/isPep',
              schema: {
                const: 'no',
              },
            },
          },
        },
        {
          type: 'InfoText',
          options: {
            text: '* PEP is an individual who is or has been entrusted with a prominent public function (for example heads of state or of government, senior politicians, senior government, judicial or military officials, senior executives of state owned corporations, important political party officials).',
          },
        },
        {
          type: 'Control',
          label: 'Source of Funds',
          scope: '#/properties/person/properties/sourceOfFunds',
        },
        {
          type: 'InfoText',
          options: {
            text: 'Please indicate your SOURCE OF FUNDS (to make an investment)\n*e.g. salary, from investment, saving',
          },
        },
      ],
    },
  ],
};
