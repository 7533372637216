import React, { useState } from 'react';
import { uploadFundDocument } from './fundDocumentActions';
import {
  ApiDocumentShareScope,
  ApiDocumentType,
  ApiLimitedPartner,
  LimitedPartnerType,
} from '../../api/models';
import { Button } from '../../common/Button';
import { Select } from '../../common/inputs/Select';
import { useQueryClient } from 'react-query';
import { useAlert } from 'react-alert';
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Input,
  Stack,
} from '@mui/material';
import { useGeneralPartner } from '../../hooks/useGeneralPartner';
import { useParams } from 'react-router-dom';
import { TextField } from '../../common/TextField';

export interface Props {
  fundId: string;
}

export const FundDocumentUpload: React.FC<Props> = ({ fundId }: Props) => {
  const { generalPartnerId } = useParams<{ generalPartnerId: string }>();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { generalPartner } = useGeneralPartner(generalPartnerId!);

  const buildSelectOption = (lp: ApiLimitedPartner) => {
    if (lp.limitedPartnerType == LimitedPartnerType.Company)
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value: lp.company!.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        label: `Company | ${lp.company!.name}`,
        type: ApiDocumentShareScope.Company,
      };
    else
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value: lp.person!.id,
        label: `Natural person | ${lp.person?.firstName} ${lp.person?.lastName}`,
        type: ApiDocumentShareScope.Person,
      };
  };

  const values = [
    {
      value: 'fund',
      label: 'Fund',
      type: undefined,
    },
    ...(generalPartner?.limitedPartners?.map((lp: ApiLimitedPartner) =>
      buildSelectOption(lp)
    ) || []),
  ];

  const [isOpen, setOpen] = React.useState(false);
  const closeHandler = () => setOpen(false);

  const queryClient = useQueryClient();
  const alert = useAlert();

  const submitUpload = async () => {
    setUploading(true);
    try {
      if (selectedFile) {
        await uploadFundDocument(
          fundId,
          selectedFile,
          fileType,
          shareScope,
          shareEntityId
        );
        queryClient.invalidateQueries('fund-documents');
        alert.show('File uploaded successfully!', { type: 'success' });
      }
      closeHandler();
    } catch (e: any) {
      console.error(e);
      alert.show('Upload failed: ' + (e.statusText || ''), { type: 'error' });
    } finally {
      setUploading(false);
    }
  };

  const [selectedFile, setSelectedFile] = useState();
  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const [fileType, setFileType] = useState<ApiDocumentType>(
    ApiDocumentType.Other
  );

  const [shareScope, setShareScope] = useState<ApiDocumentShareScope>(
    ApiDocumentShareScope.Fund
  );
  const [shareEntityId, setShareEntityId] = useState<string>(fundId);

  const [uploading, setUploading] = useState(false);

  return (
    <>
      <Button variant='SecondaryAction' onClick={() => setOpen(true)}>
        Upload
      </Button>
      <Dialog open={isOpen} onClose={closeHandler}>
        <DialogContent>
          <Stack spacing={5}>
            <Autocomplete
              defaultValue={values[0]}
              disableClearable
              options={values}
              onChange={(_, chosenOption) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                setShareScope(chosenOption.type!);
                setShareEntityId(chosenOption?.value);
              }}
              disabled={uploading}
              renderInput={(params) => (
                <TextField {...params} label='Share scope' />
              )}
            />
            <FormControl fullWidth>
              <Select
                label='Document type'
                value={Object.entries(ApiDocumentType)[0][1]}
                onChange={(event) => {
                  setFileType(event.target.value as ApiDocumentType);
                }}
                options={Object.entries(ApiDocumentType).map(
                  (entry: ArrayLike<string>) => {
                    return { value: entry[1], label: entry[0] };
                  }
                )}
                disabled={uploading}
              />
            </FormControl>
            <Input type='file' onChange={changeHandler} disabled={uploading} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeHandler} disabled={uploading}>
            Cancel
          </Button>
          <Button onClick={submitUpload} disabled={uploading}>
            {uploading && (
              <CircularProgress
                sx={{
                  width: '15px !important',
                  height: '15px !important',
                  marginRight: '10px',
                }}
              />
            )}
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
