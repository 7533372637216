/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiDocumentLink,
    ApiDocumentLinkFromJSON,
    ApiDocumentLinkToJSON,
    ApiFundDocument,
    ApiFundDocumentFromJSON,
    ApiFundDocumentToJSON,
} from '../models';

export interface DeleteDocumentRequest {
    fundId: string;
    documentId: string;
}

export interface GetDownloadLinkRequest {
    fundId: string;
    documentId: string;
}

export interface GetFundDocumentsRequest {
    fundId: string;
}

export interface UploadDocumentRequest {
    fundId: string;
    file: Blob;
    documentType: string;
    shareScope: string;
    shareEntityId: string;
}

/**
 * 
 */
export class FundDocumentApi extends runtime.BaseAPI {

    /**
     * delete document
     */
    async deleteDocumentRaw(requestParameters: DeleteDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
            throw new runtime.RequiredError('fundId','Required parameter requestParameters.fundId was null or undefined when calling deleteDocument.');
        }

        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling deleteDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/funds/{fundId}/documents/{documentId}`.replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))).replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete document
     */
    async deleteDocument(requestParameters: DeleteDocumentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteDocumentRaw(requestParameters, initOverrides);
    }

    /**
     * download document
     */
    async getDownloadLinkRaw(requestParameters: GetDownloadLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiDocumentLink>> {
        if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
            throw new runtime.RequiredError('fundId','Required parameter requestParameters.fundId was null or undefined when calling getDownloadLink.');
        }

        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDownloadLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/funds/{fundId}/documents/{documentId}/download`.replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))).replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiDocumentLinkFromJSON(jsonValue));
    }

    /**
     * download document
     */
    async getDownloadLink(requestParameters: GetDownloadLinkRequest, initOverrides?: RequestInit): Promise<ApiDocumentLink> {
        const response = await this.getDownloadLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get fund documents
     */
    async getFundDocumentsRaw(requestParameters: GetFundDocumentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiFundDocument>>> {
        if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
            throw new runtime.RequiredError('fundId','Required parameter requestParameters.fundId was null or undefined when calling getFundDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/funds/{fundId}/documents`.replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiFundDocumentFromJSON));
    }

    /**
     * get fund documents
     */
    async getFundDocuments(requestParameters: GetFundDocumentsRequest, initOverrides?: RequestInit): Promise<Array<ApiFundDocument>> {
        const response = await this.getFundDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * upload document
     */
    async uploadDocumentRaw(requestParameters: UploadDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
            throw new runtime.RequiredError('fundId','Required parameter requestParameters.fundId was null or undefined when calling uploadDocument.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadDocument.');
        }

        if (requestParameters.documentType === null || requestParameters.documentType === undefined) {
            throw new runtime.RequiredError('documentType','Required parameter requestParameters.documentType was null or undefined when calling uploadDocument.');
        }

        if (requestParameters.shareScope === null || requestParameters.shareScope === undefined) {
            throw new runtime.RequiredError('shareScope','Required parameter requestParameters.shareScope was null or undefined when calling uploadDocument.');
        }

        if (requestParameters.shareEntityId === null || requestParameters.shareEntityId === undefined) {
            throw new runtime.RequiredError('shareEntityId','Required parameter requestParameters.shareEntityId was null or undefined when calling uploadDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.documentType !== undefined) {
            formParams.append('documentType', requestParameters.documentType as any);
        }

        if (requestParameters.shareScope !== undefined) {
            formParams.append('shareScope', requestParameters.shareScope as any);
        }

        if (requestParameters.shareEntityId !== undefined) {
            formParams.append('shareEntityId', requestParameters.shareEntityId as any);
        }

        const response = await this.request({
            path: `/owner/funds/{fundId}/documents`.replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * upload document
     */
    async uploadDocument(requestParameters: UploadDocumentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.uploadDocumentRaw(requestParameters, initOverrides);
    }

}
