/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AmlFormStatus,
    AmlFormStatusFromJSON,
    AmlFormStatusFromJSONTyped,
    AmlFormStatusToJSON,
    RiskStatus,
    RiskStatusFromJSON,
    RiskStatusFromJSONTyped,
    RiskStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiUpdateLimitedPartnerPersonRequest
 */
export interface ApiUpdateLimitedPartnerPersonRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiUpdateLimitedPartnerPersonRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUpdateLimitedPartnerPersonRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUpdateLimitedPartnerPersonRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUpdateLimitedPartnerPersonRequest
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUpdateLimitedPartnerPersonRequest
     */
    residency?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUpdateLimitedPartnerPersonRequest
     */
    taxResidency?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUpdateLimitedPartnerPersonRequest
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUpdateLimitedPartnerPersonRequest
     */
    phone?: string;
    /**
     * 
     * @type {AmlFormStatus}
     * @memberof ApiUpdateLimitedPartnerPersonRequest
     */
    amlStatus?: AmlFormStatus;
    /**
     * 
     * @type {RiskStatus}
     * @memberof ApiUpdateLimitedPartnerPersonRequest
     */
    risk?: RiskStatus;
}

export function ApiUpdateLimitedPartnerPersonRequestFromJSON(json: any): ApiUpdateLimitedPartnerPersonRequest {
    return ApiUpdateLimitedPartnerPersonRequestFromJSONTyped(json, false);
}

export function ApiUpdateLimitedPartnerPersonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiUpdateLimitedPartnerPersonRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'residency': !exists(json, 'residency') ? undefined : json['residency'],
        'taxResidency': !exists(json, 'taxResidency') ? undefined : json['taxResidency'],
        'bankAccount': !exists(json, 'bankAccount') ? undefined : json['bankAccount'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'amlStatus': !exists(json, 'amlStatus') ? undefined : AmlFormStatusFromJSON(json['amlStatus']),
        'risk': !exists(json, 'risk') ? undefined : RiskStatusFromJSON(json['risk']),
    };
}

export function ApiUpdateLimitedPartnerPersonRequestToJSON(value?: ApiUpdateLimitedPartnerPersonRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'address': value.address,
        'residency': value.residency,
        'taxResidency': value.taxResidency,
        'bankAccount': value.bankAccount,
        'phone': value.phone,
        'amlStatus': AmlFormStatusToJSON(value.amlStatus),
        'risk': RiskStatusToJSON(value.risk),
    };
}

