import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ownerShuftiApi, queryClient } from '../../apis';
import { ShuftiRef } from '../../common/admin/ShuftiRef';
import { Button } from '../../common/Button';
import { DisplayField } from '../../common/DisplayField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Params = {
  personId: string;
}


export const ShuftiStatus = ({ personId }: Params) => {
  const status = useQuery(['shufti-status', personId], async () => {
    return ownerShuftiApi.getShuftiStatus({
      personId: personId
    });
  });

  const refreshStatus = useMutation('refresh-shufti-status', async () => {
    return ownerShuftiApi.refreshShuftiStatus(
      {
        personId: personId
      }
    );
  },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shufti-status', personId]);
      }
    });

  const realtimeLink = useMutation('realtime-shufti-link', async () => {
    return ownerShuftiApi.generateRealtimeShuftiLink(
      {
        personId: personId
      }
    );
  },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shufti-status', personId]);
      }
    });

  if (status.isLoading || status.data == undefined) {
    return <CircularProgress />;
  }
  console.log(status.data);
  return (
    <Box width="500px">
      <Typography style={{
        fontFamily: 'Roboto',
        fontSize: '24px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'var(--black)',
      }}>ShuftiPro Status</Typography>
      <ShuftiRef personId={personId} id={status.data.id} reference={status.data.reference} />
      <DisplayField label='Status'>{status.data.status}</DisplayField>
      <Box>
        <Typography style={{ color: '#afafaf' }}>Url</Typography>
        <Typography style={{
          wordBreak: 'break-all'
        }}><a href={status.data.url}>{status.data.url}</a></Typography>
        <IconButton aria-label="delete" size="small" onClick={() => {
          if (status.data.url != undefined) {
            navigator.clipboard.writeText(status.data.url);
          }
        }}>
          <ContentCopyIcon />
        </IconButton>
      </Box>

      <DisplayField label='Last Event'>{status.data.payload?.event}</DisplayField>
      <DisplayField label='Country'>{status.data.payload?.verificationData?.document?.country}</DisplayField>
      <DisplayField label='Date of Birth'>{status.data.payload?.verificationData?.document?.dob}</DisplayField>
      <DisplayField label='Document Number'>{status.data.payload?.verificationData?.document?.documentNumber}</DisplayField>
      <DisplayField label='Expiry Date'>{status.data.payload?.verificationData?.document?.expiryDate}</DisplayField>
      <DisplayField label='Issue Date'>{status.data.payload?.verificationData?.document?.issueDate}</DisplayField>
      <DisplayField label='Place of Issue'>{status.data.payload?.verificationData?.document?.placeOfIssue}</DisplayField>
      <Button onClick={() => {
        refreshStatus.mutate();
      }}>Refresh Shufti Status</Button>
      {/* <Button onClick={() => {
        realtimeLink.mutate();
      }}>Generate Video Link</Button> */}

    </Box >
  )
}