import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Link,
  Box,
} from '@mui/material';
import './ExampleDocumentsPagePlaceholder.css';
import { MainContent } from '../../common/MainContent';

export interface Props {
  rows: Array<{ srNo: number; documentName: string; uploadedAt: string }>;
  fileName: string;
  title: string;
}

export const ExampleDocumentsPagePlaceholder: React.FC<Props> = ({
  rows,
  fileName,
  title,
}: Props) => (
  <MainContent headingText={title}>
    <Box sx={{ padding: '40px' }}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className='documents-table-cell'>№</TableCell>
              <TableCell className='documents-table-cell'>NAME</TableCell>
              <TableCell className='documents-table-cell'>
                UPLOADED AT
              </TableCell>
              <TableCell className='documents-table-cell' />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.srNo}>
                <TableCell>{row.srNo}</TableCell>
                <TableCell>{row.documentName}</TableCell>
                <TableCell>{row.uploadedAt}</TableCell>
                <TableCell>
                  <Link
                    href={`/${fileName}`}
                    download={`${title} example.pdf`}
                    color='inherit'
                    underline='none'
                  >
                    <Button
                      variant='contained'
                      style={{
                        fontSize: '14px',
                        padding: '7px 16px',
                        borderRadius: '0.75vh',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        color: 'white',
                        backgroundColor: '#003fae',
                      }}
                    >
                      Download
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </MainContent>
);
