import React from 'react';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

export interface Props {
  color: string;
}

export const SignOutButton: React.FC<Props> = ({ color }: Props) => {
  const auth = useAuthenticator();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return (
    <Button
      disableElevation
      style={{ textTransform: 'none', color }}
      onClick={() => {
        queryClient.invalidateQueries();
        localStorage.clear();
        auth.signOut();
        navigate('/');
      }}
    >
      <LogoutIcon sx={{ paddingRight: '0.5vw' }} />
      Sign out
    </Button>
  );
};
