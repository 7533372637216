import React, { useState } from 'react';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ownerInvitationApi } from '../../apis';
import { DisplayField } from '../../common/DisplayField';
import { Button } from '../../common/Button';
import { DateTime } from 'luxon';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Params = {
  generalPartnerId: string;
  personId: string;
}

const showTime = (time: Date | undefined) => {
  if (time == undefined) {
    return "N/A";
  }
  const dt = DateTime.fromJSDate(time);
  const days = DateTime.now().diff(dt, 'days').toObject();
  const daysNumber = Math.floor(days.days!);
  let daysStr = 'Today';
  if (daysNumber > 0) {
    daysStr = `${Math.abs(daysNumber)} days ago`;
  } else if (daysNumber < 0) {
    daysStr = `in ${Math.abs(daysNumber)} days`;
  }
  return `${dt.toFormat('dd LLL yyyy')} (${daysStr})`;
}

const generateUrl = (invitationId?: string): string => {
  if (invitationId == undefined) {
    return '';
  }
  const port = location.port != undefined ? `:${location.port}` : '';
  const url = `${location.protocol}//${location.hostname}${port}/onboard?invitation=${invitationId}`;
  return url;
}

const formatInviteUrl = (invitationId?: string) => {
  if (invitationId == undefined) {
    return 'Not Sent';
  }
  const url = generateUrl(invitationId);
  return <a href={url}>{url}</a>
}

const generatePin = () => {
  const pin = Math.floor(Math.random() * 10000);
  return `${pin}`.padStart(4, '0');
}

export const OwnerInvitationInfo = ({ personId }: Params) => {

  const [pin, setPin] = useState<string>();
  const invitationInfo = useQuery(['invitation-info', personId], async () => {
    return ownerInvitationApi.getInvitation({ personId: personId });
  });
  const queryClient = useQueryClient();
  const createInvitation = useMutation('create-invitation', async (pin: string) => {
    return ownerInvitationApi.createInvitation({
      personId: personId,
      apiCreateInvitation: {
        personId: personId,
        pin: pin
      }
    });
  },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['invitation-info', personId]);
      }
    });
  if (invitationInfo.isLoading || invitationInfo.data == undefined) {
    return <CircularProgress />;
  }

  return (
    <Box width="500px">
      <DisplayField label='Name'>{invitationInfo.data.firstName}  {invitationInfo.data.lastName}</DisplayField>
      <DisplayField label='Email'>{invitationInfo.data.email}</DisplayField>
      <DisplayField label='Invitation URL'>{formatInviteUrl(invitationInfo.data.id)}
        <IconButton size="small" onClick={() => {
          const url = generateUrl(invitationInfo.data.id);
          if (url != undefined) {
            navigator.clipboard.writeText(url);
          }
        }}>
          <ContentCopyIcon />
        </IconButton>
      </DisplayField>
      <DisplayField label='PIN'>{pin != undefined ? pin : 'N/A'}
        <IconButton size="small" onClick={() => {
          navigator.clipboard.writeText(pin != undefined ? pin : 'N/A');
        }}>
          <ContentCopyIcon />
        </IconButton>
      </DisplayField>
      <DisplayField label='Valid Until'>{showTime(invitationInfo.data.validUntil)}</DisplayField>
      <DisplayField label='Created On'>{showTime(invitationInfo.data.created)}</DisplayField>
      {pin && <Typography color='red'>WARNING! Don&apos;t refresh the page or you will loose the PIN! You will not be able to retreive it later. But you can generate a new Invite with a new PIN!</Typography>}
      <Button onClick={() => {
        const pin = generatePin();
        createInvitation.mutate(pin);
        setPin(pin);
      }}>Create Invitation</Button>
    </Box>

  );
}