/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiCompany,
    ApiCompanyFromJSON,
    ApiCompanyToJSON,
    ApiInvestorProfile,
    ApiInvestorProfileFromJSON,
    ApiInvestorProfileToJSON,
    ApiUpdateCompanyRequest,
    ApiUpdateCompanyRequestFromJSON,
    ApiUpdateCompanyRequestToJSON,
    ApiUpdateLimitedPartnerPersonRequest,
    ApiUpdateLimitedPartnerPersonRequestFromJSON,
    ApiUpdateLimitedPartnerPersonRequestToJSON,
} from '../models';

export interface AddLimitedPartnerCompaniesRequest {
    companyId: string;
    apiUpdateCompanyRequest: Array<ApiUpdateCompanyRequest>;
}

export interface AddLimitedPartnerPeopleRequest {
    companyId: string;
    apiUpdateLimitedPartnerPersonRequest: Array<ApiUpdateLimitedPartnerPersonRequest>;
}

export interface GetLimitedPartnerPersonRequest {
    personId: string;
}

export interface UpdateLimitedPartnerPersonRequest {
    personId: string;
    apiUpdateLimitedPartnerPersonRequest: ApiUpdateLimitedPartnerPersonRequest;
}

/**
 * 
 */
export class LimitedPartnersApi extends runtime.BaseAPI {

    /**
     * creates LPs companies for a company
     */
    async addLimitedPartnerCompaniesRaw(requestParameters: AddLimitedPartnerCompaniesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiCompany>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling addLimitedPartnerCompanies.');
        }

        if (requestParameters.apiUpdateCompanyRequest === null || requestParameters.apiUpdateCompanyRequest === undefined) {
            throw new runtime.RequiredError('apiUpdateCompanyRequest','Required parameter requestParameters.apiUpdateCompanyRequest was null or undefined when calling addLimitedPartnerCompanies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/companies/{companyId}/limited-partner-companies`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.apiUpdateCompanyRequest.map(ApiUpdateCompanyRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiCompanyFromJSON));
    }

    /**
     * creates LPs companies for a company
     */
    async addLimitedPartnerCompanies(requestParameters: AddLimitedPartnerCompaniesRequest, initOverrides?: RequestInit): Promise<Array<ApiCompany>> {
        const response = await this.addLimitedPartnerCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * creates LP people for a company
     */
    async addLimitedPartnerPeopleRaw(requestParameters: AddLimitedPartnerPeopleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiInvestorProfile>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling addLimitedPartnerPeople.');
        }

        if (requestParameters.apiUpdateLimitedPartnerPersonRequest === null || requestParameters.apiUpdateLimitedPartnerPersonRequest === undefined) {
            throw new runtime.RequiredError('apiUpdateLimitedPartnerPersonRequest','Required parameter requestParameters.apiUpdateLimitedPartnerPersonRequest was null or undefined when calling addLimitedPartnerPeople.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/companies/{companyId}/limited-partner-people`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.apiUpdateLimitedPartnerPersonRequest.map(ApiUpdateLimitedPartnerPersonRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiInvestorProfileFromJSON));
    }

    /**
     * creates LP people for a company
     */
    async addLimitedPartnerPeople(requestParameters: AddLimitedPartnerPeopleRequest, initOverrides?: RequestInit): Promise<Array<ApiInvestorProfile>> {
        const response = await this.addLimitedPartnerPeopleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fetches one limited partner natural person info
     */
    async getLimitedPartnerPersonRaw(requestParameters: GetLimitedPartnerPersonRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiInvestorProfile>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getLimitedPartnerPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/limited-partner-people/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInvestorProfileFromJSON(jsonValue));
    }

    /**
     * fetches one limited partner natural person info
     */
    async getLimitedPartnerPerson(requestParameters: GetLimitedPartnerPersonRequest, initOverrides?: RequestInit): Promise<ApiInvestorProfile> {
        const response = await this.getLimitedPartnerPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updates a limited partner natural person
     */
    async updateLimitedPartnerPersonRaw(requestParameters: UpdateLimitedPartnerPersonRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiInvestorProfile>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling updateLimitedPartnerPerson.');
        }

        if (requestParameters.apiUpdateLimitedPartnerPersonRequest === null || requestParameters.apiUpdateLimitedPartnerPersonRequest === undefined) {
            throw new runtime.RequiredError('apiUpdateLimitedPartnerPersonRequest','Required parameter requestParameters.apiUpdateLimitedPartnerPersonRequest was null or undefined when calling updateLimitedPartnerPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/limited-partner-people/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiUpdateLimitedPartnerPersonRequestToJSON(requestParameters.apiUpdateLimitedPartnerPersonRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInvestorProfileFromJSON(jsonValue));
    }

    /**
     * updates a limited partner natural person
     */
    async updateLimitedPartnerPerson(requestParameters: UpdateLimitedPartnerPersonRequest, initOverrides?: RequestInit): Promise<ApiInvestorProfile> {
        const response = await this.updateLimitedPartnerPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
