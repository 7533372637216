import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { isDemo } from '../../util/environment';
import { isStage } from '../../util/environment';
import { useQuery } from 'react-query';
import { ApiShuftiVerification, onboardApi, ownerShuftiApi, queryClient, ShuftiStatus } from '../../apis';

type Params = {
  shufti?: ApiShuftiVerification;
  personId?: string;
  onFinish: () => void;
};

export const ShuftiForm = ({ shufti, personId, onFinish }: Params) => {

  const shuftiStatus = useQuery(['shufti-status', personId], () => {
    return ownerShuftiApi.getShuftiStatus({
      personId: personId!
    });
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!shuftiStatus.isLoading) {
        console.log("Refreshing Status", shuftiStatus);
        queryClient.invalidateQueries(['shufti-status', personId]);
      }
    }, 3000);
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    if (shuftiStatus.data != undefined && shuftiStatus.data.status == ShuftiStatus.Approved) {
      onFinish();
    }
  }, [shuftiStatus]);

  useEffect(() => {
    console.log("Lesening for Message");
    window.onmessage = (e => {
      console.log('Post Message', e);
      if (e.origin == 'https://app.shuftipro.com' && e.data['verification_status'] == 'verification.accepted') {
        onFinish();
      }
    });
  }, []);

  return <div>
    <iframe src={shufti?.url} id="shuftipro-iframe" allow="camera *;microphone *" width="700px" height="800px"></iframe>
  </div>
};
