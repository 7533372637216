/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAdmin
 */
export interface ApiAdmin {
    /**
     * 
     * @type {string}
     * @memberof ApiAdmin
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAdmin
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAdmin
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAdmin
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAdmin
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAdmin
     */
    residency?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAdmin
     */
    legalPowers?: string;
}

export function ApiAdminFromJSON(json: any): ApiAdmin {
    return ApiAdminFromJSONTyped(json, false);
}

export function ApiAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAdmin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'residency': !exists(json, 'residency') ? undefined : json['residency'],
        'legalPowers': !exists(json, 'legalPowers') ? undefined : json['legalPowers'],
    };
}

export function ApiAdminToJSON(value?: ApiAdmin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phone': value.phone,
        'residency': value.residency,
        'legalPowers': value.legalPowers,
    };
}

