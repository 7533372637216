import {
  ApiDocumentShareScope,
  ApiDocumentType,
  fundDocumentApi,
  UploadDocumentRequest,
} from '../../apis';

export const uploadFundDocument = async (
  fundId: string,
  file: File,
  fileType: ApiDocumentType,
  shareScope: ApiDocumentShareScope,
  shareEntityId: string
) => {
  const request: UploadDocumentRequest = {
    fundId: fundId,
    documentType: fileType,
    file: file,
    shareScope: shareScope,
    shareEntityId: shareEntityId,
  };
  await fundDocumentApi.uploadDocument(request);
};

export const deleteFundDocument = async (
  fundId: string,
  documentId: string
) => {
  await fundDocumentApi.deleteDocument({
    fundId: fundId,
    documentId: documentId,
  });
};
