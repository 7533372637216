import React from 'react';
import Logo from '../assets/logo.png';
import FooterImage from '../assets/footer-image.png';
import { Box, Link, Typography } from '@mui/material';
import '@fontsource/roboto';
import { Impersonator } from './admin/Impersonator';

export const Footer: React.FC = () => (
  <Box
    display='flex'
    flexDirection='row'
    marginTop='20vh'
    minWidth='95vw'
    minHeight='40vh'
    style={{ backgroundColor: '#9bcccc' }}
  >
    <Box display='flex' flexDirection='row' style={{ gap: '4vw', margin: '4vh' }}>
      <img height='50vh' src={Logo} alt='Poolside logo' />
      <Box display='flex' flexDirection='column' style={{ gap: '2vh' }}>
        <Impersonator />
        <Typography
          style={{
            fontFamily: 'Roboto-Medium',
            fontWeight: 'bold',
            color: '#898989',
          }}
        >
          ABOUT
        </Typography>
        <Link
          style={{
            fontFamily: 'Roboto-Regular',
            color: 'black',
            marginTop: '5vh',
          }}
          underline='hover'
          target='_blank'
          href='https://gopoolside.com/privacy-policy'
        >
          Privacy Policy
        </Link>
        <Link
          style={{ fontFamily: 'Roboto-Regular', color: 'black' }}
          underline='hover'
          target='_blank'
          href='https://gopoolside.com/terms-of-use'
        >
          Terms of Use
        </Link>
        <Link
          style={{ fontFamily: 'Roboto-Regular', color: 'black' }}
          underline='hover'
          target='_blank'
          href='https://gopoolside.com/general-risk-disclaimer'
        >
          Risk Disclosure
        </Link>
        <hr
          style={{
            marginTop: '4vh',
            width: '100%',
            color: '#9bcccc',
            borderTop: '0.3vh dashed black',
          }}
        />
        <Typography style={{ fontFamily: 'Roboto-Regular', fontSize: '1.5vh' }}>
          Service is provided by Estcap Asset Management OÜ,
          <br /> registered with the Estonian Financial Supervision Authority
        </Typography>
      </Box>
    </Box>
    <img style={{ margin: '8% 0 1% auto' }} height='100%' src={FooterImage} alt='Footer image' />
  </Box>
);
