/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AmlFormStatus {
    InProgress = 'IN_PROGRESS',
    Review = 'REVIEW',
    Submitted = 'SUBMITTED',
    Approved = 'APPROVED'
}

export function AmlFormStatusFromJSON(json: any): AmlFormStatus {
    return AmlFormStatusFromJSONTyped(json, false);
}

export function AmlFormStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AmlFormStatus {
    return json as AmlFormStatus;
}

export function AmlFormStatusToJSON(value?: AmlFormStatus | null): any {
    return value as any;
}

