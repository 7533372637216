import { UISchemaElement } from '@jsonforms/core';
import { materialCells } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { Box, Toolbar } from '@mui/material';
import React from 'react';
import { LimitedPartnerType } from '../../api';
import { Button } from '../../common/Button';
import { poolSideJsonFormRenderers } from '../../common/renderers';
import { amlSchema } from '../aml/dataSchema';
import { amlCompanyUISchema, amlPersonUISchema } from '../aml/uiSchemas';

type Params = {
  formData: any;
  formType: LimitedPartnerType;
  onChange?: (data: any) => void;
  onNext?: () => void;
};

export const OnboardAmlForm = ({ formData, formType, onNext, onChange }: Params) => {
  let uischema: UISchemaElement = amlPersonUISchema;
  if (formType == LimitedPartnerType.Company) {
    uischema = amlCompanyUISchema;
  }
  return (
    <Box display='flex' flexDirection='column' width='780px' marginTop='20px' rowGap='20px'>
      <JsonForms
        schema={amlSchema}
        uischema={uischema}
        data={formData}
        renderers={poolSideJsonFormRenderers}
        cells={materialCells}
        // readonly={current.context.isReadonly}
        onChange={({ data }) => {
          if (onChange != undefined) {
            onChange(data);
          }
        }}
      />
      <Toolbar
        sx={{
          paddingLeft: '0px',
          paddingRight: '0px',
          flexGrow: 1,
          columnGap: '5px',
        }}
      >
        <Box flexGrow={1}></Box>
        <Button
          onClick={async () => {
            if (onNext != undefined) {
              onNext();
            }
          }}
        >
          Next
        </Button>
      </Toolbar>
    </Box>
  );
};
