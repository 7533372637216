import React from 'react';
import {
  Authenticator as AmplifyAuthenticator,
  CheckboxField,
  useAuthenticator,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './authenticator.css';
import { SignUpLegalText } from '../../SignUpLegalText';

export interface AuthenticatorProps {
  children: React.ReactNode;
  initialState?: 'signUp' | 'signIn' | 'resetPassword';
}

export const Authenticator: React.FC<AuthenticatorProps> = ({
  children,
  initialState = 'signIn',
}: AuthenticatorProps) => {
  return (
    <AmplifyAuthenticator
      initialState={initialState}
      loginMechanisms={['email']}
      components={{
        SignUp: {
          FormFields() {
            const { validationErrors } = useAuthenticator();
            return (
              <>
                <AmplifyAuthenticator.SignUp.FormFields />
                <CheckboxField
                  errorMessage={validationErrors.acknowledgement}
                  hasError={!!validationErrors.acknowledgement}
                  name='acknowledgement'
                  value='yes'
                  role='checkbox'
                  label={<SignUpLegalText />}
                />
              </>
            );
          },
        },
      }}
      services={{
        async validateCustomSignUp(formData) {
          if (!formData.acknowledgement) {
            return {
              acknowledgement:
                'You must agree to the Privacy Policy and Terms of Use in order to sign up.',
            };
          }
        },
      }}
      variation='modal'
    >
      {() => <>{children}</>}
    </AmplifyAuthenticator>
  );
};
