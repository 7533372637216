/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiShuftiReferenceRequest
 */
export interface ApiShuftiReferenceRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiReferenceRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiReferenceRequest
     */
    personId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiReferenceRequest
     */
    reference?: string;
}

export function ApiShuftiReferenceRequestFromJSON(json: any): ApiShuftiReferenceRequest {
    return ApiShuftiReferenceRequestFromJSONTyped(json, false);
}

export function ApiShuftiReferenceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiShuftiReferenceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'personId': !exists(json, 'personId') ? undefined : json['personId'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
    };
}

export function ApiShuftiReferenceRequestToJSON(value?: ApiShuftiReferenceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'personId': value.personId,
        'reference': value.reference,
    };
}

