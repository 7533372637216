import { Box, Typography } from '@mui/material';
import React from 'react';
import { ApiOnboardProfile, ApiShuftiVerification, ApiVeriffStatus } from '../../api';
import { ShuftiForm } from '../verification/ShuftiForm';
import { VerificationForm } from '../verification/VerificationForm';
type Params = {
  profile?: ApiOnboardProfile;
  email?: string;
  firstName?: string;
  lastName?: string;
  shufti: ApiShuftiVerification;
  onNext?: () => void;
};

export const VeriffStep = ({ shufti, profile, email, firstName, lastName, onNext }: Params) => {
  return (
    <Box>
      {(profile?.veriffStatus === ApiVeriffStatus.InProgress && (
        <>
          <Typography style={{ fontFamily: 'Roboto' }} variant='h5'>
            Verification is already in progress, please wait for the results.
          </Typography>
        </>
      )) || (
          <>
            <Typography style={{ fontFamily: 'Roboto' }} variant='h5'>
              Please verify your identity
            </Typography>
            <ShuftiForm shufti={shufti} personId={profile?.id}
              onFinish={() => {
                if (onNext != undefined) {
                  onNext();
                }
              }}
            />
          </>
        )}
    </Box>
  );
};
