/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiAmlForm,
    ApiAmlFormFromJSON,
    ApiAmlFormToJSON,
    LimitedPartnerType,
    LimitedPartnerTypeFromJSON,
    LimitedPartnerTypeToJSON,
} from '../models';

export interface GetOwnerAmlFormRequest {
    limitedPartnerId: string;
    limitedPartnerType: LimitedPartnerType;
}

export interface GetOwnerAmlFormHistoryRequest {
    limitedPartnerId: string;
    limitedPartnerType: LimitedPartnerType;
}

export interface SetOwnerAmlFormRequest {
    apiAmlForm: ApiAmlForm;
}

/**
 * 
 */
export class LimitedPartnersAmlApi extends runtime.BaseAPI {

    /**
     * fetches aml form for a limited partner
     */
    async getOwnerAmlFormRaw(requestParameters: GetOwnerAmlFormRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAmlForm>> {
        if (requestParameters.limitedPartnerId === null || requestParameters.limitedPartnerId === undefined) {
            throw new runtime.RequiredError('limitedPartnerId','Required parameter requestParameters.limitedPartnerId was null or undefined when calling getOwnerAmlForm.');
        }

        if (requestParameters.limitedPartnerType === null || requestParameters.limitedPartnerType === undefined) {
            throw new runtime.RequiredError('limitedPartnerType','Required parameter requestParameters.limitedPartnerType was null or undefined when calling getOwnerAmlForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/aml/{limitedPartnerId}/{limitedPartnerType}`.replace(`{${"limitedPartnerId"}}`, encodeURIComponent(String(requestParameters.limitedPartnerId))).replace(`{${"limitedPartnerType"}}`, encodeURIComponent(String(requestParameters.limitedPartnerType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAmlFormFromJSON(jsonValue));
    }

    /**
     * fetches aml form for a limited partner
     */
    async getOwnerAmlForm(requestParameters: GetOwnerAmlFormRequest, initOverrides?: RequestInit): Promise<ApiAmlForm> {
        const response = await this.getOwnerAmlFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fetches aml form for a limited partner
     */
    async getOwnerAmlFormHistoryRaw(requestParameters: GetOwnerAmlFormHistoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiAmlForm>>> {
        if (requestParameters.limitedPartnerId === null || requestParameters.limitedPartnerId === undefined) {
            throw new runtime.RequiredError('limitedPartnerId','Required parameter requestParameters.limitedPartnerId was null or undefined when calling getOwnerAmlFormHistory.');
        }

        if (requestParameters.limitedPartnerType === null || requestParameters.limitedPartnerType === undefined) {
            throw new runtime.RequiredError('limitedPartnerType','Required parameter requestParameters.limitedPartnerType was null or undefined when calling getOwnerAmlFormHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/aml/history/{limitedPartnerId}/{limitedPartnerType}`.replace(`{${"limitedPartnerId"}}`, encodeURIComponent(String(requestParameters.limitedPartnerId))).replace(`{${"limitedPartnerType"}}`, encodeURIComponent(String(requestParameters.limitedPartnerType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiAmlFormFromJSON));
    }

    /**
     * fetches aml form for a limited partner
     */
    async getOwnerAmlFormHistory(requestParameters: GetOwnerAmlFormHistoryRequest, initOverrides?: RequestInit): Promise<Array<ApiAmlForm>> {
        const response = await this.getOwnerAmlFormHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saves AML form for a limited partner
     */
    async setOwnerAmlFormRaw(requestParameters: SetOwnerAmlFormRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAmlForm>> {
        if (requestParameters.apiAmlForm === null || requestParameters.apiAmlForm === undefined) {
            throw new runtime.RequiredError('apiAmlForm','Required parameter requestParameters.apiAmlForm was null or undefined when calling setOwnerAmlForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/aml/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAmlFormToJSON(requestParameters.apiAmlForm),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAmlFormFromJSON(jsonValue));
    }

    /**
     * saves AML form for a limited partner
     */
    async setOwnerAmlForm(requestParameters: SetOwnerAmlFormRequest, initOverrides?: RequestInit): Promise<ApiAmlForm> {
        const response = await this.setOwnerAmlFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
