import { useQuery } from 'react-query';
import { ApiInvestorProfile, ApiVeriffStatus } from '../api';
import { limitedPartnerPlatformApi } from '../apis';

const investorProfileQuery = 'investorProfile';

export const useInvestorProfile = (): {
  isProfileLoading: boolean;
  profile: ApiInvestorProfile | undefined;
  isProfileError: boolean;
} => {
  const profileQuery = useQuery(
    investorProfileQuery,
    async () => {
      return limitedPartnerPlatformApi.getInvestorProfileById({ limitedPartnerId: 'self' });
    },
    {
      // retry: (failureCount, error) => {
      //   return failureCount < 1 && (error as any)?.status !== 404;
      // },
      // refetchInterval: (data) => {
      //   if (data?.veriffStatus === ApiVeriffStatus.InProgress) {
      //     return 5000;
      //   } else {
      //     return false;
      //   }
      // },
      // refetchOnWindowFocus: false,
      // retryDelay: 5000,
      onError: (err) => {
        return null;
      },
    }
  );
  return {
    isProfileLoading: profileQuery.isLoading,
    profile: profileQuery.data,
    isProfileError: profileQuery.isError,
  };
};
