import React, { useState } from 'react';
import { MainContent } from '../../common/MainContent';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { TabPanel, Tabs } from '../../common/Tabs';
import { amlCompanyUISchema, amlPersonUISchema } from './uiSchemas';
import { AmlIndividualForm } from './AmlIndividualForm';
import { BreadcrumbLink } from '../../common/Breadcrumbs';
import { useGeneralPartner } from '../../hooks/useGeneralPartner';
import { useQueries, useQuery } from 'react-query';
import { limitedPartnerPlatformApi, ownerAmlApi } from '../../apis';
import {
  AmlFormStatus,
  ApiAdminCompany,
  ApiAmlForm,
  ApiInvestorProfile,
  LimitedPartnerType,
} from '../../api';
import { AmlHistoryForm } from './AmlHistoryForm';

export type AmlFormParams = {
  generalPartnerId: string;
  personId: string;
  companyId: string;
};

export const buildNaturalPersonFormData = (targetProfile: ApiInvestorProfile): string => {
  const formData = {
    person: {
      name: targetProfile.firstName + ' ' + targetProfile.lastName?.toUpperCase(),
      email: targetProfile.email,
      residency: targetProfile.residency,
      taxResidency: targetProfile.taxResidency,
      homeAddress: targetProfile.address,
      phone: targetProfile.phone,
    },
  };

  return JSON.stringify(formData);
};

export const buildCompanyFormData = (
  targetProfile: ApiInvestorProfile,
  company: ApiAdminCompany
): string => {
  if (company.company == undefined) {
    return '{}';
  }
  let basisOfRepresentation = '';
  const representations = ['Board member', 'Under a Power of Attorney'];
  if (company.legalPowers != undefined) {
    const idx = representations.findIndex(
      (s) => s.toLowerCase().indexOf(company.legalPowers!.toLowerCase()) >= 0
    );
    if (idx >= 0) basisOfRepresentation = representations[idx];
  }

  const boardMembers = company.company.representatives
    ?.filter((r) => {
      return r.legalPowers && r.legalPowers?.toLowerCase().includes('board member');
    })
    .map((r) => {
      return {
        name: `${r.firstName} ${r.lastName?.toUpperCase()}`,
        email: r.email,
        phone: r.phone,
      };
    });

  const defaultCompany = {
    name: company.company.name,
    registrationCode: company.company.registryCode,
    registrationAddress: company.company.address,
    email:
      company.company.contactEmail != undefined
        ? company.company.contactEmail
        : targetProfile.email,
    basisOfRepresentation: basisOfRepresentation,
    boardMembers: boardMembers,
  };

  const formData = {
    company: defaultCompany,
  };

  return JSON.stringify(formData);
};

export const buildNaturalPersonDefaultValue = (
  selfProfile: ApiInvestorProfile,
  targetProfile: ApiInvestorProfile,
  personalAmlForm?: ApiAmlForm
): ApiAmlForm => {
  if (personalAmlForm && personalAmlForm.formData) {
    return Object.assign({}, personalAmlForm, { createdBy: selfProfile.id });
  }

  return {
    amlFormStatus: AmlFormStatus.InProgress,
    createdBy: selfProfile.id,
    createdForPerson: targetProfile.id,
    formData: buildNaturalPersonFormData(targetProfile),
  };
};

export const buildCompanyDefaultValue = (
  selfProfile: ApiInvestorProfile,
  targetProfile: ApiInvestorProfile,
  formParams: AmlFormParams,
  companyAmlForm?: ApiAmlForm
): ApiAmlForm | undefined => {
  if (companyAmlForm && companyAmlForm.formData) {
    return Object.assign({}, companyAmlForm, { createdBy: selfProfile.id });
  }

  const company = targetProfile.companies?.find((c) => c.company?.id == formParams.companyId);
  if (company == undefined) {
    return undefined;
  }

  return {
    amlFormStatus: AmlFormStatus.InProgress,
    createdBy: selfProfile.id,
    createdForCompany: company.company?.id,
    formData: buildCompanyFormData(targetProfile, company),
  };
};

export const useRetreiveDefaultValue = (formParams: AmlFormParams) => {
  const profileIds = ['self'];
  profileIds.push(formParams.personId);
  const profileQueries = useQueries(
    profileIds.map((profileId) => {
      return {
        queryKey: ['investor-profile', profileId],
        queryFn: async () => {
          return limitedPartnerPlatformApi.getInvestorProfileById({
            limitedPartnerId: profileId,
          });
        },
      };
    })
  );
  const selfProfile = profileQueries[0];
  const targetProfile = profileQueries[1];

  const personAmlForm = useQuery(
    ['person-aml', targetProfile.data?.id],
    async () => {
      if (targetProfile.data != undefined) {
        return ownerAmlApi.getOwnerAmlForm({
          limitedPartnerId: targetProfile.data?.id,
          limitedPartnerType: LimitedPartnerType.NaturalPerson,
        });
      }
    },
    { enabled: targetProfile.isSuccess }
  );

  const companyAmlForm = useQuery(
    ['company-aml', targetProfile.data?.id],
    async () => {
      if (formParams.companyId != 'none') {
        const company = targetProfile.data!.companies?.find(
          (c) => c.company?.id == formParams.companyId
        );
        if (company != undefined && company.company != undefined) {
          return ownerAmlApi.getOwnerAmlForm({
            limitedPartnerId: company.company.id,
            limitedPartnerType: LimitedPartnerType.Company,
          });
        }
      }
    },
    { enabled: targetProfile.isSuccess }
  );

  return {
    selfProfile: selfProfile.data,
    targetProfile: targetProfile.data,
    personalAmlForm: personAmlForm.data,
    companyAmlForm: companyAmlForm.data,
    isAmlFormLoading:
      selfProfile.isLoading ||
      targetProfile.isLoading ||
      personAmlForm.isLoading ||
      companyAmlForm.isLoading,
  };
};

export const AmlOwnerForm = () => {
  const params = useParams<AmlFormParams>();
  if (params.generalPartnerId == undefined) {
    return <CircularProgress />;
  }
  const [tab, setTab] = useState<number>(0);

  const { selfProfile, targetProfile, personalAmlForm, companyAmlForm, isAmlFormLoading } =
    useRetreiveDefaultValue(params as AmlFormParams);

  const { generalPartner, isGeneralPartnerLoading } = useGeneralPartner(params.generalPartnerId);

  if (
    isAmlFormLoading ||
    selfProfile == undefined ||
    targetProfile == undefined ||
    personalAmlForm == undefined ||
    isGeneralPartnerLoading ||
    generalPartner == undefined
  ) {
    return <CircularProgress />;
  }

  const companyDefaultValue = buildCompanyDefaultValue(
    selfProfile,
    targetProfile,
    params as AmlFormParams,
    companyAmlForm
  );

  const personDefaulValue = buildNaturalPersonDefaultValue(
    selfProfile,
    targetProfile,
    personalAmlForm
  );

  const tabNames = ['Natural Person'];

  if (companyDefaultValue != undefined) {
    tabNames.push('Entity');
  }
  tabNames.push('Natural Person (History)');
  if (companyDefaultValue != undefined) {
    tabNames.push('Entity (History)');
  }

  return (
    <MainContent
      links={[
        <BreadcrumbLink key={1} to='/general-partners'>
          All companies
        </BreadcrumbLink>,
        <BreadcrumbLink key={2} to={`/general-partners/${params.generalPartnerId}`}>
          {generalPartner.name}
        </BreadcrumbLink>,
        <BreadcrumbLink
          key={3}
          to={`/general-partners/${params.generalPartnerId}/limited-partners`}
        >
          Limited Partners
        </BreadcrumbLink>,
        <BreadcrumbLink key={4} to='#'>
          AML
        </BreadcrumbLink>,
      ]}
      headingText='Onboarding AML Form'
    >
      <Tabs
        value={tab}
        tabs={tabNames}
        onChange={(_, index) => {
          setTab(index);
        }}
        panels={(value: number) => {
          let companyTab = <></>;
          if (companyDefaultValue != undefined) {
            companyTab = (
              <TabPanel value={value} index={1}>
                <AmlIndividualForm
                  backLink={`/general-partners/${params.generalPartnerId}/limited-partners`}
                  amlForm={companyDefaultValue}
                  uiSchema={amlCompanyUISchema}
                />
              </TabPanel>
            );
          }
          return (
            <>
              <TabPanel value={value} index={0}>
                <AmlIndividualForm
                  backLink={`/general-partners/${params.generalPartnerId}/limited-partners`}
                  amlForm={personDefaulValue}
                  uiSchema={amlPersonUISchema}
                />
              </TabPanel>
              {companyTab}
              <TabPanel value={value} index={companyDefaultValue != undefined ? 2 : 1}>
                <AmlHistoryForm
                  personId={targetProfile.id}
                  backLink={`/general-partners/${params.generalPartnerId}/limited-partners`}
                />
              </TabPanel>
              {companyDefaultValue != undefined &&
                <TabPanel value={value} index={3}>
                  <AmlHistoryForm
                    companyId={params.companyId}
                    backLink={`/general-partners/${params.generalPartnerId}/limited-partners`}
                  />
                </TabPanel>
              }
            </>
          );
        }}
      ></Tabs>
    </MainContent>
  );
};
