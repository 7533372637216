/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiShuftiResponseVerificationData,
    ApiShuftiResponseVerificationDataFromJSON,
    ApiShuftiResponseVerificationDataFromJSONTyped,
    ApiShuftiResponseVerificationDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiShuftiResponse
 */
export interface ApiShuftiResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiResponse
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiResponse
     */
    event?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShuftiResponse
     */
    error?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiShuftiResponse
     */
    verificationResult?: object;
    /**
     * 
     * @type {ApiShuftiResponseVerificationData}
     * @memberof ApiShuftiResponse
     */
    verificationData?: ApiShuftiResponseVerificationData;
}

export function ApiShuftiResponseFromJSON(json: any): ApiShuftiResponse {
    return ApiShuftiResponseFromJSONTyped(json, false);
}

export function ApiShuftiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiShuftiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'event': !exists(json, 'event') ? undefined : json['event'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'verificationResult': !exists(json, 'verification_result') ? undefined : json['verification_result'],
        'verificationData': !exists(json, 'verification_data') ? undefined : ApiShuftiResponseVerificationDataFromJSON(json['verification_data']),
    };
}

export function ApiShuftiResponseToJSON(value?: ApiShuftiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reference': value.reference,
        'event': value.event,
        'error': value.error,
        'verification_result': value.verificationResult,
        'verification_data': ApiShuftiResponseVerificationDataToJSON(value.verificationData),
    };
}

