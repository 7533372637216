export const naturalPersonFormSchema = {
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      description: 'Please enter your first name',
    },
    lastName: {
      type: 'string',
      description: 'Please enter your last name',
    },
    residency: {
      type: 'string',
      countryList: true,
      description: 'Please enter your Residency',
    },
    taxResidency: {
      type: 'string',
      countryList: true,
      description: "Please enter company's Tax Residency",
    },
    address: {
      type: 'string',
      description: "Please enter company's Address",
    },
    bankAccount: {
      type: 'string',
      description: "Please enter company's Bank Account",
    },
    email: {
      type: 'string',
      description: 'Please enter your Email',
    },
    amlStatus: {
      type: 'string',
    },
  },
};

const representative = {
  type: 'object',
  properties: {
    company: {
      type: 'string',
    },
    adminInfo: {
      type: 'object',
      properties: {
        ...naturalPersonFormSchema.properties,
        legalPowers: {
          type: 'string',
          // enum: ['Passport', 'ID Card'],
        },
        phone: {
          type: 'string',
          description: "Please Company's Representative Phone",
        },
      },
    },
  },
};

const companyFormSchema = {
  type: 'object',
  properties: {
    representatives: {
      type: 'array',
      items: representative,
    },
    companyData: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          description: "Please enter company's Name",
        },
        address: {
          type: 'string',
          description: "Please enter company's Address",
        },
        registryCode: {
          type: 'string',
          description: "Please enter company's Registry Code",
        },
        bankAccount: {
          type: 'string',
          description: "Please enter company's Bank Account",
        },
        residency: {
          type: 'string',
          countryList: true,
          description: "Please enter company's Residency",
        },
        taxResidency: {
          type: 'string',
          countryList: true,
          description: "Please enter company's Tax Residency",
        },
        contactEmail: {
          type: 'string',
          description: "Please enter company's Contact Email",
        },
      },
    },
  },
};

export const lpFormSchema = {
  type: 'object',
  properties: {
    person: naturalPersonFormSchema,
    company: companyFormSchema,
    lpTypeSelect: {
      type: 'string',
    },
    risk: {
      type: 'string'
    }
  },
};
