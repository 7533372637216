import React from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
};

export const BreadcrumbLink: React.FC<Props> = ({ children, to }) => {
  return (
    <MuiLink component={Link} underline='hover' color='inherit' to={to}>
      {children}
    </MuiLink>
  );
};

export const Breadcrumbs: React.FC = ({ children }) => {
  return (
    <MuiBreadcrumbs
      sx={{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: '500',
        color: '#003fae',
        letterSpacing: 'normal',
        '& .MuiBreadcrumbs-separator': {
          marginLeft: '7px',
          marginRight: '7px',
        },
      }}
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {children}
    </MuiBreadcrumbs>
  );
};
