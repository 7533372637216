import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import BackgroundBlur from '../assets/background-blur.png';

export const CircularProgressOverlay: React.FC = () => {
  return (
    <Box
      style={{
        backgroundImage: `url(${BackgroundBlur}`,
        height: '100%',
        width: '100%',
        backgroundRepeat: 'no-repeat',
      }}
      position='absolute'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <CircularProgress />
    </Box>
  );
};
