import { materialRenderers } from '@jsonforms/material-renderers';
import ArrayLayoutRenderer, { arrayLayoutTester } from './ArrayLayoutRenderer';
import CountryListControl, { countryControlTester } from './CountryListControl';
import EnumFreeSoloRenderer, { enumFreeSoloTester } from './EnumFreeSoloRenderer';
import { HeadingTextRenderer, InfoTextRenderer, infoTextTester } from './InfoTextRenderer';
import TypeScriptEnumListControl, {
  typeScriptEnumListControlTester,
} from './TypeScriptEnumListControl';

export const poolSideJsonFormRenderers = [
  ...materialRenderers,
  // register custom renderers
  { tester: countryControlTester, renderer: CountryListControl },
  { tester: arrayLayoutTester, renderer: ArrayLayoutRenderer },
  { tester: infoTextTester('InfoText'), renderer: InfoTextRenderer },
  { tester: infoTextTester('HeadingText'), renderer: HeadingTextRenderer },
  { tester: enumFreeSoloTester, renderer: EnumFreeSoloRenderer },
  { tester: typeScriptEnumListControlTester, renderer: TypeScriptEnumListControl },
];
