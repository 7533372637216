import { AmlFormStatus, ApiOnboardAmlInfo, ApiOnboardProfile, ApiOnboardCompanyAml, ApiShuftiVerification, ShuftiStatus, ApiAmlInfo, ApiVeriffStatus } from '../../api';
import { assign, createMachine } from 'xstate';
import { onboardApi, queryClient } from '../../apis';
export interface OnBoardingContext {
  invitationId: string;
  pin: string;
  onBoardProfile: ApiOnboardProfile;
  email: string;
  firstName: string;
  lastName: string;
  verification: ApiShuftiVerification;
  personAmlForm: any;
  currentCompanyIdx: number;
  currentCompanyAml: any;
  currentCompanyName: string;
  companyAmlForms: any[];
  currentStep: number;
  steps: string[];
  msg: string;
}

const findNextCompanyAmlIdx = (
  currentCompanyIdx: number,
  currentCompanyAml: Array<ApiOnboardCompanyAml>
): number => {
  if (currentCompanyAml.length == 0) {
    return -2;
  }
  for (let idx = currentCompanyIdx + 1; idx < currentCompanyAml.length; idx++) {
    if (currentCompanyAml[idx].aml!.amlFormStatus == AmlFormStatus.InProgress) {
      return idx;
    }
  }
  return currentCompanyAml.length;
};

export const onBoardingStateMachine = createMachine<OnBoardingContext, any>({
  id: 'onboarding',
  initial: 'preInit',
  states: {
    preInit: {
      id: 'preInit',
      on: {
        init: {
          target: 'loading',
          actions: assign({
            invitationId: (_context, event) => event.invitationId,
            currentCompanyIdx: (_context, _event) => -1,
            pin: (_contect, event) => event.pin
          }),
        },
      },
    },
    loading: {
      id: 'loading',
      type: 'compound',
      initial: 'init',
      onDone: {
        target: 'switcher',
      },
      states: {
        init: {
          invoke: [
            {
              id: 'onboardInfo',
              src: (context, _event) => {
                return queryClient.fetchQuery(['onboard'], () => {
                  return onboardApi.info({ invitationId: context.invitationId, pin: context.pin })
                    .catch(async err => {
                      const response = (err as Response);
                      let json = null;
                      try {
                        json = await response.json();
                      } catch (e) {
                        throw {
                          "status": response.status,
                          "message": "Server Error!"
                        }
                      }
                      throw json;
                    });
                });
              },
              onDone: {
                target: 'success',
                actions: assign({
                  onBoardProfile: (_context, event) => event.data,
                  personAmlForm: (_context, event) => {
                    const ret = JSON.parse(
                      (event.data as ApiOnboardProfile).amlInfo!.personalAmlForm!.formData!
                    );
                    return ret;
                  },
                  steps: (_context, event) => {
                    const profile = (event.data as ApiOnboardProfile);
                    let steps = ["Personal Info", "Verification", "Personal AML"];
                    if ((profile.amlInfo!.companyAmlForms != undefined) && (profile.amlInfo!.companyAmlForms.length > 0)) {
                      const companyAmlSteps = profile.amlInfo!.companyAmlForms.map(aml => "AML for " + aml.companyName);
                      steps = steps.concat(companyAmlSteps);
                    }
                    return steps;
                  },
                  currentStep: (_context, _event) => 0
                }),
              },
              onError: {
                target: '#preInit',
                actions: assign({
                  msg: (_context, event) => {
                    const response = event.data;
                    return response["message"];
                  }
                })
              }
            },
          ],
        },
        success: {
          type: 'final',
        },
      },
    },
    switcher: {
      always: [
        {
          target: 'confirmEmail',
          actions: assign({
            currentStep: (_context, _event) => 1
          }),
          cond: (context, _event) =>
            context.onBoardProfile.shuftiVerificationStatus != ShuftiStatus.Approved && context.onBoardProfile.veriffStatus != ApiVeriffStatus.Approved
        },
        {
          target: 'personAml',
          actions: assign({
            currentStep: (_context, _event) => 2
          }),
          cond: (context, _event) =>
            context.onBoardProfile.amlInfo?.personalAmlForm?.amlFormStatus == AmlFormStatus.InProgress,
        },
        {
          target: 'aml',
        },
      ],
    },
    confirmEmail: {
      type: 'compound',
      initial: 'init',
      onDone: {
        target: 'veriff',
      },
      states: {
        init: {
          on: {
            next: {
              target: 'saving',
              actions: assign({
                email: (_context, event) => event.confirmedParams.confirmedEmail,
                firstName: (_context, event) => event.confirmedParams.confirmedFirstName,
                lastName: (_context, event) => event.confirmedParams.confirmedLastName,
              }),
            },
          },
        },
        saving: {
          invoke: [
            {
              id: 'confirmEmail',
              src: (context, _event) => {
                return onboardApi.confirmPersonalDetails({
                  apiOnboardConfirmPersonalDetails: {
                    email: context.email,
                    firstName: context.firstName,
                    lastName: context.lastName,
                  },
                  invitationId: context.invitationId,
                  pin: context.pin
                });
              },
              onDone: {
                target: 'success',
                actions: assign({
                  verification: (_context, event) => event.data as ApiShuftiVerification,
                })
              },
            },
          ],
        },
        success: {
          type: 'final',
        },
      },
    },
    veriff: {
      on: {
        next: {
          target: 'loading',
        },
      },
    },
    personAml: {
      type: 'compound',
      initial: 'init',
      onDone: {
        target: 'loading',
      },
      states: {
        init: {
          on: {
            change: {
              actions: assign({
                personAmlForm: (_context, event) => {
                  return event.personAmlData;
                },
              }),
            },
            next: {
              target: 'saving',
            },
          },
        },
        saving: {
          invoke: [
            {
              id: 'submitAmlForm',
              src: (context, _event) => {
                return onboardApi.submitAmlForm({
                  invitationId: context.invitationId,
                  pin: context.pin,
                  apiAmlForm: {
                    id: context.onBoardProfile.amlInfo?.personalAmlForm?.id,
                    createdBy: context.onBoardProfile.id,
                    amlFormStatus: AmlFormStatus.Submitted,
                    createdForPerson: context.onBoardProfile.id,
                    formData: JSON.stringify(context.personAmlForm),
                  },
                });
              },
              onDone: {
                target: 'success',
              },
            },
          ],
        },
        success: {
          type: 'final',
        },
      },
    },
    aml: {
      type: 'compound',
      initial: 'init',
      onDone: {
        target: 'thankyou',
      },
      states: {
        init: {
          always: [
            {
              target: 'switchstate',
              actions: assign((context, _event) => {
                const companyAmlForms = context.onBoardProfile.amlInfo!.companyAmlForms!;
                const nextIdx = findNextCompanyAmlIdx(
                  context.currentCompanyIdx,
                  context.onBoardProfile.amlInfo!.companyAmlForms!
                );

                let formData = undefined;
                let companyName = undefined;

                if ((nextIdx >= 0) && (nextIdx < companyAmlForms.length)) {
                  const formDataStr = companyAmlForms[nextIdx].aml!.formData;
                  formData = formDataStr != undefined ? JSON.parse(formDataStr) : undefined;
                  companyName = companyAmlForms[nextIdx].companyName;
                }
                return {
                  currentCompanyIdx: nextIdx,
                  currentCompanyAml: formData,
                  currentCompanyName: companyName,
                  currentStep: nextIdx + 3
                };
              }),
            },
          ],
        },
        switchstate: {
          always: [
            {
              target: 'edit',
              cond: (context, _event) =>
                context.currentCompanyIdx >= 0 &&
                context.currentCompanyIdx < context.onBoardProfile.amlInfo!.companyAmlForms!.length,
            },
            {
              target: 'finish',
            },
          ],
        },
        edit: {
          on: {
            change: {
              actions: assign({
                currentCompanyAml: (_context, event) => {
                  return event.amlData;
                },
              }),
            },
            next: {
              target: 'saving',
            },
          },
        },
        saving: {
          invoke: [
            {
              id: 'submitAmlForm',
              src: (context, _event) => {
                const amlForms = context.onBoardProfile.amlInfo!.companyAmlForms!;
                return onboardApi.submitAmlForm({
                  invitationId: context.invitationId,
                  pin: context.pin,
                  apiAmlForm: {
                    id: context.onBoardProfile.amlInfo?.personalAmlForm?.id,
                    createdBy: context.onBoardProfile.id,
                    amlFormStatus: AmlFormStatus.Submitted,
                    createdForCompany: amlForms[context.currentCompanyIdx].companyId,
                    formData: JSON.stringify(context.currentCompanyAml),
                  },
                });
              },
              onDone: {
                target: '#loading',
              },
            },
          ],
        },
        finish: {
          type: 'final',
        },
      },
    },
    thankyou: {
      invoke: [
        {
          id: 'onboardEnd',
          src: (context, _event) => {
            return onboardApi.endOnboardSession({
              invitationId: context.invitationId,
              pin: context.pin,
            });
          },
        },
      ],
    },
  },
});
