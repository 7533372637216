/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AmlFormStatus,
    AmlFormStatusFromJSON,
    AmlFormStatusFromJSONTyped,
    AmlFormStatusToJSON,
    ApiAdminCompany,
    ApiAdminCompanyFromJSON,
    ApiAdminCompanyFromJSONTyped,
    ApiAdminCompanyToJSON,
    RiskStatus,
    RiskStatusFromJSON,
    RiskStatusFromJSONTyped,
    RiskStatusToJSON,
    RoleType,
    RoleTypeFromJSON,
    RoleTypeFromJSONTyped,
    RoleTypeToJSON,
    ShuftiStatus,
    ShuftiStatusFromJSON,
    ShuftiStatusFromJSONTyped,
    ShuftiStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiInvestorProfile
 */
export interface ApiInvestorProfile {
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    entityId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    selectedEntityId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    residency?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    taxResidency?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    phone?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiInvestorProfile
     */
    lastEmailAt?: Date;
    /**
     * 
     * @type {AmlFormStatus}
     * @memberof ApiInvestorProfile
     */
    amlStatus?: AmlFormStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestorProfile
     */
    veriffId?: string;
    /**
     * 
     * @type {ShuftiStatus}
     * @memberof ApiInvestorProfile
     */
    veriffStatus?: ShuftiStatus;
    /**
     * 
     * @type {RiskStatus}
     * @memberof ApiInvestorProfile
     */
    risk?: RiskStatus;
    /**
     * 
     * @type {Array<RoleType>}
     * @memberof ApiInvestorProfile
     */
    roles: Array<RoleType>;
    /**
     * 
     * @type {Array<ApiAdminCompany>}
     * @memberof ApiInvestorProfile
     */
    companies?: Array<ApiAdminCompany>;
}

export function ApiInvestorProfileFromJSON(json: any): ApiInvestorProfile {
    return ApiInvestorProfileFromJSONTyped(json, false);
}

export function ApiInvestorProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInvestorProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'selectedEntityId': !exists(json, 'selectedEntityId') ? undefined : json['selectedEntityId'],
        'email': json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'residency': !exists(json, 'residency') ? undefined : json['residency'],
        'taxResidency': !exists(json, 'taxResidency') ? undefined : json['taxResidency'],
        'bankAccount': !exists(json, 'bankAccount') ? undefined : json['bankAccount'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'lastEmailAt': !exists(json, 'lastEmailAt') ? undefined : (new Date(json['lastEmailAt'])),
        'amlStatus': !exists(json, 'amlStatus') ? undefined : AmlFormStatusFromJSON(json['amlStatus']),
        'veriffId': !exists(json, 'veriffId') ? undefined : json['veriffId'],
        'veriffStatus': !exists(json, 'veriffStatus') ? undefined : ShuftiStatusFromJSON(json['veriffStatus']),
        'risk': !exists(json, 'risk') ? undefined : RiskStatusFromJSON(json['risk']),
        'roles': ((json['roles'] as Array<any>).map(RoleTypeFromJSON)),
        'companies': !exists(json, 'companies') ? undefined : ((json['companies'] as Array<any>).map(ApiAdminCompanyFromJSON)),
    };
}

export function ApiInvestorProfileToJSON(value?: ApiInvestorProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'entityId': value.entityId,
        'selectedEntityId': value.selectedEntityId,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'address': value.address,
        'residency': value.residency,
        'taxResidency': value.taxResidency,
        'bankAccount': value.bankAccount,
        'phone': value.phone,
        'lastEmailAt': value.lastEmailAt === undefined ? undefined : (value.lastEmailAt.toISOString()),
        'amlStatus': AmlFormStatusToJSON(value.amlStatus),
        'veriffId': value.veriffId,
        'veriffStatus': ShuftiStatusToJSON(value.veriffStatus),
        'risk': RiskStatusToJSON(value.risk),
        'roles': ((value.roles as Array<any>).map(RoleTypeToJSON)),
        'companies': value.companies === undefined ? undefined : ((value.companies as Array<any>).map(ApiAdminCompanyToJSON)),
    };
}

